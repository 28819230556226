import axios from 'axios';
import { method } from 'lodash';

export const checkAccount = (email) => {
	return axios.post('/checkAccount', {
		email: email,
	});
};

export const signUp = async (email, password, name, company, answer1, question1) => {
	let body = {
		name,
		company,
		email,
		password,
		studykey: "",
		salt: "",
		iv: "",
		firstsecurityquestionId: question1,
		firstsecurityanswer: answer1,
		secondsecurityquestionId: 0,
		secondsecurityanswer: "",
		organisationId: 0,
		categoryId: 0,
		regionId: 0
	}
	console.log(body)

try{
	let res= await fetch('https://frontend.mypeerformance.com:8080/api/users/signUp', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body)
	})
	return  await res.json()
}catch (err) {
	return  alert(err)
}
	// console.log('ressssssss', res)
	// localStorage.setItem('id', res.data.id)
	// localStorage.setItem('token', res.data.token)
 


};

export const checkToken = (token) => {
	return axios.get(`/verifyToken?token=${token}`, {
		token,
	});
};

export const checkAuthToken = (token) => {
	return axios.get(`/verifyAuthToken?token=${token}`, {
		token,
	});
};

export const getSecurityQuestions = () => {
	return axios.get('/securityQuestions');
};

export const getCompanyDictionaries = () => {
	return axios.get('/companyDictionaries');
};

export const createUser = (userData) => {
	return axios.post('/createUser', {
		...userData,
	});
};

export const createCompany = (companyData) => {
	return axios.post('/createCompany', {
		...companyData,
	});
};

export const refreshToken = (email) => {
	return axios.post('/refreshToken', { email });
};

export const refreshAuthToken = (submiterEmail, supervisorEmail) => {
	return axios.post('/refreshAuthToken', { submiterEmail, supervisorEmail });
};
