import Table from 'react-bootstrap/Table';
import {useEffect, useState}  from "react";

import {
    getUserInfo,
    getUserSubscriptions,
    getStudyData
  } from "../store/dashboardActions";
import TableItems from './TableItems';
import { useDispatch, useSelector } from 'react-redux';


function ProfileStudyData  () {
    
    const dispatch = useDispatch();

    
    const userSubscriptions = useSelector(
      (state) => state.dashboard.userSubscriptions
      );
      const userInfo = useSelector(
        (state) => state.dashboard.userInfo
        );
        const StatePeek = useSelector(
          (state) => state.dashboard
          );
        console.log(userSubscriptions, "PROFILE")
        console.log(userInfo, "PROFILE")
        console.log(StatePeek, "PROFILE")
        
        



        
        useEffect(() => {
          dispatch(getUserInfo());
          dispatch(getUserSubscriptions());
        }, [dispatch]);
        


        
        
        
        
        return (
          <div style={{width: "77%", height:"82vh", margin: "auto", overflowY:"auto"}}>
                

          <div className="container" style={{padding:"2rem", background:"#1F2748", borderRadius:"0.5rem"}}>
                <h1 style={{marginBottom:"2rem"}}>Study Details</h1>
                    
                    <Table responsive 
                    striped
                    hover
                    borderless
                    variant="dark"
                    cellspacing="0"
                    >
                    <thead>
                      <tr style={{background:"#181f3d", border:"none"}}>
                        
                        <th style={{background:"#181f3d", border:"none"}}>Study Name</th>
                        <th style={{background:"#181f3d", border:"none"}}>Data Status</th>
                        <th style={{background:"#181f3d", border:"none"}}>Renewel Status </th>
                        <th style={{background:"#181f3d", border:"none"}}>Token amount </th>
                        <th style={{background:"#181f3d", border:"none"}}>Status</th>
                      </tr>
                    </thead>
                      <tbody>

                        {userSubscriptions.map(userSubs => (
                          <TableItems userSubsID = {userSubs.study.ID} {...userSubs} key={userSubs.study.ID}/>
                          ))}
                        
                      </tbody>
                    </Table>
            </div>
          </div> 
        
     )


}

export default ProfileStudyData 