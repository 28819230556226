import React from "react";
import { Line } from "react-chartjs-2";
import lockOpen from "../Styleguide/icons/basic-lock-open.svg";
import { getMonthName } from "../Utils/dashboard";
import { useContext } from "react";
import { Cntx } from "../context/context";
import RenderIf from "../renderif/RenderIf";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../store/dashboard";

const DATA_COUNT = 7;
const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };
let height = 155;
let width = 400;

const setUpChart = (data, name) => {
  function removeDuplicatesByDate(data) {
    const uniqueDates = new Set();
    const filteredData = [];

    for (const item of data) {
      const { Date } = item;
      if (!uniqueDates.has(Date)) {
        uniqueDates.add(Date);
        filteredData.push(item);
      }
    }

    return filteredData;
  }
  let filteredData = data;
  if(data){

    filteredData = removeDuplicatesByDate(data);
  }
    
  const arrayMonths = filteredData
    ? filteredData.map((row) => new Date(row.Date).getMonth())
    : [];
  return {
    type: "bar",
    labels: arrayMonths ? arrayMonths.map((month) => getMonthName(month)) : [],
    datasets: [
      {
        label: name,
        data: filteredData ? filteredData.map((row) => row.Value) : [],
        borderColor: "#91FBAF",
        backgroundColor: "#91FBAF",
        pointRadius: 0,
      },
      {
        label: "Industry Average",
        data: data ? filteredData.map((row) => row.Average) : [],
        borderColor: "#6585FF",
        backgroundColor: "#6585FF",
        pointRadius: 0,
      },
    ],
    options: {
      legend: {
        labels: {
          fontColor: "blue",
          fontSize: 18,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
};

const setUpDefaultChart = {
  type: "bar",
  labels: [
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
  ],
  datasets: [
    {
      label: "My data",
      data: [6, 5.5, 6, 6.5, 7, 6.7, 4.2, 7, 7.5, 7, 6.5, 6],
      borderColor: "#91FBAF",
      backgroundColor: "#91FBAF",
      pointRadius: 0,
    },
    {
      label: "Industry Average",
      data: [7, 7.5, 7, 6.5, 6, 4.5, 5, 6, 5.5, 6, 6.5, 7],
      borderColor: "#6585FF",
      backgroundColor: "#6585FF",
      pointRadius: 0,
    },
  ],
  options: {
    legend: {
      labels: {
        fontColor: "blue",
        fontSize: 18,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
};

const setUpEmptyChart = {
  type: "bar",
  labels: [
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
  ],
  datasets: [
    {
      label: "My data",
      data: [1],
      borderColor: "#91FBAF",
      backgroundColor: "#91FBAF",
      pointRadius: 0,
    },
    {
      label: "Industry Average",
      data: [2],
      borderColor: "#6585FF",
      backgroundColor: "#6585FF",
      pointRadius: 0,
    },
  ],
  options: {
    legend: {
      labels: {
        fontColor: "blue",
        fontSize: 18,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
};

const options = {
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "rgb(255, 99, 132)",
      },
    },
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        borderDash: [6, 2],
        color: "#5E6891",
      },
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },
};

export const LineChart = ({ data, name, subscription }) => {
  const dispatch = useDispatch();
  const activeStudy = useSelector((state) => state.dashboard.activeStudy);
if(!activeStudy || activeStudy=={} ||Object.keys(activeStudy).length==0){
  data=null
  dispatch(dashboardActions.setStudyData(null));
  dispatch(dashboardActions.setParticipantBreakDown(null));
  console.log("Avvvvvv",activeStudy)
}
  const chartData = setUpChart(data, name);
  const contextuser = useContext(Cntx);

  console.log("tewtewttwt", data);
  if (subscription === true && data && JSON.stringify(data).length > 50) {
    if (JSON.stringify(data).length > 50) {
      return (
        <Line
          data={chartData}
          options={options}
          width={width}
          height={height}
        />
      );
    }
  }

  if (
    subscription === true ||
    JSON.stringify(data) === "undefined" ||
    JSON.stringify(data) === undefined
  ) {
    return (
      <Line
        data={setUpEmptyChart}
        options={options}
        width={width}
        height={height}
      />
    );
  } else {
    return (
      <div
        style={{
          paddingBottom: "3.5px",
        }}
      >
        <Line
          data={setUpDefaultChart}
          options={options}
          width={width}
          height={height}
        />
        <div
          className="chart-background"
          style={{
            borderTopRightRadius: "50px",
            borderTopLeftRadius: "50px",
            borderBottomLeftRadius: "50px",
            borderBottomRightRadius: "50px",
          }}
        >
          <RenderIf isTrue={contextuser.val == true}>
            {null}
            {/* <div className="data-tooltip-info">
              <span
                data-title="These are the studies which the user is subscribed to.  The user is expected to submit data to each one when prompted each month
"
              >
                <img
                  src="https://img.icons8.com/metro/26/000000/info.png"
                  class="info-icon"
                  alt=""
                />
              </span>
            </div> */}
          </RenderIf>

          <div className="chart-background-content">
            <img src={lockOpen} alt="lock-open" />
            <div>
              <small style={{ fontSize: ".725em" }}>
                Subscribe to Study to see your statistic{" "}
              </small>
              <br />
              <small style={{ fontSize: ".725em" }}> every day</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
