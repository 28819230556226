import axios from "axios";

export const fetchStudyById = async (sub) => {
  try {
    const response = await axios.get(`/studies/getById/${sub.id}`, {
      headers: {
        // 'x-access-token': localStorage.getItem('token')
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchRegions = async () => {
  try {
    const response = await axios.get("/regions/getRegions", {
      headers: {
        // 'x-access-token': localStorage.getItem('token')
        "x-access-token": localStorage.getItem("token"),
      },
    });

    //console.log("This is what we received", response.data.data);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
export const getExamplestudies = async () => {
  try {
    const response = await axios.get("/examplestudies/getlist", {
      headers: {
        // 'x-access-token': localStorage.getItem('token')
        "x-access-token": localStorage.getItem("token"),
      },
    });

    console.log("This is what we received", response.data.data);
    let resParse = response.data.data;

    for (const item of resParse) {
      const parentsic = item.category.parentsic;

      item.category.parentsic = await fetchParentPeerGroupBysic(parentsic);
    }
    return resParse;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
export const fetchRegionChild = async (parent) => {
  try {
    // const response = await axios.get(`/regions/${parent}/datapoint`,
    const response = await axios.get(
      `regions/getRegionChildren`,
      // const response = await axios.get(`/regions/2/datapoint`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    console.log("This is the result 2:", response);

    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

// Get the first three regions - All of UK, Europe, Global,
export const fetchTop3Level = async () => {
  try {
    const response = await axios.get("/regions/getTop3Regions", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });

    // set the top 3 levels

    window.localStorage.setItem(
      "top3Regions",
      JSON.stringify(response.data.data)
    );
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchUserInfo = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    // const token = localStorage.getItem("token")
    // const response = await axios.get('/users/info',
    const response = await axios.get(`/users/byUserId/${user.id}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
        // 'x-access-token': token
      },
    });
    // we are setting a variable that the user has logged in
    localStorage.setItem("userIsLoggedIn", "true");
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

// Get region by id - studyID, regionId
export const fetchStudyByRegion = async (peerGroupId, regionId) => {
  console.log({ peerGroupId, regionId }, "lll?");
  try {
    //let response = await axios.get(`/studies/16775/1`,
    let response = await axios.get(
      `/studies/getByRegion/${peerGroupId}/${regionId}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchStudies = async (peerGroupId) => {
  try {
    const response = await axios.get(`/studies/getByPeergroup/${peerGroupId}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });

    console.log("This is the peer group id :", peerGroupId);
    console.log("This is the study:", response);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

//
export const fetchUserSubscriptions = async (id) => {
  try {
    let userId = localStorage.getItem("id");
    const response = await axios.get(`/subscriptions/getByuserId/${userId}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    // console.log("response.data" + JSON.stringify(response.data));
    localStorage.setItem(
      "userSubscription",
      JSON.stringify(response.data.data)
    );
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const addSubscription = async (id) => {
  try {
    const response = await axios.post(
      "/users/subscriptions",
      {
        studyId: id,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchAddStudyData = async (value, studyId) => {
  try {
    let userId = localStorage.getItem("id");
    console.log("valuestudydata", value, studyId, userId);
    const response = await axios.post(
      `/studydata/create`,
      {
        studyId: studyId,
        value: value,
        userId: userId,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchGetStudyData = async (studyId) => {
  try {
    let userId = localStorage.getItem("id");
    console.log("dfsssgw312123312", studyId, userId);
    const response = await axios.get(
      `/studydata/getByuserId/${studyId}/${userId}`,
      //const response = await axios.get(`/getByuserId/${studyId}/${userId}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
export const fetchGetExampleStudyData = async () => {
  try {
    const response = await axios.get(
      `/examplestudydata/getlist`,
      //const response = await axios.get(`/getByuserId/${studyId}/${userId}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    console.log("action.payloadaction.payloadaction.payload", response);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
// Update the sql user table with the user's last region
export const updateRegion = async (regionID) => {
  try {
    const response = await axios.put(
      "/users/region",
      {
        regionID: regionID,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

// Update the sql user table with the user's last selected peer group
export const updatePeerGroup = async (id) => {
  try {
    const response = await axios.put(
      "/users/peergroup",
      {
        peerGroupId: id,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchRegionById = async (regionId) => {
  try {
    const response = await axios.get(`/regions/getRegionById/${regionId}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });

    localStorage.setItem("regionId", response.data.data.ID);
    localStorage.setItem("regionName", response.data.data.Name);
    localStorage.setItem("userIsLoggedIn", "true");
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchPeerGroup = async (peerGroupId) => {
  try {
    const response = await axios.get(
      `/categories/peerGroupbyId/${peerGroupId}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    localStorage.setItem("fetchedLevel4", response.data.data.Category); // fine
    localStorage.setItem("fetchedLevel6", response.data.data.Category); // fine
    localStorage.setItem("fetchedLevel4SIC", response.data.data.SIC); // fine
    localStorage.setItem("fetchedLevel6SIC", response.data.data.SIC); // fine
    localStorage.setItem("userIsLoggedIn", "true");
    if (response.data.data.ParentSIC) {
      await fetchParentPeerGroup(response.data.data.ParentSIC);
    }

    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
const fetchParentPeerGroup = async (parentsic) => {
  try {
    console.log("xkvdjcbgdjkbdfkjgdfjkbg", parentsic);

    const response = await axios.get(`/categories/byparentsic/${parentsic}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    console.log("xkvdjcbgdjkbdfkjgdfjkbg", response);
    let newArry = [];
    newArry = response.data.data.filter(
      (category) => category.sic == parentsic && category.parentsic == "0"
    );

    console.log("fddgdgdffg", newArry);
    localStorage.setItem("parentCategory", response.data.data[0].category); // fine

    console.log("fddgdgdffg", localStorage.getItem("parentCategory"));

    return response.data.data;
  } catch (err) {
    console.log("xkvdjcbgdjkbdfkjgdfjkbg", parentsic);

    console.log(`error`, err);
    return [];
  }
};
export const fetchParentPeerGroupBysic = async (parentsic) => {
  try {
    let response = await fetchParentPeerGroup(parentsic);
    console.log(response, "checkItem");
    return response[0].category;
  } catch (err) {
    console.log(`error`, err);
    return parentsic;
  }
};

// Participant range data
export const fetchParticipantRange = async () => {
  try {
    const response = await axios.get("/getrange", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

// Participant get participant breakdown data
export const fetchParticipantBreakDown = async (studyId) => {
  try {
    // :studyID/datapoint
    let userId = localStorage.getItem("id");
    const response = await axios.get(
      `/participantBreakdownData/getdata/${studyId}/${userId}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    localStorage.setItem("breakDown", response.data);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
export const fetchexParticipantBreakDown = async (studyId) => {
  try {
    // :studyID/datapoint
    const response = await axios.get(
      `/participantBreakdownData/exampleParticipantbdData`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    localStorage.setItem("breakDown", response.data);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

// Faizan Zafar
export const fetchNotificationsData = async () => {
  try {
    const response = await axios.get(`/notifications/getlist`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const updateNotification = async (notiId) => {
  try {
    const response = await axios.put(
      `/notification/update/${notiId}`,
      {},
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return null;
  }
};

export const deleteNotification = async (notiId) => {
  try {
    const response = await axios.delete(`/notification/delete/${notiId}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return null;
  }
};
