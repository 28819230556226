import React, { useState } from "react";
import { Button, Card, CardDeck, CardColumns, Container, Row, Col } from "react-bootstrap";


export function QuickLinks() {

    return (

        <div className="">
            <Container>
                <hr />
                <Row>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Recruitment</Card.Title>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Plumber</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Driving</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Sports</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Finance</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Farming</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Shopping</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Software</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Finance</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Farming</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: "10rem" }}>
                            <Card.Img variant="top" src="https://picsum.photos/10/10" />
                            <Card.Body>
                                <Card.Title style={{ textAlign: "center" }}>Shopping</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default { QuickLinks };
