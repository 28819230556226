const Button = ({
  type = "button",
  onClick = () => {},
  disabled = false,
  text,
  cname
}) => (
  <div className="text-center mt-4">
    <button
      type={type}
      className={`${cname} btn btn-primary text-center w-100`} 
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  </div>
);

export default Button;
