import React, { useEffect, useState, useContext } from "react";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faBell,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./App.css";
// import {decryptDataCTR} from './Utils/DecryptDataCTR';
import RenderIf from "./renderif/RenderIf";
import {
  getPeerGroupSession,
  getUser,
  removeUserSession,
} from "./Utils/Common";
import logo from "./Styleguide/logo_horizontal.svg";
import homeIcon from "./Styleguide/home.svg";
import arrowNext from "./Styleguide/arrow-next.svg"
import bell from "./Styleguide/bell.svg";
import { useHistory } from "react-router-dom";
import { Cntx } from "./context/context";
import {
  getExamplestudieslist,
  getStudyData,
  getStudies,
  getParticipantBreakdown,
  getNotificationsData,
  updateNotificationData,
  deleteNotificationData,
} from "./store/dashboardActions";
import { fetchParentPeerGroupBysic } from "./store/dashboardFetches";
import { peerformanceGreen, peerformanceMenuText } from "./colours";
import BrowseCategory from "./BrowseCategory";
import { useSelector, useDispatch } from "react-redux";
import { dashboardActions } from "./store/dashboard";
import { setActiveStudy } from "../src/store/dashboardActions";
import { QueuedStudies } from "../src/Dashboard/QueuedStudies";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  onNewNotification,
  offSocketNewNotificationListener,
} from "./Socket/socket";
function NavigationHeader(props) {
  const contextuser = useContext(Cntx);
  const dispatch = useDispatch();
  const addSugestedStudy = useSelector(
    (state) => state.dashboard.addSugestedStudy
  );

  const toggleaddSugestedstudy = (addSugestedStudy) => {
    console.log("addSugestedStudy", addSugestedStudy);
    // return async dispatch => {
    dispatch(dashboardActions.setAddSugestedStudy(!addSugestedStudy));
    // }
  };
  const isLastPeerGroup = useSelector(
    (state) => state.dashboard.lastPeerGroupSelected4Or5
  );

 
  const notificationsData = useSelector(
    (state) => state.dashboard.notificationsData
  ).slice().reverse();
  const unReadNotifications = notificationsData.filter(
    (x) => x.isRead == 0
  ).length;

  useEffect(() => {
    dispatch(getNotificationsData());
    const listener = (notification) => {
      dispatch(dashboardActions.addNotification(notification));
    };
    onNewNotification(listener);

    return () => {
      offSocketNewNotificationListener(listener);
    };
  }, []);

  const markAsRead = (notiId) => {
    dispatch(updateNotificationData(notiId));
  };
  const deleteNotification = (notiId) => {
    dispatch(deleteNotificationData(notiId));
  };
  //

  const examplestudies = useSelector((state) => state.dashboard.examplestudies);
  const [modalShow, setModalShow] = React.useState(false);
  let [getShowExample, setShowExample] = useState("Show Example Study");
  let [getCanShowExample, setCanShowExample] = useState(false);
  const showExampleStudystate = useSelector(
    (state) => state.dashboard.showExampleStudy
  );

  // const activeStudy = useSelector((state) => state.dashboard.activeStudy);
  const userSubscriptions = useSelector(
    (state) => state.dashboard.userSubscriptions
  );
  const availableStudies = useSelector(
    (state) => state.dashboard.availableStudies
  );
  const queuedStudies = useSelector((state) => state.dashboard.queuedStudies);

  const queuedStudy = () => {
    dispatch(dashboardActions.setAddSugestedStudy(false));
  };

  const homeHandler = () => {
    try {
      const max = [...userSubscriptions]?.reduce(function (prev, curr) {
        
        return prev.NumOfSubscribers > curr.NumOfSubscribers ? prev : curr;
      }, 0);

      console.log(userSubscriptions, max, "max");
      onActiveStudyClick(max);
      queuedStudy();
      setShowExample("Show Example Study");

      // showExampleStudy();
    } catch (err) {
      console.log(err.message);
    }
  };
  const onActiveStudyClick = async (subscription) => {
    let study = {
      ID: subscription.StudyID,
      Name: subscription.study.Name,
      RegionID: subscription.RegionID,
      PeerGroupID: subscription.PeerGroupID,
      TypeID: subscription.TypeID,
      NumOfSubscribers: subscription.NumOfSubscribers,
      About: subscription.About,
      Status: subscription.study.Status,
      Activestatus: parseInt(subscription.Active),
      Category: subscription.Category,
      ParentCategory: subscription.ParentCategory,
      createdAt: subscription.study.createdAt,
      updatedAt: subscription.study.updatedAt,
      DividedBy: subscription.DividedBy,
      AverageOf: subscription.AverageOf
    };
    await fetchParentPeerGroupBysic(study.ParentCategory);
    dispatch(dashboardActions.setActiveStudy(study));
    dispatch(getStudyData(subscription.StudyID));

    // alert("stop")
    dispatch(
      getStudies(
        subscription?.PeerGroupID,
        subscription?.RegionID,
        subscription?.StudyID
      )
    );
    // dispatch(updateLastPeerGroup(subscription?.PeerGroupID));
    dispatch(getParticipantBreakdown(subscription?.StudyID));
    // localStorage.setItem("regionId", subscription?.RegionID);
    // dispatch(updateLastRegion(subscription?.RegionID));
  };
  // const handlingStudyClick = study => {

  //     dispatch(dashboardActions.setActiveStudy(study));
  //     const isSubscriber = userSubscriptions.some(subscription => subscription.StudyID === study.ID);
  //     if (isSubscriber) {

  //     dispatch(getStudyData(study.ID));

  //     } else {
  //     dispatch(dashboardActions.setStudyData(null));
  //     }
  // };
  const user = getUser();
  // if (modalShow === true) {
  //     if (document.getElementById("A")) {
  //         document.getElementById("A").click()
  //     }
  // }

  let history = useHistory();
  const handleLogout = () => {
    removeUserSession();
    history.push("/");
    // props.history.push('/home'); // push the user to the home page when they logout
  };

  // useEffect(() => {
  // setModalShow(isLastPeerGroup);
  // }, [isLastPeerGroup]);

  let exampleStudyDisplay = document.getElementById("exampleStudyDisplay");
  let mainCardDisplay = document.getElementById("mainCardDisplay");

  //TAKEN OUT OF SHOWEXAMPLESTUDY() TO FIX
  // let showExampleStudyGrab = async () => {
  //   await dispatch(getExamplestudieslist());
  // }

  let showExampleStudy = () => {
    //INTERCEPT DISPATCH WITH CONDITIONAL ON SHOW STATE
    // IF EXAMPLES ARE SHOWING
    if (getCanShowExample === false) {
      contextuser.setVal(true);
      // exampleStudyDisplay.style.display = "inline-block";
      // mainCardDisplay.style.display = "none";

      dispatch(dashboardActions.setShowExampleStudy(true));
      dispatch(getExamplestudieslist());
      setShowExample("Hide Example Study");
      setCanShowExample(true);
      // queuedStudy();

      document.getElementById("exampleStudyParagraph").style.color = "#FE648D";
    }
    // IF EXAMPLES ARE NOT SHOWING
    if (getCanShowExample === true) {
      dispatch(dashboardActions.setStudyData(null));

      contextuser.setVal(false);
      // exampleStudyDisplay.style.display = "none";
      // mainCardDisplay.style.display = "block";
      setShowExample("Show Example Study");
      setCanShowExample(false);
      dispatch(dashboardActions.setShowExampleStudy(false));
      dispatch(dashboardActions.setParticipantBreakDown(""));
      homeHandler();
      queuedStudy();

      if (userSubscriptions.length > 0) {
        console.log(userSubscriptions, "goose")
        let study = {
          ID: userSubscriptions[0].StudyID,
          Name: userSubscriptions[0].study.Name,
          RegionID: userSubscriptions[0].RegionID,
          PeerGroupID: userSubscriptions[0].PeerGroupID,
          TypeID: userSubscriptions[0].TypeID,
          NumOfSubscribers: userSubscriptions[0].NumOfSubscribers,
          About: userSubscriptions[0].About,
          Status: userSubscriptions[0].study.Status,
          Activestatus: parseInt(userSubscriptions[0].Active),
          Category: userSubscriptions[0].Category,
          ParentCategory: userSubscriptions[0].ParentCategory,
          createdAt: userSubscriptions[0].study.createdAt,
          updatedAt: userSubscriptions[0].study.updatedAt,
          DividedBy: userSubscriptions[0].DividedBy,
          AverageOf: userSubscriptions[0].AverageOf
        };
        dispatch(dashboardActions.setActiveStudy(study));
        const isSubscriber = userSubscriptions.some(
          (subscription) => subscription.StudyID === study.ID
        );
        if (isSubscriber) {
          dispatch(getStudyData(study.ID));
          dispatch(getParticipantBreakdown(study?.ID));
        } else {
          dispatch(dashboardActions.setStudyData(null));
        }
      } else if (availableStudies.length > 0) {
        dispatch(dashboardActions.setActiveStudy(availableStudies[0]));
        const isSubscriber = userSubscriptions.some(
          (subscription) => subscription.StudyID === availableStudies[0].ID
        );
        if (isSubscriber) {
          dispatch(getStudyData(availableStudies[0].ID));
          dispatch(getParticipantBreakdown(availableStudies[0].ID));
        } else {
          dispatch(dashboardActions.setStudyData(null));
        }
      } else if (queuedStudies.length > 0) {
        dispatch(dashboardActions.setActiveStudy(queuedStudies[0]));
        // dispatch(getParticipantBreakdown(queuedStudies[0].ID));
        const isSubscriber = userSubscriptions.some(
          (subscription) => subscription.StudyID === queuedStudies[0].ID
        );
        if (isSubscriber) {
          dispatch(getStudyData(queuedStudies[0].ID));
          dispatch(getParticipantBreakdown(queuedStudies[0].ID));
        } else {
          dispatch(dashboardActions.setStudyData(null));
        }
      } else {
        dispatch(dashboardActions.setActiveStudy({}));
        dispatch(dashboardActions.setStudyData(null));
      }
      dispatch(dashboardActions.setShowExampleStudy(false));

      document.getElementById("exampleStudyParagraph").style.color = "#7b84a8";
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row" style={{ paddingTop: "15px" }}>
          <div className="col-sm-3" style={{ color: "white", width: "355px" }}>
            <a className="navbar-brand" href="/">
              <img
                src={props.logo || logo}
                alt=""
                width="260"
                style={{ paddingLeft: "2%" }}
              />
            </a>

            <nav className="navbar navbar-expand-lg">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
          </div>
          <div
            className="col-md-5"
            style={{ color: "white", width: "650px", paddingTop: "9px" }}
          >
            <div className="navbar-row main-navbar">
              <div onClick={() => homeHandler()} className="noPadding">
                <img
                  src={homeIcon}
                  alt=""
                  width="16"
                  height="22"
                  style={{ paddingBottom: "2px" }}
                />
                <a
                  href="/dashboard"
                  style={{
                    color: peerformanceGreen,
                    fontSize: "13px",
                    marginLeft: "5px",
                    textDecoration: "none",
                  }}
                >
                  Home
                </a>
              </div>

              <div className="noPadding">
                <a
                  role="button"
                  style={{ color: peerformanceMenuText, fontSize: "13px" }}
                >
                  {" "}
                  About
                </a>
              </div>

              <div className="noPadding">
                <a
                  role="button"
                  onClick={() => {
                    setModalShow(true);
                    queuedStudy();
                  }}
                  style={{ color: peerformanceMenuText, fontSize: "13px" }}
                >
                  {" "}
                  Peer&nbsp;Groups{" "}
                </a>
                <BrowseCategory
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>

              <div className="noPadding">
                <a
                  role="button"
                  style={{ color: peerformanceMenuText, fontSize: "13px" }}
                  onClick={() => toggleaddSugestedstudy(addSugestedStudy)}
                >
                  Suggest&nbsp;Study
                </a>
              </div>

              <div
                className="noPadding"
                id="exampleStudyDiv"
                onClick={() => {
                  showExampleStudy();
                }}
              >
                <a
                  role="button"
                  style={{ color: peerformanceMenuText, fontSize: "13px" }}
                  id="exampleStudyParagraph"
                >
                  {getShowExample}
                </a>
              </div>
            </div>
          </div>

          <div
            className="col"
            style={{ paddingTop: "9px", marginRight: "0px" }}
          >
            <div
              className=" float-end navbar-right"
              style={{ paddingRight: "20px" }}
            >
              <div className="noPadding" style={{ fontSize: "18px" }}>
                <p
                  style={{
                    marginRight: "16px",
                    marginLeft: "-27px",
                    marginTop: "2px",
                  }}
                >
                  <NavDropdown
                    title="My Account"
                    id="nav-dropdown"
                    className="my-account"
                    style={{
                      color: peerformanceMenuText,
                      fontSize: "13px",
                      textDecoration: "none",
                    }}
                  >
                    <div className="triangle-dropdown"></div>

                    <NavDropdown.Item>
                      <Link style={{ all: "unset" }} to="/profile?source=1">
                        <div className="dropitem">
                          <p style={{ marginBottom: "0" }}>Study Details</p>
                          <img src={arrowNext} alt="" width="25px" />
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ all: "unset" }} to="/profile?source=2">
                        <div className="dropitem">
                          <p style={{ marginBottom: "0" }}>Payments</p>
                          <img src={arrowNext} alt="" width="25px" />
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ all: "unset" }} to="/profile?source=3">
                        <div className="dropitem">
                          <p style={{ marginBottom: "0" }}>Personal Details</p>
                          <img src={arrowNext} alt="" width="25px" />
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ all: "unset" }} to="/profile?source=4">
                        <div className="dropitem">
                          <p style={{ marginBottom: "0" }}>Security</p>
                          <img src={arrowNext} alt="" width="25px" />
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Link style={{ all: "unset" }} to="/profile?source=5">
                        <div className="dropitem">
                          <p style={{ marginBottom: "0" }}>Data Requests</p>
                          <img src={arrowNext} alt="" width="25px" />
                        </div>
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleLogout}>
                      <div className="dropitem">
                        <p style={{ marginBottom: "0" }}>Log Out</p>
                        <img src={arrowNext} alt="" width="25px" />
                      </div>
                    </NavDropdown.Item>
                  </NavDropdown>
                </p>
              </div>
              {/* <div className=" noPadding" style={{ marginLeft: "0px" }}>
                <Link
                  style={{
                    color: peerformanceMenuText,
                    fontSize: "13px",
                    textDecoration: "none",
                  }}
                  to="/profile"
                >
                  My&nbsp;Account
                </Link>
              </div> */}
              {/*               
              <div className="noPadding" style={{ fontSize: "18px" }}>
                <p
                  style={{
                    color: "#37D881",
                    marginRight: "16px",
                    marginLeft: "-27px",
                  }}
                >
                  <NavDropdown
                    title={user.username}
                    id="nav-dropdown"
                    style={{ fontSize: "13px" }}
                  >
                    <NavDropdown.Item
                      eventKey="4.1"
                      style={{ color: "white" }}
                      className="firsrow-dropdow"
                    >
                      {" "}
                      <img
                        src="https://picsum.photos/35/35"
                        className="rounded-circle"
                      />
                      <NavDropdown.Item eventKey="4.4">
                        <div className="">
                          <p style={{ marginBottom: "0" }}>
                            Welcome {user.name}
                          </p>
                        </div>
                      </NavDropdown.Item>
                    </NavDropdown.Item>
                  </NavDropdown>
                </p>
              </div> */}
              {/* <div className=" noPadding" style={{ marginLeft: "0px" }}>
                <Link
                  style={{
                    color: peerformanceMenuText,
                    fontSize: "13px",
                    textDecoration: "none",
                  }}
                  onClick={handleLogout}
                  to="/profile"
                >
                  Log&nbsp;Out
                </Link>
              </div> */}
              <div>
                {/* <a href="">
                  <img
                    src={bell}
                    style={{
                      display: "block",
                      backgroundSize: "contain",
                      textDecoration: "none",
                    }}
                    alt=""
                  />
                </a> */}
                <NavDropdown
                  id="notification-dropdown"
                  autoClose="outside"
                  title={
                    <span style={{ fontSize: "20px" }}>
                      <FontAwesomeIcon icon={faBell} />
                      {unReadNotifications > 0 && (
                        <span
                          style={{
                            backgroundColor: "#eb5886",
                            borderRadius: "10px",
                            color: "white",
                            padding: "0px 5px",
                            fontSize: "10px",
                            position: "absolute",
                            top: 0,
                            left: "8px",
                          }}
                        >
                          {unReadNotifications}
                        </span>
                      )}
                    </span>
                  }
                >
                  {notificationsData?.map((n) => (
                    <NavDropdown.Item
                      style={{
                        background: "#161c3a",
                        border: "1px solid #353b65",
                      }}
                      eventKey={`3.${n.id}`}
                      className={`notification_item ${
                        +n.isRead == 0 ? " border-left" : ""
                      }`}
                    >
                      <div className="notification">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <b>Study Update</b>
                        </span>
                        <p>{n.notification} </p>
                        <div className="notiContent">
                          {n.isRead == 0 && (
                            <span
                              onClick={() => markAsRead(n.id)}
                              style={{ color: "#36d481", marginRight: "20px" }}
                            >
                              Mark as read
                            </span>
                          )}
                          <span
                            onClick={() => deleteNotification(n.id)}
                            style={{ color: "#e75e88", marginRight: "10rem" }}
                          >
                            Delete
                          </span>
                          <span style={{ color: "#8F96B4", fontSize: "14px" }}>
                            {new Date(n.createdAt).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </span>
                        </div>
                      </div>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </div>
              {/* <div className="noPadding" style={{ fontSize: "18px" }}>
                <p
                  style={{
                    color: "#37D881",
                    marginRight: "16px",
                    marginLeft: "-27px",
                  }}
                >
                  <NavDropdown
                    title={user.username}
                    id="nav-dropdown"
                    style={{ fontSize: "13px" }}
                  >
                    <NavDropdown.Item
                      eventKey="4.1"
                      style={{ color: "white" }}
                      className="firsrow-dropdow"
                    >
                      {" "}
                      <img
                        src="https://picsum.photos/35/35"
                        className="rounded-circle"
                      />
                      <NavDropdown.Item eventKey="4.4">
                        <div className="">
                          <p style={{ marginBottom: "0" }}>
                            Welcome {user.name}
                          </p>
                        </div>
                      </NavDropdown.Item>
                    </NavDropdown.Item>
                  </NavDropdown>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationHeader;
