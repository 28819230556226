import { Router } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, Component } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router';
import ConfirmationPopup from './components/ConfirmationPopup';


class Confirm extends Component {

    async componentDidMount() {
        // this.handleToast()

        let userId = this.props.match.params;
        console.log('hi', userId.id)


        const res = await axios.put(`https://frontend.mypeerformance.com:8080/api/users/authorizeaccount/${userId.id}`)
        const data = res.data
        console.log(data)


        setTimeout(() => {
            toast.success("User Validated Successfully")
            const { history } = this.props
            history.push("/");
        }, 1000)







    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default Confirm