import { useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from "react-redux";
import { getStudyData } from '../store/dashboardActions';
import { fetchGetStudyData } from '../store/dashboardFetches';
import { useState } from 'react';
import "./TableItems.css";
function TableItems({ ValidUntil, study, userSubsID, ...props}) {
    const [StudData, setStudData] = useState("")
    const dispatch = useDispatch();
    
    const dateCheck = new Date(ValidUntil);
    const currentDate = new Date();

console.log(props, "USERSUB");


    function DataStatusChecker (date) {
        console.log(date,"ayyy")
        if (date.length < 1){
            return (
                <Badge style={{background:"var(--danger)",paddingRight:"0.6em", paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}} text="dark">
                    No data submitted
                </Badge>
                )
        } else if ( date[date.length-1] )


                //Do this
        if ( date[date.length-1]){
            return (
            <Badge style={{background:"var(--warning)",paddingRight:"0.6em", paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}} text="dark">
                Expiring on {dateCheck.toLocaleDateString("en-GB")}
            </Badge> 
        )} else { return (
            <Badge style={{background:"var(--success)",paddingRight:"0.6em", paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}} text="dark">
            Expiring on {dateCheck.toLocaleDateString("en-GB")}
        </Badge> 
                )
        }

    };






    useEffect(() => {
        fetchGetStudyData(userSubsID).then(response => setStudData(DataStatusChecker(response)));
        
      }, [dispatch]);

      console.log(StudData, "MCLLLL");

    


    console.log(study.name,"ALILAI")
    
    
        




    function dateChecker (){

        const diffTime = Math.abs(currentDate - dateCheck)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        console.log(diffDays ," + DAYS" )
        return  diffDays 
    }

   
    function BadgerRenewlStatus () {
        if (dateCheck < currentDate) {
            return (
        
            <Badge  style={{background:"var(--danger)",paddingRight:"0.6em",paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}}>
                Expired on {dateCheck.toLocaleDateString("en-GB")}
            </Badge>        
            )
    } else if (dateCheck > currentDate && dateChecker() < 13){
        return(
            <Badge style={{background:"var(--warning)",paddingRight:"0.6em", paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}} text="dark">
                Expiring on {dateCheck.toLocaleDateString("en-GB")}
            </Badge>            
            )
    } else {
        return(     
            <Badge style={{background:"var(--success)",paddingRight:"0.6em", paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}}>
                Valid until {dateCheck.toLocaleDateString("en-GB")}
            </Badge>     
            ) 
        }
    }
    
    const statusChecker  = () => {
        if (study.Status == 0 ){
            return (
            <Badge  style={{background:"var(--danger)",paddingRight:"0.6em",paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}}>
                Inactive
            </Badge>)   
        }
        //  else if(){
        //     QUEUED STUDIES SOLUTION HERE
        // }
        else {
            return (
            <Badge  style={{background:"var(--success)",paddingRight:"0.6em",paddingLeft:"0.6em",borderRadius:"10rem", cursor: "pointer"}}>
                Active
            </Badge>)    
        }
        }
    

        




    return(

   <tr>
    <td>
        {study.Name}
    </td>
    <td>
        {StudData}
    </td>
    <td>
        
        {BadgerRenewlStatus()}
        
    </td>
    <td>
        £1000
    </td>
    <td>
        {statusChecker()}
    </td>
   </tr> 
   )

    }
 export default TableItems

