
import './ProfilePayments.css';









function ProfilePayments () {




    return (
      <div
        style={{
          width: "77%",
          height: "82vh",
          margin: "auto",
          overflowY: "auto",
        }}
      >
        <div
          className="container"
          style={{
            padding: "2rem",
            background: "#1F2748",
            borderRadius: "0.5rem",
          }}
        >
          <h1 style={{ marginBottom: "3rem" }}>Payments</h1>

          <div className="row mt-5 mb-5">
            <div
              className=" mx-auto"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p style={{ width: "45%", color: "#37D881" }}>
                Tokens can be bought and stored in your account. Each token
                gives you 12 months of analysis from the moment you assign it to
                a study. Tokens are not transferable and non refundable.
              </p>
              <button
                style={{
                  width: "45%",
                  color: "#FFFFFF",
                  border: "2px solid #4063EC",
                  background: "none",
                  display: "inline-block",
                  width: "fit-content",
                  borderRadius: "0.2rem",
                  height: "fit-content",
                  padding: "1rem",
                }}
              >
                Purchase Tokens
              </button>

              <div
                htmlFor="tokenAmount"
                style={{ color: "#37D881", paddingTop: "1.5%" }}
              >
                Tokens Remaining:
              </div>

              <div
                style={{
                  background: "#C1CBDC",
                  width: "8rem",
                  height: "8rem",
                  borderRadius: "100%",
                  marginTop: "-30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  id="tokenAmount"
                  style={{
                    color: "#37D881",
                    fontSize: "36px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  10
                </div>
              </div>
            </div>
          </div>

          <h5 style={{ fontWeight: "100" }}>Payment Method</h5>

          <div className="row">
            <div className=" mx-auto">
              <form className=" nova2lidate ">
                <div className="row mt-3 ">
                  <div
                    className=" p-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="group-input" style={{ width: "45%" }}>
                      <label className="form-label" htmlFor="cardNumber">
                        Card Details
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="cardNumber"
                        required
                        disabled
                        placeholder="XXXX-XXXX-XXXX-1234"
                        style={{
                          background: "#262D51",
                          border: "none",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="group-input " style={{ width: "45%" }}>
                      <label className="form-label" htmlFor="password">
                        Expiry date
                      </label>
                      <div
                        class="form-control expiration"
                        style={{ background: "#262D51", border: "none" }}
                      >
                        <input
                          type="text"
                          name="month"
                          placeholder="12"
                          maxlength="2"
                          size="2"
                          disabled
                          style={{
                            background: "#262D51",
                            border: "none",
                            color: "white",
                          }}
                        />
                        <span style={{ color: "#757575", paddingRight: "5px" }}>
                          /
                        </span>
                        <input
                          type="text"
                          name="year"
                          placeholder="24"
                          maxlength="2"
                          size="2"
                          disabled
                          style={{
                            background: "#262D51",
                            border: "none",
                            color: "white",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ fontWeight: "100" }}>
                    + Add New Payment Method
                  </div>
                </div>
              </form>
            </div>
          </div>

          <h5 style={{ fontWeight: "100", marginTop: "3rem" }}>
            Billing Address
          </h5>

          <div className="row">
            <div className=" mx-auto">
              <form className=" nova2lidate ">
                <div className="group-input" style={{ width: "45%" }}>
                  <p>Save as Company Address</p>

                  <input
                    className="payment-radio"
                    type="radio"
                    id="paymentYes"
                    name="paymentRadio"
                    required
                    style={{
                      background: "#262D51",
                      border: "none",
                      color: "white",
                    }}
                  />
                  <label for="paymentYes">Yes</label>
                  <input
                    className="payment-radio"
                    type="radio"
                    id="paymentNo"
                    name="paymentRadio"
                    required
                    style={{
                      background: "#262D51",
                      border: "none",
                      color: "white",
                    }}
                  />
                  <label for="paymentNo">No</label>
                </div>
                <div className="row mt-3 ">
                  <div
                    className=" p-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="group-input" style={{ width: "45%" }}>
                      <input
                        className="form-control"
                        type="text"
                        id="AddyL1"
                        required
                        placeholder="Address Line One"
                        style={{
                          background: "#262D51",
                          border: "none",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="group-input " style={{ width: "45%" }}>
                      <div
                        class="form-control expiration"
                        style={{ background: "#262D51", border: "none" }}
                      >
                        <input
                          className="form-control"
                          type="text"
                          id="addyL2"
                          required
                          placeholder="Address Line Two"
                          style={{
                            background: "#262D51",
                            border: "none",
                            color: "white",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3 ">
                  <div
                    className=" p-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="group-input" style={{ width: "45%" }}>
                      <input
                        className="form-control"
                        type="text"
                        id="townCity"
                        required
                        placeholder="Town / City"
                        style={{
                          background: "#262D51",
                          border: "none",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="group-input " style={{ width: "45%" }}>
                      <div
                        class="form-control expiration"
                        style={{ background: "#262D51", border: "none" }}
                      >
                        <input
                          className="form-control"
                          type="text"
                          id="cardNumber"
                          required
                          placeholder="Postcode"
                          style={{
                            background: "#262D51",
                            border: "none",
                            color: "white",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <button
            style={{
              color: "white",
              background: "#37D881",
              padding: "1rem",
              borderRadius: "0.5rem",
              border: "none",
              marginLeft: "88%",
              marginTop: "1rem",
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    );
}

export default ProfilePayments