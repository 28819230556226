import React from "react";
import { useContext } from "react";
import { Cntx } from "../context/context";
import RenderIf from "../renderif/RenderIf";
import copyIcon from "../Styleguide/icons/copy.svg";
import { peerformanceTextBabyBlue } from "../colours";
import openBookIcon from "../Styleguide/book-open.svg";
import rightArrowIcon from "../Styleguide/icons/chevron-right.svg";
import { dashboardActions } from "../store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { ContactsOutlined } from "@mui/icons-material";

const ActiveStudies = ({ activeStudies, activeStudy, onActiveStudyClick }) => {
  const addSugestedStudy = useSelector(
    (state) => state.dashboard.addSugestedStudy
  );

  console.log("activeStudies", activeStudies, activeStudy);
  const dispatch = useDispatch();
  const contextuser = useContext(Cntx);
  const handleaddSugestedstudy = (addSugestedStudy) => {
    console.log("addSugestedStudy", addSugestedStudy);
    // return async dispatch => {
    dispatch(dashboardActions.setAddSugestedStudy(!addSugestedStudy));
    // }
  };
  return (
    <div className="active-studies-nav">
      <div className="container-fluid">
        <p>
          <img className="open-book-icon" src={openBookIcon} alt="" />
          ACTIVE STUDIES ({activeStudies?.length})
        </p>

        <hr />
        <RenderIf isTrue={contextuser.val == true}>
          {null}
          {/* <div className="data-tooltip-info">
            <span data-title="These are the studies which the user is subscribed to.  The user is expected to submit data to each one when prompted each month">
              <img
                src="https://img.icons8.com/metro/26/000000/info.png"
                class="info-icon"
                alt=""
              />
            </span>
          </div> */}
        </RenderIf>
        <div className="active-studies-container">
          <RenderIf isTrue={activeStudies.length > 0}>
            {activeStudies?.map((actStudy) => (
              <div
                className="row active-studies-row"
                key={actStudy.ID}
                onClick={() => {
                  onActiveStudyClick(actStudy);

                  handleaddSugestedstudy(true);
                }}
                style={{
                  color:
                    actStudy.StudyID === activeStudy?.ID
                      ? "#38d881"
                      : peerformanceTextBabyBlue,
                }}
              >
                <div className="col-sm-1">
                  {/* {actStudy.StudyID === activeStudy?.ID ? */}
                  {actStudy.StudyID === activeStudy?.ID ? (
                    <img
                      src={rightArrowIcon}
                      style={{ marginRight: "", marginTop: "4px" }}
                      className="float-end"
                      alt=""
                    />
                  ) : null}
                </div>
                <RenderIf isTrue={activeStudies?.length > 0 == true}>
                  <div
                    style={{ paddingLeft: 0 }}
                    className="col-sm-10 col-md-10 col-lg-10 col-xl-10 text-elipsed"
                  >
                    {actStudy.NumOfSubscribers < 5 ? (
                      <img
                        src={copyIcon}
                        style={{ marginRight: "5px", marginTop: "4px" }}
                        className="float-start"
                        alt=""
                      />
                    ) : null}

                    {actStudy?.study?.Name}
                  </div>
                </RenderIf>
              </div>
            ))}
          </RenderIf>
          {/* <RenderIf isTrue={activeStudies?.length <= 0}>
            {" "}
            <div>
              <h4>No Study Yet</h4>
            </div>{" "}
          </RenderIf> */}
        </div>
      </div>
    </div>
  );
};

export default ActiveStudies;
