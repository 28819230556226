const { io } = require("socket.io-client");
const socket = io("https://frontend.mypeerformance.com:8080");

module.exports.JoinSocket = (token, cb) => {
  socket.emit("join", { token }, cb);
};

module.exports.onNewNotification = (cb) => {
  socket.on("new_notification", (notification) => {
    console.log("new_notification", notification);
    cb(notification);
  });
};

module.exports.offSocketNewNotificationListener = (cb) => {
  socket.off("new_notification", cb);
};
