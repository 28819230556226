import React from "react";
import bookWithPlusIcon from "../Styleguide/Page-1.svg";
import copyIcon from "../Styleguide/icons/copy.svg";
import check from "../Styleguide/icons/check.svg";
import { dashboardActions } from "../store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { Cntx } from "../context/context";
import RenderIf from "../renderif/RenderIf";

const QueuedStudies = ({
  activeStudy,
  queuedStudies,
  userSubscriptions,
  onStudyClick,
}) => {
  const addSugestedStudy = useSelector(
    (state) => state.dashboard.addSugestedStudy
  );
  const dispatch = useDispatch();
  const activeStudy123 = useSelector((state) => state.dashboard.activeStudy);
  const contextuser = useContext(Cntx);
  console.log('userSubscriptions', userSubscriptions)
  let element = document.getElementById("participant_breakdown");
  
  const handleaddSugestedstudy = (addSugestedStudy) => {
    console.log("addSugestedStudy", addSugestedStudy);
    // return async dispatch => {
    dispatch(dashboardActions.setAddSugestedStudy(!addSugestedStudy));
    // }
  };
  return (
    <div className="queued-studies-nav">
      <div className="container-fluid">
        <p style={{ marginLeft: " 2.5%" }}>
          <img
            src={copyIcon}
            style={{ marginLeft: " -8%", marginRight: "10px" }}
            alt=""
          />
          QUEUED STUDIES ({queuedStudies.length})
          <img
            src={bookWithPlusIcon}
            className="float-end card book-with-plus-icon"
            alt=""
            onClick={() => handleaddSugestedstudy(addSugestedStudy)}
          />
        </p>

        <div className="studies-horizontal-line"> </div>
        <RenderIf isTrue={contextuser.val == true}>
          {null}
          {/* <div className="data-tooltip-info">
            <span
              data-title="Queued studies relate to the active Peer Group listed above.  Queued studies that have been suggested by Users.  Other users can join these studies if interested.  When there are 5 users in one study it will go live and show in the Available studies above.
Queued studies can be suggested by clicking the green bookmark icon
"
            >
              <img
                src="https://img.icons8.com/metro/26/000000/info.png"
                class="info-icon"
                alt=""
              />
            </span>
          </div> */}
        </RenderIf>

        <div
          className={`row col-sm-12 ${
            !addSugestedStudy ? "studies-container1" : "studies-container"
          } ${
            addSugestedStudy && !activeStudy123 ? "studies-container2" : ""
          } `}
          style={{ display: "block" }}
        >
          {queuedStudies.map((studyItem) => {
            const isVisible =
              userSubscriptions.find(
                (sub) => studyItem?.ID === sub?.StudyID
              ) !== undefined;

            return (
              <div
                className={activeStudy?.ID === studyItem.ID ? "active" : null}
                onClick={() => {
                  onStudyClick(studyItem);
                  handleaddSugestedstudy(true);
                }}
                key={studyItem.ID}
              >
                <img
                  style={{
                    visibility: isVisible ? "visible" : "hidden",
                  }}
                  src={check}
                  alt="check-icon"
                />
                <span style={{ marginLeft: "10px" }}>{studyItem.Name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QueuedStudies;
