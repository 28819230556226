import { useState , useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { dashboardActions } from "./store/dashboard";
import { useDispatch, useSelector } from "react-redux";

export default function SuggestStudyOptions({ 
  suggestStudy, 
  measure1, 
  measure2, 
  measure3, 
  setMeasure1, 
  setMeasure2,  
  setMeasure3, 
  producedBy, 
  setProducedBy,
  about,
  name }) {


  const [percentCheckBox, setPercentCheckBox] = useState(false);
  const [absoluteCheckBox, setAbsoluteCheckBox] = useState(false);
  const [averageTracker, setAverageTracker] = useState(false);
   const [isOpen, setIsOpen] = useState(false);

   const handleToggle = () => {
     setIsOpen(!isOpen);
   };

   const handleKeyDown = (event) => {
     if (event.key === " ") {
       event.preventDefault(); 
     }
   };














        const addSugestedStudy = useSelector(
          (state) => state.dashboard.addSugestedStudy
        );

          const handleSpacebarKeyDown = (event) => {
            if (event.key === "32") {
              event.preventDefault();
              event.stopImmediatePropagation();
              event.stopPropagation();
            }
          };

          useEffect(() => {
            const element = document.getElementById("dropdown-basic"); 

            if (element) {
              element.addEventListener("keyup", handleSpacebarKeyDown);
            }

            return () => {
              if (element) {
                element.removeEventListener("keyup", handleSpacebarKeyDown);
              }
            };
          }, []);



      const dispatch = useDispatch();    

      const handleaddSugestedstudy = (addSugestedStudy) => {
        
        dispatch(dashboardActions.setAddSugestedStudy(!addSugestedStudy));
        
      };

     



    const handlePercentCheckBox = (e) => {
      
      setPercentCheckBox(true);
      setAbsoluteCheckBox(false);
      setAverageTracker(false);
      setMeasure1("percentChange");
      setMeasure2("");
      setMeasure3("");
    };

    const handleAbsoluteCheckBox = () => {
      setPercentCheckBox(false);
      setAbsoluteCheckBox(true);
      setAverageTracker(false);
      setMeasure1("");
      setMeasure2("");
      setMeasure3("absoluteValue");


    };

    const handleAverageTracker = () => {
      
      setPercentCheckBox(false);
      setAbsoluteCheckBox(false);
      setAverageTracker(true);
      setMeasure1("");
      
      setMeasure3("");

    };

    const suggestStudyHandler = () => {
      let measure = ""
      if(percentCheckBox === true){
        measure = "percentCheckBox";
      } else if( absoluteCheckBox === true){

        measure = "absoluteCheckBox"
      } else if (averageTracker === true){
        measure = "averageTracker"

    }
    console.log(measure, "measures")
      if (about && name && measure){
        suggestStudy();
        handleaddSugestedstudy(addSugestedStudy);

      }else {
              toast.error(
                "Please select study type, insert study name and include basic description"
              );
              console.log(about,name,measure, "ABOUT")
      }
      




    }


      const handleInputClick = (e) => {
        e.stopPropagation();
      };




  return (
    <div className="study-type-section">
      <div className="row">
        <div className="col-sm-12 mt-40">
          <h2 className="h2-study">STUDY TYPE</h2>
        </div>
      </div>
      <div className="row mb-20">
        <div className="col-md-3 mb-20">
          <div id="percentage">
            {/* <div className="dropdown"> */}
            <div
              className="btn btn-secondary"
              role="button"
              onClick={handlePercentCheckBox}
            >
              Percentage Change
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="checkboxToken"
                style={{
                  borderColor: "#1c6c40",
                  left: "95%",
                  bottom: "33%",
                }}
                checked={percentCheckBox}
                onChange={handlePercentCheckBox}
              />
            </div>

            {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <div className="dropdown-item">
                <label className="label">Measure</label>
                <input
                type="text"
                value={measure1}
                onChange={(e) => setMeasure1(e.target.value)}
                />
                </div>
              </div> */}
            {/* </div> */}
          </div>
        </div>
        <div className="col-md-3 mb-20">
          <div id="efficiency" onClick={handleAverageTracker}>
            <Dropdown show={isOpen} onToggle={handleToggle}>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"

                // onClick={handleAverageTracker}
              >
                Average
              </Dropdown.Toggle>

              <Dropdown.Menu onKeyDown={handleKeyDown}>
                <Dropdown.Item>
                  <label className="label">Measure</label>
                  <input
                    type="text"
                    value={measure2}
                    onChange={(e) => setMeasure2(e.target.value)}
                    onClick={handleInputClick}
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <label className="label">Produced by</label>
                  <input
                    type="text"
                    value={producedBy}
                    onChange={(e) => setProducedBy(e.target.value)}
                    onClick={handleInputClick}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <div id="efficiency">
            <div className="dropdown">
              <div
                className="btn btn-secondary dropdown-toggle"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                
                > Average
              </div>

              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <div className="dropdown-item">
                  <label className="label">Measure</label>
                  <input
                    type="text"
                    value={measure2}
                    onChange={(e) => setMeasure2(e.target.value)}
                  />
                </div>
                <div className="dropdown-item">
                  <label className="label">Produced by</label>
                  <input
                    type="text"
                    value={producedBy}
                    onChange={(e) => setProducedBy(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-md-3 mb-20">
          <div id="absolute">
            <div className="">
              <div
                className="btn btn-secondary "
                role="button"
                onClick={handleAbsoluteCheckBox}
              >
                Absolute Number
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="checkboxToken"
                  style={{
                    borderColor: "#1c6c40",
                    left: "95%",
                    bottom: "33%",
                  }}
                  checked={absoluteCheckBox}
                  onChange={handleAbsoluteCheckBox}
                />
              </div>

              {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <div className="dropdown-item">
                    <label className="label">Measure</label>
                    <input
                      type="text"
                      value={measure3}
                      onChange={(e) => setMeasure3(e.target.value)}
                    />
                  </div>
                </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-20">
          <div id="submitStudy">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={suggestStudyHandler}
            >
              Submit Study for review
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


