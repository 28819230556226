export const feedbackMessages = {
	name: 'Full name must not be empty',
	company: 'Company name must not be empty',
	emailRequired: 'Email is required',
	invalidEmail: 'Invalid email',
	emptyPassword: 'Password cannot be empty',
	passwordRequirements: 'Password should has:\n - At least 10 characters long\n - Should include combination of uppercase letters, lowercase letters, numbers, and symbols\n - Should include at least 1 entry from every group of the above',
	emptyConnfirmPassword: 'Confirmation password cannot be empty',
	samePasswords: 'Passwords should be the same',
	noOfEmployees: 'Select the employees',
	companyReach: 'company reached',
	answer1: 'gives the answer of question'
};
