import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: "",
  lastRegionSelected: "",
  selectedPeerGroup: "",
  regions: [],
  userInfo: undefined,
  examplestudies: [],
  activeexamplestudies: [],
  toggleQEffect: false,
  userSubscriptions: [],
  availableStudies: [],
  queuedStudies: [],
  allStudies: [],
  studyData: undefined,
  activeStudy: undefined,
  addSugestedStudy: false,
  showExampleStudy: false,
  categories: [],
  // Participant breakdown
  participantBreakDown: [],

  // Top 3 regions
  top3Regions: [],

  // Region child
  regionChildren: [],

  // Region child
  citesChildren: [],

  // study Data By Region
  studyDataByRegion: [],

  lastPeerGroupSelected4Or5: "",

  lastPeerGroupSelected6: "",

  lastStudySubscribed: "",

  //Faizan Zafar
  notificationsData: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setLastRegionSelected(state, action) {
      state.lastRegionSelected = action.payload;
    },

    setToggleQEffect(state, action) {
      state.toggleQEffect = action.payload;
    },
    setLastStudySubscribed(state, action) {
      state.lastStudySubscribed = action.payload;
    },

    setUser(state, action) {
      state.user = action.payload;
    },
    setRegions(state, action) {
      state.regions = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setAddSugestedStudy(state, action) {
      state.addSugestedStudy = action.payload;
    },
    setShowExampleStudy(state, action) {
      state.showExampleStudy = action.payload;
    },
    groupStudies(state, action) {
      const { studies, studyId } = action.payload;
      console.log("<><><><><><><><><><><><>", studies);
      studies.sort((a, b) => b.NumOfSubscribers - a.NumOfSubscribers);

      const availableStudies = studies.filter(
        (study) => study.NumOfSubscribers >= 5
      );
      const queuedStudies = studies.filter(
        (study) => study.NumOfSubscribers < 5
      );

      state.availableStudies = availableStudies;
      state.queuedStudies = queuedStudies;
      state.allStudies = studies;
      console.log("queuedStudies", queuedStudies);
      //FUCK THIS PIECE OF CODE SPECIFICALLY.
      // if (availableStudies.length && !studyId) {
      //     state.activeStudy = availableStudies[0];

      // } else if (availableStudies.length && studyId) {
      //     state.activeStudy = availableStudies.find(study => study.ID === studyId);
      // }
    },

    setUserSubscriptions(state, action) {
      const subscriptions = action.payload;
      state.userSubscriptions = subscriptions;
    },
    setCategories(state, action) {
      const categories = action.payload;
      state.categories = categories;
    },
    setExamplestudies(state, action) {
      let subscriptions = [];
      let avlexamplestudies = [];
      subscriptions = action.payload.studies;
      avlexamplestudies = action.payload.studies;
      subscriptions = subscriptions.map((item) => {
        console.log(item, "checkItem");
        let study = {
          ID: item.id,
          UserID: localStorage.getItem("id"),
          StudyID: item.id,
          SubscriptionTypeID: item.studytypeId,
          Activated: "2022-06-16T00:00:00.000Z",
          ValidUntil: "2083-06-30T00:00:00.000Z",
          Active: item.activestatus,
          Created: item.createdAt,
          Updated: item.updatedAt,
          RegionID: item.regionId,
          PeerGroupID: item.categoryId,
          TypeID: item.studytypeId,
          NumOfSubscribers: 0,
          About: item.about,
          ParentCategory: item.category.parentsic,
          Category: item.category.category,
          study: {
            ID: item.id,
            Name: item.name,
            Status: item.status,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          },
        };
        return study;
      });
      state.examplestudies = subscriptions;
      let activeExample = avlexamplestudies.map((item) => {
        let exstudy = {
          About: item.about,
          Activestatus: 1,
          Category: item.category.category,
          ID: item.id,
          Name: item.name,
          NumOfSubscribers: 0,
          ParentCategory: "",
          PeerGroupID: item.categoryId,
          RegionID: item.regionId,
          Status: "1",
          TypeID: item.studytypeId,
          createdAt: "2022-06-27T05:17:25.000Z",
          updatedAt: "2022-06-27T15:07:32.000Z",
        };
        return exstudy;
      });

      state.activeexamplestudies = activeExample;
    },
    setStudyData(state, action) {
      console.log("action.payloadaction.payloadaction.payload", action.payload);

      state.studyData = action.payload;
    },
    setActiveStudy(state, action) {
      console.log("setActiveStudy", action);
      state.activeStudy = action.payload;
    },

    //  Participant breakdown
    setParticipantBreakDown(state, action) {
      state.participantBreakDown = action.payload;
    },

    //  top 3 regions
    setTop3Regions(state, action) {
      state.top3Regions = action.payload;
    },

    // Set the region Children
    setRegionChildren(state, action) {
      state.regionChildren = action.payload;
    },

    // Set the region Children
    setCityChildren(state, action) {
      state.citesChildren = action.payload;
    },

    // study Data By Region
    studyDataByRegion(state, action) {
      state.top3Regions = action.payload;
    },

    // study Data By Region
    setLastPeerGroupSelected4Or5(state, action) {
      state.lastPeerGroupSelected4Or5 = action.payload;
    },
    // study Data By Region
    setLastPeerGroupSelected6(state, action) {
      //state.lastPeerGroupSelected6 = action.payload;
      state.lastPeerGroupSelected4Or5 = action.payload;
    },

    // Faizan Zafar
    setNotificationsData(state, action) {
      state.notificationsData = action.payload;
    },
    addNotification(state, action) {
      const newNotification = action.payload;
      const notifications = [...state.notificationsData];
      const isExist = notifications.find((n) => n.id == newNotification.id);
      if (!isExist) notifications.push(newNotification);
      state.notificationsData = notifications;
    },
    updateNotification(state, action) {
      const notificationId = action.payload;
      const notifications = [...state.notificationsData];
      const index = notifications.findIndex((x) => x.id == notificationId);
      if (index >= 0) notifications[index].isRead = 1;
      state.notificationsData = notifications;
    },
    deleteNotification(state, action) {
      const notificationId = action.payload;
      const notifications = [...state.notificationsData];
      const index = notifications.findIndex((x) => x.id == notificationId);
      if (index >= 0) {
        notifications.splice(index, 1);
      }
      state.notificationsData = notifications;
    },
    // end Faizan
  },
});

export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
