import React from "react";
import { useContext } from "react";
import ribbon from "../Styleguide/icons/ribbon.svg";
import searchIcon from "../Styleguide/search_icon.svg";
import check from "../Styleguide/icons/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../store/dashboard";
import { Cntx } from "../context/context";
import RenderIf from "../renderif/RenderIf";

const AvailableStudies = ({
  activeStudy,
  availableStudies,
  userSubscriptions,
  onStudyClick,
}) => {
  const addSugestedStudy = useSelector(
    (state) => state.dashboard.addSugestedStudy
  );
  const dispatch = useDispatch();
  const activeStudy123 = useSelector((state) => state.dashboard.activeStudy);
  const showExampleStudystate = useSelector(
    (state) => state.dashboard.showExampleStudy
  );
  const contextuser = useContext(Cntx);

  console.log("availableStudies", { availableStudies });
  
  const handleaddSugestedstudy = (addSugestedStudy) => {
    console.log("addSugestedStudy", addSugestedStudy);
    // return async dispatch => {
    dispatch(dashboardActions.setAddSugestedStudy(!addSugestedStudy));
    // }
  };
  return (
    <div className="available-studies-nav">
      <div style={{ paddingRight: "6px" }} className="container-fluid">
        <p style={{ marginLeft: " 2.5%" }}>
          <img
            src={ribbon}
            style={{ marginLeft: " -8%", marginRight: "10px" }}
            alt=""
          />
          AVAILABLE STUDIES ({availableStudies.length})
          <img src={searchIcon} className="float-end card search-icon" alt="" />
        </p>
        <div className="studies-horizontal-line" />
        <RenderIf isTrue={contextuser.val == true}>
          {null}
          {/* <div className="data-tooltip-info">
            <span data-title="Available studies relate to the Active Peer Group.  These are studies which have more than 5 users subscribed to it and can be participated in immediatly">
              <img
                src="https://img.icons8.com/metro/26/000000/info.png"
                class="info-icon"
                alt=""
              />
            </span>
          </div> */}
        </RenderIf>
        <div
          className={`row col-sm-12 ${
            !addSugestedStudy ? "studies-container1" : "studies-container"
          } ${
            addSugestedStudy && !activeStudy123 ? "studies-container2" : ""
          }  `}
          style={{ display: "block" }}
        >
          {availableStudies.map((study) => (
            <div
              key={study.ID}
              className={activeStudy?.ID === study.ID ? "active" : null}
              onClick={() => {
                onStudyClick(study);
                handleaddSugestedstudy(true);
              }}
            >
              <img
                style={{
                  visibility: userSubscriptions.some(
                    (sub) => study?.ID === sub?.StudyID
                  )
                    ? "visible"
                    : "hidden",
                }}
                src={check}
                alt="check-icon"
              />
              <span style={{ marginLeft: "10px" }}>{study.Name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailableStudies;
