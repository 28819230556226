import axios from "axios";
import { useSelector } from "react-redux";
import { dashboardActions } from "./dashboard";
import {
  fetchRegions,
  fetchUserInfo,
  fetchUserSubscriptions,
  addSubscription,
  updatePeerGroup,
  fetchAddStudyData,
  fetchGetStudyData,
  fetchTop3Level,
  fetchRegionChild,
  fetchParticipantBreakDown,
  fetchStudyByRegion,
  getExamplestudies,
  fetchexParticipantBreakDown,
  fetchGetExampleStudyData,
  updateRegion,
  fetchRegionById,
  fetchPeerGroup,
  fetchParentPeerGroupBysic,
  fetchNotificationsData,
  updateNotification,
  deleteNotification,
} from "./dashboardFetches";

// participant breakdown data
export const getParticipantBreakdown = (studyId) => {
  return async (dispatch, getState) => {
    const participantBreakdown = await fetchParticipantBreakDown(studyId);
    dispatch(dashboardActions.setParticipantBreakDown(participantBreakdown));
  };
};
export const getExampleParticipantBreakdown = () => {
  return async (dispatch, getState) => {
    const participantBreakdown = await fetchexParticipantBreakDown();
    dispatch(dashboardActions.setParticipantBreakDown(participantBreakdown));
  };
};
export const getExamplestudieslist = () => {
  return async (dispatch) => {
    let studies = await getExamplestudies();

    let study = {
      ID: studies[0].id,
      UserID: localStorage.getItem("id"),
      StudyID: studies[0].id,
      SubscriptionTypeID: studies[0].studytypeId,
      Activated: "2022-06-16T00:00:00.000Z",
      ValidUntil: "2083-06-30T00:00:00.000Z",
      Active: studies[0].activestatus,
      Created: studies[0].createdAt,
      Updated: studies[0].updatedAt,
      RegionID: studies[0].regionId,
      PeerGroupID: studies[0].categoryId,
      TypeID: studies[0].studytypeId,
      NumOfSubscribers: 0,
      About: studies[0].about,
      ParentCategory: studies[0].category.parentsic,
      Category: studies[0].category.category,
      study: {
        ID: studies[0].id,
        Name: studies[0].name,
        Status: studies[0].status,
        createdAt: studies[0].createdAt,
        updatedAt: studies[0].updatedAt,
      },
    };
    let exstudy = {
      ID: study.StudyID,
      Name: study.study.Name,
      RegionID: study.RegionID,
      PeerGroupID: study.PeerGroupID,
      TypeID: study.TypeID,
      NumOfSubscribers: study.NumOfSubscribers,
      About: study.About,
      Status: study.study.Status,
      Activestatus: study.Active,
      Category: study.Category,
      ParentCategory: study.ParentCategory,
      createdAt: study.study.createdAt,
      updatedAt: study.study.updatedAt,
    };
    console.log(exstudy, "HERE");
    dispatch(dashboardActions.setActiveStudy(exstudy));
    dispatch(getExampleStudyData());
    dispatch(getExampleParticipantBreakdown());
    await dispatch(dashboardActions.setExamplestudies({ studies }));

    // const availableStudies = studies.filter(study => study.Status == 1);

    // if (availableStudies.length && !studyId) {
    //     dispatch(getStudyData(availableStudies[0].ID));
    //     dispatch(getParticipantBreakdown(availableStudies[0].ID));

    // } else if (availableStudies.length && studyId) {
    //     dispatch(getStudyData(studyId));
    // }
  };
};

export const getStudies = (peerGroupID, regionID, studyId = undefined) => {
  return async (dispatch) => {
    console.log("checkcheckcheck");
    let studies = await fetchStudyByRegion(peerGroupID, regionID);
    // localStorage.setItem("parentCategory" )

    let fetchPeerById = await fetchPeerGroup(peerGroupID);
    console.log(fetchPeerById, "CHEX");
    localStorage.setItem("peerGroupName", fetchPeerById.Category);
    let fetchParentPeerBySIC = await fetchParentPeerGroupBysic(
      fetchPeerById.ParentSIC
    );
    localStorage.setItem("fetchedLevel4SIC", fetchPeerById.ParentSIC);

    console.log(fetchParentPeerBySIC, "CHEX");
    console.log(fetchPeerById, "CHOK");
    localStorage.setItem("parentCatergory", fetchParentPeerBySIC);

    dispatch(dashboardActions.groupStudies({ studies, studyId }));

    const availableStudies = studies.filter(
      (study) => study.NumOfSubscribers >= 5
    );
    if (studies.length <= 0) {
      dispatch(setActiveStudy(null));
    } else {
      if (availableStudies.length && !studyId) {
        console.log("CHECKER");
        dispatch(getStudyData(availableStudies[0].ID));
        dispatch(getParticipantBreakdown(availableStudies[0].ID));
      } else if (availableStudies.length && studyId) {
        dispatch(getStudyData(studyId));
      }
    }
  };
};

// Get top 3 levels
export const getTop3Regions = () => {
  return async (dispatch) => {
    const top3regions = await fetchTop3Level();
    dispatch(dashboardActions.setTop3Regions(top3regions));
  };
};

// Get region child
export const getRegionChildren = (parent) => {
  return async (dispatch) => {
    const regionChildren = await fetchRegionChild(parent);
    const regionChildrenFilteredByLevel4 = regionChildren.filter(
      (item) => item.Level == 4
    );

    console.log("fixApi", regionChildrenFilteredByLevel4);
    const regionSort = regionChildrenFilteredByLevel4
      .sort((a, b) => b.ID - a.ID)
      .reverse();
    dispatch(dashboardActions.setRegionChildren(regionSort));
  };
};

// Get region city child
export const getCityChildren = (parent) => {
  return async (dispatch) => {
    const regionChildren = await fetchRegionChild(parent);
    const regionChildrenFilteredByLevel5 = regionChildren.filter(
      (item) => item.ParentID == parent
    );
    console.log("fixApi2", regionChildrenFilteredByLevel5, parent);
    dispatch(dashboardActions.setCityChildren(regionChildrenFilteredByLevel5));
  };
};

// Get all regions
export const getRegions = () => {
  return async (dispatch) => {
    const regions = await fetchRegions();
    dispatch(dashboardActions.setRegions(regions));
  };
};

export const updateLastRegion = (regionID) => {
  return async () => {
    const region = await updateRegion(regionID);
  };
};

export const updateLastPeerGroup = (id) => {
  return async (dispatch) => {
    const studies = await updatePeerGroup(id);
  };
};

export const getRegionById = (id) => {
  return async (dispatch) => {
    let regionById = await fetchRegionById(id);

    console.log(regionById, "CHECKCHECK");
    dispatch(dashboardActions.setLastRegionSelected(regionById));
  };
};

export const getUserInfo = () => {
  return async (dispatch) => {
    const userInfo = await fetchUserInfo();
    let regionById;
    let peerGroupById;
    dispatch(dashboardActions.setUserInfo(userInfo));
    // if (userInfo && userInfo.Peer_Group_ID) {
    if (userInfo && userInfo.Peer_Group_ID) {
      //Setting the region id extracted from the backend/db
      if (userInfo.RegionID) {
        getRegionById(userInfo.RegionID);
        localStorage.setItem("storedRegionId", userInfo.RegionID);
        // Setting region

        regionById = await fetchRegionById(userInfo.RegionID);
        let regionState = regionById;
        dispatch(dashboardActions.setLastRegionSelected(regionState));
      }
      // getting the region id from session storage
      
      dispatch(getUserSubscriptions());

      // Set the peer group that the user last selected
      localStorage.setItem("peerGroupId", userInfo.Peer_Group_ID);

      // peerGroupById = await fetchPeerGroup(userInfo.Peer_Group_ID);
      // dispatch(dashboardActions.setLastPeerGroupSelected4Or5(peerGroupById));
    }
  };
};

export const getUserSubscriptions = () => {
  return async (dispatch, getState) => {
    let regionId = localStorage.getItem("regionId");
    const userSubscriptions = await fetchUserSubscriptions();
    
       //FILTER ALL SUBS UNDER 5
    // const filteredSubscriptions = userSubscriptions.filter(
    //   (subscription) => subscription.NumOfSubscribers >= 5
    //   );
    //SORT BY MOST TO LEAST SUBSCRIBERS
    let filteredSubscriptions = userSubscriptions;
    filteredSubscriptions.sort(
      (a, b) => b.NumOfSubscribers - a.NumOfSubscribers
    );

    dispatch(dashboardActions.setUserSubscriptions(filteredSubscriptions));
    console.log(
      userSubscriptions.length,
      "AYYYLMAO",
      filteredSubscriptions,
      userSubscriptions
    );

    const lastStudySubscribed = getState().dashboard.lastStudySubscribed

    let findLastStudySubscribed = filteredSubscriptions.find((study) => study.StudyID === lastStudySubscribed)
    
    if (findLastStudySubscribed > 0 ){
     
      console.log(findLastStudySubscribed,"FINDLAST")
      dispatch(dashboardActions.setActiveStudy(findLastStudySubscribed))
      
      dispatch(
        getStudies(
          findLastStudySubscribed.PeerGroupID,
          findLastStudySubscribed.RegionID
        )
      );
    }else
    {
       
      console.log(
        findLastStudySubscribed,
        "FINDLAST",
        filteredSubscriptions,
        filteredSubscriptions[0],
        lastStudySubscribed
      );
      //USE FIRST INDEX OF ARRAY (HIGHEST SUBS)
      
      if (userSubscriptions.length > 0) 
      {
        console.log("FFS");
        console.log("CHECK1", userSubscriptions);
        dispatch(
          getStudies(
            userSubscriptions[0].PeerGroupID,
            userSubscriptions[0].RegionID
            )
            );
            let studyPC = await fetchParentPeerGroupBysic(
                userSubscriptions[0]?.ParentCategory
                );
                
                let study = {
                  ID: userSubscriptions[0].StudyID,
                  Name: userSubscriptions[0].study.Name,
                  RegionID: userSubscriptions[0].RegionID,
                  PeerGroupID: userSubscriptions[0].PeerGroupID,
                  TypeID: userSubscriptions[0].TypeID,
                  NumOfSubscribers: userSubscriptions[0].NumOfSubscribers,
                  About: userSubscriptions[0].About,
                  Status: userSubscriptions[0].study.Status,
                  Activestatus: parseInt(userSubscriptions[0].Active),
                  Category: userSubscriptions[0].Category,
                  ParentCategory: studyPC,
                  DividedBy: userSubscriptions[0].DividedBy,
                  AverageOf: userSubscriptions[0].AverageOf,
                  createdAt: userSubscriptions[0].study.createdAt,
                  updatedAt: userSubscriptions[0].study.updatedAt,
                };
                
                dispatch(dashboardActions.setActiveStudy(study));
                console.log("AYYLMAO", userSubscriptions[0]);
                UpdateAccount(userSubscriptions[0].RegionID);
                fetchRegionById(userSubscriptions[0].RegionID);
                localStorage.setItem("peerGroupId", userSubscriptions[0].PeerGroupID);
                
                async function fetchPeerbyId(item) {
                  try {
                    let resid = await fetchPeerGroup(item);
                    console.log(resid.Category, "CHECKARRRR");
                    
                    return resid.Category;
                  } catch (error) {
                    // Handle any errors that may occur during the fetch
                    console.error("Error fetching data:", error);
                  }
                }
                
                let peerGroupNameByID = await fetchPeerbyId(
                  userSubscriptions[0].PeerGroupID
                  );
                  localStorage.setItem("peerGroupName", peerGroupNameByID);
                } else {
                  
                  let peerGroupId = localStorage.getItem("peerGroupId");
                  console.log("CHECK1", peerGroupId, regionId);
                  dispatch(getStudies(peerGroupId, regionId));
                }
              };
            }
          };
            
let UpdateAccount = async (regionId) => {
  const body = {
    regionId: regionId,
  };
  console.log("global", body);
  let id = await localStorage.getItem("id");
  
  const res = await axios.put(
    `https://frontend.mypeerformance.com:8080/api/users/update/${id}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
    }
    );
    const data = res.data;
    console.log("regions", res);
  };
  
  export const subscribeStudy = (studyId) => {
    return async (dispatch, getState) => {
      const userSubscriptions = await addSubscription(studyId);
      dispatch(getUserSubscriptions());
    };
  };
  
  export const addStudyData = (value, studyId) => {
    return async (dispatch, getState) => {
      const addDataResult = await fetchAddStudyData(value, studyId);
    };
  };
  
  export const getStudyData = (studyId) => {
    return async (dispatch) => {
      const studyData = await fetchGetStudyData(studyId);
      dispatch(dashboardActions.setStudyData(studyData));
    };
  };
  export const getExampleStudyData = () => {
    return async (dispatch) => {
      const studyData = await fetchGetExampleStudyData();
      dispatch(dashboardActions.setStudyData(studyData));
    };
  };
  
  export const setActiveStudy = (data) => (dispatch) => {
    dispatch(dashboardActions.setActiveStudy(data));
  };
  
  export const toggleQEffect = (data) => (dispatch) => {
    return dispatch(dashboardActions.setToggleQEffect(data));
  };
  
  //  Faizan Zafar
  export const getNotificationsData = () => {
    return async (dispatch) => {
      const notificationsData = await fetchNotificationsData();
      dispatch(dashboardActions.setNotificationsData(notificationsData));
    };
  };
  
  export const updateNotificationData = (notiId) => {
    return async (dispatch) => {
      const data = await updateNotification(notiId);
      if (data) dispatch(dashboardActions.updateNotification(notiId));
    };
  };
  
  export const deleteNotificationData = (notiId) => {
    return async (dispatch) => {
      const data = await deleteNotification(notiId);
      if (data) dispatch(dashboardActions.deleteNotification(notiId));
    };
  };
  
  
  export const setLastStudySubscribed = (data) => (dispatch) => {
    console.log(data, "LASTSTUDYSELECTED")
      return dispatch(dashboardActions.setLastStudySubscribed(data));
  };
              
              
              