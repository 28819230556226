import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Doughnut } from "react-chartjs-2";
import { LineChart, LockedLineChart } from "../src/chart/line";
import AddDataPopUp from "./AddDataPopup";
import hierarchy from "./Styleguide/hierarchy.svg";
import { JoinStudyModal } from "./JoinStudyModal";
import usersgreenicon from "./Styleguide/users-green-icon.svg";
import SuggestStudyOptions from "./SuggestStudyOptions";
import toast from "react-hot-toast";
import mapPin from "./Styleguide/map-pin.svg";
// import fakeImage3 from "./Styleguide/unnpm iber_chart.png";
import peerlogo from "./Styleguide/peerlogo.svg";

import users from "./Styleguide/users.svg";
import { getRegionDescription, checkSubscription } from "./Utils/dashboard";
import { useSelector, useDispatch } from "react-redux";
import SubscribeToStudy from "./Dashboard/SubscribeToStudy";
import {
  getParticipantBreakdown,
  getUserSubscriptions,
  subscribeStudy,
  toggleQEffect,
  setLastStudySubscribed
} from "./store/dashboardActions";
import ProgressBarComponent from "./Dashboard/components/ProgressBarComponent";
import { Participant_breakdown } from "./Dashboard/Participant_breakdown";
import { CircularProgressbarComponent } from "./Dashboard/CircularProgressbarComponent";
import { getMonthName } from "./Utils/dashboard";
import BrowseCategory from "./BrowseCategory";
import Regions from "./Regions";
import "./MainCard.css";
import { Cntx } from "./context/context";
import RenderIf from "./renderif/RenderIf";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import plusSign from "./Styleguide/plus-symbol.svg";
import { Button } from "react-bootstrap";
import { dashboardActions } from "./store/dashboard";

function MainCard() {
  const [name, setName] = React.useState("");
  const [regionId, setRegionId] = React.useState("");
  const [about, setAbout] = React.useState("");
  const [studytypeId, setStudytypeId] = React.useState("");
  const [numofsubscribers, setnumofsubscribers] = React.useState("");
  const [categoryId, setCategoryId] = React.useState("");
  
  const contextuser = useContext(Cntx);
  // const [measure, setMeasure] = React.useState('')
  const addSugestedStudy = useSelector(
    (state) => state.dashboard.addSugestedStudy
  );
  const [measure1, setMeasure1] = React.useState("");
  const [measure2, setMeasure2] = React.useState("");
  const [measure3, setMeasure3] = React.useState("");

  const [producedBy, setProducedBy] = React.useState("");
  const activeStudy = useSelector((state) => state.dashboard.activeStudy);
  const queuedStudies = useSelector((state) => state.dashboard.queuedStudies);
  const availableStudies = useSelector((state) => state.dashboard.availableStudies);
  
  const userSubscriptions = useSelector(
    (state) => state.dashboard.userSubscriptions
  );
  
  const user = useSelector((state) => state.dashboard.userInfo);
  // const user =JSON.parse(localStorage.getItem('user'))
  const studyData = useSelector((state) => state.dashboard.studyData);

    function removeDuplicatesByDate(data) {
      const uniqueDates = new Set();
      const filteredData = [];

      for (const item of data) {
        const { Date } = item;
        if (!uniqueDates.has(Date)) {
          uniqueDates.add(Date);
          filteredData.push(item);
        }
      }

      return filteredData;
    }
    let filteredData = studyData;
    if (studyData) {
      filteredData = removeDuplicatesByDate(studyData);
    }

    const arrayMonths = filteredData
      ? filteredData.map((row) => new Date(row.Date).getMonth())
      : [];

  console.log(studyData,"AYY");
  // const  studyData=[
  //   {Date:"2021-01-31 12:16:55.523Z",Value:3.2,Average:5.20},
  //   {Date:"2021-02-2 12:16:55.523Z",Value:4.2,Average:4.11},
  //   {Date:"2021-03-5 12:16:55.523Z",Value:5.1,Average:4.56},
  //   {Date:"2021-04-11 12:16:55.523Z",Value:6.2,Average:4.88},
  //   {Date:"2021-05-16 12:16:55.523Z",Value:7,Average:5.36},
  //   {Date:"2021-06-19 12:16:55.523Z",Value:3,Average:4.12},
  //   {Date:"2021-07-20 12:16:55.523Z",Value:8.1,Average:5.53},
  //   {Date:"2021-08-31 12:16:55.523Z",Value:5,Average:4.48},
  //   {Date:"2021-09-21 12:16:55.523Z",Value:3.2,Average:4.63},
  //   {Date:"2021-10-28 12:16:55.523Z",Value:4,Average:4.83},
  //   {Date:"2021-11-19 12:16:55.523Z",Value:3.9,Average:4.96},
  //   {Date:"2021-12-31 12:16:55.523Z",Value:2,Average:3.64},
  //   ]

  // const bydef = JSON.parse(localStorage.getItem("clicks"));
  // const def = localStorage.getItem("fetchedLevel6");

  const participantData = useSelector(
    (state) => state.dashboard.participantBreakDown
  );
  //   const participantData= {
  //     breakdownResult:[{Min:true}],
  // result: [
  //    {Range:"Range1",ReachID:1,Count:0},
  //    {Range:"Range1",ReachID:2,Count:0},
  //    {Range:"Range1",ReachID:3,Count:0},
  //    {Range:"Range1",ReachID:4,Count:1},
  //    {Range:"Range2",ReachID:1,Count:2},
  //    {Range:"Range2",ReachID:2,Count:0},
  //    {Range:"Range2",ReachID:3,Count:0},
  //    {Range:"Range2",ReachID:4,Count:0},
  //    {Range:"Range3",ReachID:1,Count:1},
  //    {Range:"Range3",ReachID:2,Count:2},
  //    {Range:"Range3",ReachID:3,Count:1},
  //    {Range:"Range3",ReachID:4,Count:1},
  //    {Range:"Range4",ReachID:1,Count:2},
  //    {Range:"Range4",ReachID:2,Count:1},
  //    {Range:"Range4",ReachID:3,Count:3},
  //    {Range:"Range4",ReachID:4,Count:2},
  // ] }

  let isSubscriber = checkSubscription(activeStudy, userSubscriptions);
  const showExampleStudystate = useSelector(
    (state) => state.dashboard.showExampleStudy
  );
  if (showExampleStudystate == true) {
    console.log("eeeeeexxxxxxx", isSubscriber);
    isSubscriber = true;
  }
  const [joinModalShow, setJoinModalShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const regions = useSelector((state) => state.dashboard.regions);
  const [regionModalShow, setRegionModalShow] = React.useState(false);
  const [sugStudy, setSugStudy] = React.useState();
  const [subStudy, setSubStudy] = React.useState();
  const [userid, setUserid] = React.useState();
  const [studyid, setStudyid] = React.useState();
  const [subscriptiontype, setSubscriptiontype] = React.useState();

  
  let getPeerGroup6State = localStorage.getItem("peerGroupName")

  let OGRegion = localStorage.getItem("regionName") ?? "" ;
  let getRegionName = OGRegion;
  

  const dispatch = useDispatch();
  const onSubscribeStudy = () => {
    // dispatch(subscribeStudy(activeStudy.ID));
    dispatch(getParticipantBreakdown(activeStudy.ID));
    const user = JSON.parse(localStorage.getItem("user"));
    const payload1 = {
      userid: user.id,
      studyid: activeStudy.ID,
      subscriptiontype: 2,
    };
    console.log("payload1", payload1);
    const token = localStorage.getItem("token");
    const url =
      "https://frontend.mypeerformance.com:8080/api/subscriptions/create";
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },

        body: JSON.stringify(payload1),
      })
        .then((response) => response.json())
        .then((subStudy) => {
          
          console.log("resSub", subStudy);
          setSubStudy(subStudy.message);
          toast.success("Study Subscribed")
          dispatch(setLastStudySubscribed(subStudy.data.studyId))
          dispatch(getUserSubscriptions());
          
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (err) {
      return console.log("error", err);
    }
  };
  




  const handleaddSugestedstudy = (addSugestedStudy) => {
    console.log("addSugestedStudy", addSugestedStudy);
    // return async dispatch => {
    dispatch(dashboardActions.setAddSugestedStudy(!addSugestedStudy));
    // }
  };
  const suggestStudy = async () => {
    let studytypeId;
    let measure;
    if (measure1) {
      studytypeId = 1;
      measure = measure1;
    } else if (measure2) {
      studytypeId = 2;
      // setMeasure(measure2)
      measure = measure2;
    } else if (measure3) {
      studytypeId = 3;
      // setMeasure(measure3)
      measure = measure3;
    }

    const payload = {
      name,
      regionId: localStorage.getItem("regionId"),
      categoryId: localStorage.getItem("peerGroupId"),
      studytypeId: studytypeId,
      about: about,
      measure,
      producedBy,
    };
    console.log("payload123", payload);
    console.log("payload", about);

    const token = localStorage.getItem("token");
    const url =
      "https://frontend.mypeerformance.com:8080/api/suggeststudy/create";
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },

        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((sugStudy) => {
          toast.success("Study suggestion has sent to admin to be approved.");
          console.log("res", sugStudy);
          // window.location.reload()
          setSugStudy(sugStudy.message);
          setName("")
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (err) {
      return console.log("error", err);
    }
  };

  const getChartTypeTitle = (typeId) => {
    switch (typeId) {
      case 1:
        return "Monthly Percentage Change";
      case 2:
        return "Monthly Producer Average";
      case 3:
        return "Absolute Monthly Change";
      default:
        return null;
    }
  };

  const getSum = (total, num) => {
    return total + (num.Value / 100) * total;
  };

  const getSumAverage = (total, num) => {
    return total + (num.Average / 100) * total;
  };

  const countCircleResult = () => {
    if (activeStudy?.TypeID === 3 || activeStudy?.TypeID === 2) {
      const firstValue =
        studyData && studyData?.length ? studyData[0]?.Value : 0;
      const mostRecentValue =
        studyData && studyData?.length
          ? studyData[studyData?.length - 1]?.Value
          : 0;
      if (firstValue && mostRecentValue) {
        let calculateValue = (mostRecentValue / firstValue) * 100 - 100;
        return parseFloat(calculateValue?.toFixed(2));
      }
      return 0;
    } else if (activeStudy?.TypeID === 1) {
      let sum = studyData?.reduce(getSum, 100);
      let toString = JSON.stringify(studyData);

      if (
        toString === "undefined" ||
        toString === undefined ||
        studyData?.length === 0
      ) {
        sum = 0;
      }
      return parseFloat(sum?.toFixed(2));
    }

    if (activeStudy?.TypeID === 1 && studyData.length === 0) {
      return 0;
    }
    return 0;
  };

  const countCircleAverageResult = () => {
    if (activeStudy?.TypeID === 3 || activeStudy?.TypeID === 2) {
      const firstValue =
        studyData && studyData?.length ? studyData[0]?.Average : 0;
      const mostRecentValue =
        studyData && studyData?.length
          ? studyData[studyData?.length - 1]?.Average
          : 0;
      if (firstValue && mostRecentValue) {
        let calculateValue = (mostRecentValue / firstValue) * 100 - 100;
        return parseFloat(calculateValue?.toFixed(2));
      }
      return 0;
    } else if (activeStudy?.TypeID === 1) {
      let sum = studyData?.reduce(getSumAverage, 100);

      let toString = JSON.stringify(studyData);

      if (
        toString === "undefined" ||
        toString === undefined ||
        studyData?.length === 0
      ) {
        sum = 0;
      }

      return parseFloat(sum?.toFixed(2));
    }
    return 0;
  };

  const getCircularProgressBarAverageValue = () => {
    if (circleResult > 100 || circleAverageResult > 100) {
      if (circleResult > circleAverageResult) {
        return (circleAverageResult / circleResult) * 100;
      } else {
        return 100;
      }
    }

    return Math.abs(circleAverageResult);
  };

  const getCircularProgressBarValue = () => {
    if (circleResult > 100 || circleAverageResult > 100) {
      if (circleResult > circleAverageResult) {
        return 100;
      } else {
        return (circleResult / circleAverageResult) * 100;
      }
    }
    return Math.abs(circleResult);
  };

  const getUserLastMonthValue = () => {
    const typeId = activeStudy.TypeID;
    const lastStudyDataResult = studyData && studyData[studyData?.length - 1];
    const value = lastStudyDataResult?.Value || 0;
    const average = lastStudyDataResult?.Average || 0;

    if (value && average) {
      if (typeId === 1) {
        const absoluteValue = Math.abs(value);
        const absoluteAverage = Math.abs(average);
        if (absoluteValue > 100 || absoluteAverage > 100) {
          const scale = Math.max(absoluteValue, absoluteAverage);
          return (value / scale) * 100;
        } else {
          return value;
        }
      } else {
        const scale = Math.max(value, average);
        return (value / scale) * 100;
      }
    } else {
      return 0;
    }
  };

  const getAverageLastMonthValue = () => {
    const typeId = activeStudy.TypeID;
    const lastStudyDataResult = studyData && studyData[studyData?.length - 1];
    const value = lastStudyDataResult?.Value || 0;
    const average = lastStudyDataResult?.Average || 0;

    if (value && average) {
      if (typeId === 1) {
        const absoluteValue = Math.abs(value);
        const absoluteAverage = Math.abs(average);
        if (absoluteValue > 100 || absoluteAverage > 100) {
          const scale = Math.max(absoluteValue, absoluteAverage);
          return (average / scale) * 100;
        } else {
          return average;
        }
      } else {
        const scale = Math.max(value, average);
        return (average / scale) * 100;
      }
    } else {
      return 0;
    }
  };

  const circleAverageResult = Math.round(countCircleAverageResult());
  const circleResult = Math.round(countCircleResult());

  let regionName = localStorage.getItem("regionName");
  // if (regionName !== null && document.getElementById("regionTxt")) {
  //   document.getElementById("regionTxt").innerHTML = regionName;
  // }
  const regionClicked = () => {
    setRegionModalShow(true);
  };

  // This function will display the month on th chart
  let displayMonth;
  const displayMonth1 = () => {
    let date = new Date().getDate();
    if (date > 5) {
      displayMonth = getMonthName(new Date().getMonth() - 1);
    } else {
      displayMonth = getMonthName(new Date().getMonth() - 2);
    }
  };
  displayMonth1();
  let studySubs = useSelector(
    (state) => state.dashboard
  );
   let studySubsNum = studySubs.allStudies.find(({ ID }) => ID === activeStudy?.ID) 
  
  let getParentCategory = localStorage.getItem("parentCategory");

  return activeStudy ? (
    <div
      className="col-sm"
      style={{
        color: "white",
        width: "930px",
      }}
    >
      <Regions
        show={regionModalShow}
        onHide={() => setRegionModalShow(false)}
        value={regionId}
        onChange={(e) => setRegionId(e.target.value)}
      />
      <div style={{ marginBottom: "0px" }} className="row col-sm-12">
        <div className="col-sm-7">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="left-icon-info">
              <div className="left-icon-img">
                <img
                  style={{ marginRight: "3px", paddingBottom: "5px" }}
                  src={hierarchy}
                  alt=""
                  onClick={() => setModalShow(true)}
                />
              </div>
              <div className="left-icon-heading position-relative pr-42px">
                <RenderIf isTrue={contextuser.val == true}>
                  {null}
                  {/* <div className="data-tooltip-info">
                    <span data-title="Each peer groups sits within a hierarchy of groups which can be added to by the user.  The right hand of the comma is the peer group, in this instance “Corporate Accounting” and the left of the comma is the parent group it sits in “Accountancy services”">
                      <img
                        src="https://img.icons8.com/metro/26/000000/info.png"
                        class="info-icon"
                        alt=""
                      />
                    </span>
                  </div> */}
                </RenderIf>
                <span onClick={() => setModalShow(true)} id="peerGroupText">
                  {`${activeStudy?.ParentCategory}, ${activeStudy?.Category}`}{" "}
                </span>

                <BrowseCategory
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
            {/* <div style={{ marginLeft: "15px", marginRight: "15px" }}>-</div> */}
            <div className="left-icon-info position-relative">
              <div className="left-icon-img">
                <img
                  style={{ marginRight: "3px", paddingBottom: "5px" }}
                  src={mapPin}
                  onClick={regionClicked}
                  alt=""
                />
              </div>
              <div className="left-icon-heading ">
                <span onClick={regionClicked} id="regionName">
                  {" "}
                  {getRegionDescription(activeStudy, regions)}
                </span>
              </div>
              <RenderIf isTrue={contextuser.val == true}>
                {null}
                {/* <div className="data-tooltip-info" style={{ left: "85px" }}>
                  <span data-title="This is the region of the peer group which defines the area being studied.  In this instance, the peer group is for Corporate Accountants doing business in the UK only">
                    <img
                      src="https://img.icons8.com/metro/26/000000/info.png"
                      class="info-icon"
                      alt=""
                    />
                  </span>
                </div> */}
              </RenderIf>
            </div>
          </div>

          {/* new study added ------ */}
          <div
            className={`row mb-20 mt-5px ${
              addSugestedStudy && activeStudy.Name ? "displayNone" : ""
            } `}
          >
            <div className="col-sm-12">
              <div className="top-right-icon-h2" style={{ display: "flex" }}>
                {console.log(activeStudy.Name, "LLLLLLLLLLLLLLLLL")}
                {activeStudy?.Name ? (
                  <div>
                    <h2 className="h2-heading-sm">{activeStudy.Name}</h2>
                  </div>
                ) : (
                  <div>
                    <h2 className="h2-heading-sm">No studies yet</h2>
                  </div>
                )}
                <div className="left-icon-info">
                  <div className="left-icon-img">
                    <img
                      style={{ marginRight: "3px" }}
                      src={usersgreenicon}
                      alt=""
                    />
                  </div>
                  <div className="left-icon-heading">
                    {/* <span>{activeStudy.NumOfSubscribers}</span> */}
                    <span>
                      {activeStudy.NumOfSubscribers
                        ? activeStudy.NumOfSubscribers
                        : 0}{" "}
                    </span>
                    {/* <span>23</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`btn-new-search-info ${
              !addSugestedStudy ? "displayNone" : ""
            } `}
          >
            <button type="button" class="btn btn-primary">
              NEW
            </button>
            <input
              id="addStudy"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Type study name…"
            />
          </div>
          {/* 
                    <div className="row">
                        <h3 style={{ color: "#FFFFFF", fontSize: '30px' }}>
                            {activeStudy?.Name}
                            <img src={users} style={{ paddingLeft: "20px" }} alt="" />
                            <small style={{
                                fontSize: "14px",
                                color: '#8F96B4'
                            }}> {activeStudy?.NumOfSubscribers}</small>
                        </h3>
                    </div> */}
        </div>
        <div className="col-sm-5">
          {addSugestedStudy ? null : (
            <>
              {isSubscriber ? (
                <AddDataPopUp
                  type={activeStudy.TypeID}
                  className="input-group-sm float-end"
                />
              ) : (
                <div className="input-group-sm mb-3 float-end">
                  <RenderIf
                    isTrue={
                      queuedStudies.length <= 0 && availableStudies.length <= 0
                    }
                  >
                    <Button
                      variant=""
                      onClick={() => {
                        handleaddSugestedstudy(addSugestedStudy);
                      }}
                      style={{
                        backgroundColor: "#38d881",
                        paddingTop: "8%",
                        paddingBottom: "8%",
                        border: "2px solid #38d881",
                        borderRadius: "10px",
                      }}
                    >
                      <small
                        style={{
                          fontSize: "16px",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                      >
                        {" "}
                        <img
                          src={plusSign}
                          alt=""
                          style={{ marginTop: "-4px", marginRight: "5px" }}
                        />
                        Suggest study
                      </small>
                    </Button>
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      !queuedStudies.length <= 0 ||
                      !availableStudies.length <= 0
                    }
                  >
                    <Button
                      variant=""
                      onClick={() => setJoinModalShow(true)}
                      style={{
                        backgroundColor: "#38d881",
                        paddingTop: "8%",
                        paddingBottom: "8%",
                        border: "2px solid #38d881",
                        borderRadius: "10px",
                      }}
                    >
                      <small
                        style={{
                          fontSize: "16px",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                        }}
                      >
                        {" "}
                        <img
                          src={plusSign}
                          alt=""
                          style={{ marginTop: "-4px", marginRight: "5px" }}
                        />
                        Join study
                      </small>
                    </Button>

                    <JoinStudyModal
                      show={joinModalShow}
                      onHide={() => setJoinModalShow(false)}
                      onSubscribeStudy={() => onSubscribeStudy()}
                    />
                  </RenderIf>
                </div>
              )}
            </>
          )}
        </div>
        <div style={{ padding: "4px" }}></div>
      </div>

      <div>
        <div className="row col-sm-12">
          <div className="col-sm-7 main-chart" style={{ minWidth: "500px" }}>
            <div
              className="card"
              style={{
                color: "#A5ACCB",
                backgroundColor: "#1f2748",
                borderTopRightRadius: "15px",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <div className="card-body">
                {getChartTypeTitle(activeStudy.TypeID)}

                <div className="row">
                  <div className="col-sm-12">
                    <RenderIf isTrue={contextuser.val == true}>
                      {null}
                      {/* <div className="data-tooltip-info">
                        <span data-title="This is the chart showing the monthly results for the active study above.  The green line is the users data and the blue is the average for the peer group">
                          <img
                            src="https://img.icons8.com/metro/26/000000/info.png"
                            class="info-icon"
                            alt=""
                          />
                        </span>
                      </div> */}
                    </RenderIf>
                    <b>
                      <h5 style={{ color: "white" }}> {displayMonth}</h5>
                    </b>
                  </div>
                </div>

                {/*{isSubscriber ? <LineChart data={studyData} name={user?.Company?.name}/> :*/}
                {/*    <LockedLineChart/>}*/}

                <LineChart
                  data={studyData}
                  name={user?.Company}
                  subscription={true}
                />
              </div>
            </div>
          </div>
          {activeStudy ? (
            <>
              <div className="container col-sm">
                <div className="about-section">
                  <h6 style={{ color: "#A5ACCB" }}> ABOUT</h6>

                  {/* <RenderIf isTrue={activeStudy && queuedStudies.length <= 0}> */}
                  {addSugestedStudy ? (
                    <textarea
                      style={{
                        overflowY: "auto",
                        height: "6.5rem",
                        marginTop: "1rem",
                        width: "100%",
                        background: "none",
                        border: "none",
                        color: "rgb(165, 172, 203)",
                        resize: "none",
                      }}
                      onChange={(e) => {
                        console.log(about, "ABOUT");
                        setAbout(e.target.value);
                      }}
                      // rows={4}
                      // cols={50}
                      placeholder="Write a small description of your suggested study here..."
                    />
                  ) : (
                    <p
                      style={{
                        color: "#A5ACCB",
                        overflowY: "auto",
                        height: "6.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      {activeStudy?.About}
                    </p>
                  )}
                  {/* </RenderIf> */}
                </div>
                <div style={{ paddingTop: "2%" }}></div>
                <div className="progress-bar-section">
                  <small style={{ color: "#A5ACCB" }}>{user?.Company}</small>
                  <RenderIf isTrue={contextuser.val == true}>
                    {null}
                    {/* <div className="data-tooltip-info">
                      <span
                        className="test"
                        data-title="This is the User average for the most recent month, in this instance, the User average has seen a 9.97% uptick on their previous month.  The horizontal bar is full because it has outperformed the peer group average"
                      >
                        <img
                          src="https://img.icons8.com/metro/26/000000/info.png"
                          class="info-icon"
                          alt=""
                        />
                      </span>
                    </div> */}
                  </RenderIf>
                    
                  <p style={{ marginBottom: "3px" }}>
                    {`£${
                      studyData && studyData?.length
                        ? activeStudy?.TypeID === 1
                          ? studyData[studyData?.length - 1]?.Value
                            ? Math.round(studyData[studyData?.length - 1].Value)
                            : 0
                          : studyData[studyData?.length - 1]?.Value
                          ? Math.round(studyData[studyData?.length - 1].Value)
                          : 0
                        : 0
                    }
                        ${activeStudy?.TypeID === 1 ? "%" : ""}`}
                  </p>
                  <ProgressBarComponent
                    id="user-progres-bar"
                    value={getUserLastMonthValue()}
                  />

                  <div style={{ paddingTop: "5%" }}></div>
                  <small style={{ color: "#A5ACCB" }}>Peer Group Average</small>
                  <RenderIf isTrue={contextuser.val == true}>
                    {null}
                    {/* <div className="data-tooltip-info">
                      <span
                        className="test1"
                        data-title="This is the peer group average for the most recent month, in this instance, the peer group average has seen a 3.16% uptick on their previous month.  The horizontal bar shows how far behind the average was from the Users result"
                      >
                        <img
                          src="https://img.icons8.com/metro/26/000000/info.png"
                          class="info-icon"
                          alt=""
                        />
                      </span>
                    </div> */}
                  </RenderIf>
                  {/* <p color={{ color: "white" }}>{`-20%`}</p> */}
                  <p
                    style={{
                      marginBottom: "3px",
                      verticalAlign: "bottom",
                    }}
                  >
                    {`£${
                      studyData && studyData?.length
                        ? activeStudy?.TypeID === 1
                          ? studyData[studyData?.length - 1]?.Average
                            ? Math.round(
                                studyData[studyData?.length - 1].Average
                              )
                            : 0
                          : studyData[studyData?.length - 1]?.Average
                          ? Math.round(studyData[studyData?.length - 1].Average)
                          : 0
                        : 0
                    }
                      ${activeStudy?.TypeID === 1 ? "%" : ""}`}
                  </p>
                  <ProgressBarComponent
                    id="industry-progres-bar"
                    value={getAverageLastMonthValue()}
                  />
                </div>
              </div>

              {/* subscrie to study----------------------------------------------------- */}
            </>
          ) : (
            // <SubscribeToStudy
            //   studyAbout={activeStudy?.About}
            //   onSubscribe={onSubscribeStudy}
            // />
            <div className="container col-sm">
              <div className="about-section">
                <h6 style={{ color: "#A5ACCB" }}> ABOUT</h6>

                <RenderIf isTrue={queuedStudies.length <= 0}>
                  <p
                    style={{
                      color: "#A5ACCB",
                      overflowY: "auto",
                      height: "6.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    Congratulations - you are the first of your peers to join
                    this group. Customize your first study using one of the
                    study types below and fill out the required details.
                  </p>
                </RenderIf>
              </div>
              <div style={{ paddingTop: "2%" }}></div>
              <div className="progress-bar-section">
                <small style={{ color: "#A5ACCB" }}>PeerFormance</small>
                <RenderIf isTrue={contextuser.val == true}>
                  {null}
                  {/* <div className="data-tooltip-info">
                    <span data-title="This is the User average for the most recent month, in this instance, the User average has seen a 9.97% uptick on their previous month.  The horizontal bar is full because it has outperformed the peer group average">
                      <img
                        src="https://img.icons8.com/metro/26/000000/info.png"
                        class="info-icon"
                        alt=""
                      />
                    </span>
                  </div> */}
                </RenderIf>
                <p style={{ marginBottom: "3px" }}>{`${0}
                                
                                 
                                    
                                    ${"%"}`}</p>
                <ProgressBarComponent id="user-progres-bar" value={0} />

                <div style={{ paddingTop: "5%" }}></div>
                <small style={{ color: "#A5ACCB" }}>Peer Group Average</small>
                <RenderIf isTrue={contextuser.val == true}>
                  {null}
                  {/* <div className="data-tooltip-info">
                    <span data-title="This is the peer group average for the most recent month, in this instance, the peer group average has seen a 3.16% uptick on their previous month.  The horizontal bar shows how far behind the average was from the Users result">
                      <img
                        src="https://img.icons8.com/metro/26/000000/info.png"
                        class="info-icon"
                        alt=""
                      />
                    </span>
                  </div> */}
                </RenderIf>
                {/* <p color={{ color: "white" }}>{`-20%`}</p> */}
                <p
                  style={{
                    marginBottom: "3px",
                    verticalAlign: "bottom",
                  }}
                >{`${0}
                                    ${"%"}`}</p>
                <ProgressBarComponent id="industry-progres-bar" value={0} />
              </div>
            </div>
          )}
        </div>
        {!addSugestedStudy ? null : (
          <SuggestStudyOptions
            suggestStudy={suggestStudy}
            measure1={measure1}
            measure2={measure2}
            measure3={measure3}
            setMeasure1={setMeasure1}
            setMeasure2={setMeasure2}
            setMeasure3={setMeasure3}
            producedBy={producedBy}
            setProducedBy={setProducedBy}
            about={about}
            name={name}
          />
        )}

        <div
          className={`row col-sm-12 ${
            !addSugestedStudy ? "activeaddSugestedStudy4" : ""
          } `}
        >
          {/*{isSubscriber ?*/}
          {/*    <Participant_breakdown data={participantData}/> : null}*/}

          <Participant_breakdown data={participantData} unlock={true} />

          {/* Circle 1*/}
          <CircularProgressbarComponent
            value={getCircularProgressBarValue()}
            text={circleResult}
            counterClockwise={circleResult < 0 ? true : false}
            months={arrayMonths?.length}
            pathColor={"rgba(0, 226, 125)"}
            trailColor={circleResult < 0 ? "#553b65" : "#353b65"}
            title="Change from start of this period"
            unlock={true}
            donutChartId="donutChart1"
            customText={user?.Company}
          />
          {/* Circle 2*/}
          <CircularProgressbarComponent
            value={getCircularProgressBarAverageValue()}
            text={circleAverageResult}
            counterClockwise={circleAverageResult < 0 ? true : false}
            months={arrayMonths?.length}
            pathColor={"rgba(101,133,255)"}
            trailColor={circleAverageResult < 0 ? "#553b65" : "#353b65"}
            title="Change from start of this period"
            unlock={true}
            donutChartId="donutChart2"
            customText="Peer Group"
          />
        </div>
      </div>
    </div>
  ) : (
    /* If the study is not activated*/
    // : <div className="col-sm" style={{ color: 'white', }}>
    //     <div style={{ marginBottom: '0px' }} className="row col-sm-12">
    //         <div className="col-sm-8" style={{ marginTop: "-10px" }}>
    //             <div style={{ display: 'flex', fontSize: '18px', color: '#8F96B4' }}>
    //                 <div style={{ display: 'flex' }}>
    //                     <img src={hierarchy} alt=""
    //                         onClick={() => setModalShow(true)}
    //                     />
    //                 </div>
    //                 <div>
    //                     <span id="peerGroupTitle" style={{ marginLeft: '3px' }}
    //                         onClick={() => setModalShow(true)}>
    //                         {/* <span id="level5Text"> {getPeerGroup4or5State}, </span> */}
    //                         <span id="level6Text">{getPeerGroup6State}</span>

    //                     </span>
    //                     <BrowseCategory show={modalShow} onHide={() => setModalShow(false)}
    //                     />
    //                 </div>

    //                 <div style={{ marginLeft: '15px', marginRight: '15px' }}>-</div>
    //                 <div style={{ display: 'flex' }}>
    //                     <img style={{ marginRight: '3px' }}
    //                         src={mapPin}
    //                         onClick={() => setRegionModalShow(true)} alt="" />

    //                     <div id="regionTxt" onClick={() => setRegionModalShow(true)}>
    //                         {getRegionName}
    //                     </div>
    //                     <Regions show={regionModalShow} onHide={() => setRegionModalShow(false)} />
    //                 </div>
    //             </div>

    //             <div style={{
    //                 display: 'flex', fontSize: '18px',
    //                 color: '#8F96B4', marginLeft: "-23.52px",
    //                 width: "1230px",
    //             }}>

    //                 {/* Blue line*/}
    //                 <div style={{ width: "20px" }}>
    //                     <div style={{
    //                         borderLeft: "2px solid #313B65",
    //                         marginTop: "740px",
    //                         marginLeft: "-1px",
    //                         height: "350px",
    //                     }} />
    //                 </div>
    //                 {/* Blue line*/}

    //                 {/* Fake image */}
    //                 <img
    //                     src={fakeImage3}
    //                     style={{ marginLeft: "10px", width: "1230px" }}
    //                     alt="" />
    //                 {/* Fake image */}
    //             </div>
    //         </div>
    //     </div>
    // </div>
    <div
      className="col-sm"
      style={{
        color: "white",
      }}
    >
      <Regions
        show={regionModalShow}
        onHide={() => setRegionModalShow(false)}
        value={regionId}
        onChange={(e) => setRegionId(e.target.value)}
      />
      <div style={{ marginBottom: "0px" }} className="row col-sm-12">
        <div className="col-sm-7">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="left-icon-info">
              <div className="left-icon-img">
                <img
                  style={{ marginRight: "3px", paddingBottom: "5px" }}
                  src={hierarchy}
                  alt=""
                  onClick={() => setModalShow(true)}
                />
              </div>
              <div className="left-icon-heading pr-42px">
                <span
                  onClick={() => setModalShow(true)}
                  id="peerGroupText"
                  className="peerGroupText"
                >
                  &nbsp;
                  {getParentCategory
                    ? getParentCategory
                    : activeStudy?.ParentCategory}
                  ,<span> </span>
                </span>

                <span
                  onClick={() => setModalShow(true)}
                  id="peerGroupText"
                  className="peerGroupText"
                >
                  &nbsp;
                  {getPeerGroup6State}
                </span>

                <BrowseCategory
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
              <RenderIf isTrue={contextuser.val == true}>
                {null}
                {/* <div className="data-tooltip-info">
                  <span data-title="This is the region of the peer group which defines the area being studied.  In this instance, the peer group is for Corporate Accountants doing business in the UK only">
                    <img
                      src="https://img.icons8.com/metro/26/000000/info.png"
                      class="info-icon"
                      alt=""
                    />
                  </span>
                </div> */}
              </RenderIf>
            </div>
            {/* <div style={{ marginLeft: "15px", marginRight: "15px" }}>-</div> */}
            <div className="left-icon-info">
              <div className="left-icon-img">
                <img
                  style={{ marginRight: "3px", paddingBottom: "5px" }}
                  src={mapPin}
                  onClick={regionClicked}
                  alt=""
                />
              </div>
              <div className="left-icon-heading ">
                <span onClick={regionClicked} id="regionName">
                  {getRegionName}
                </span>
              </div>
              <RenderIf isTrue={contextuser.val == true}>
                {null}
                {/* <div className="data-tooltip-info">
                  <span data-title="This is the region of the peer group which defines the area being studied.  In this instance, the peer group is for Corporate Accountants doing business in the UK only">
                    <img
                      src="https://img.icons8.com/metro/26/000000/info.png"
                      class="info-icon"
                      alt=""
                    />
                  </span>
                </div> */}
              </RenderIf>
            </div>
          </div>

          {/* new study added ------*/}
          <div
            className={`btn-new-search-info ${
              !addSugestedStudy ? "displayNone" : ""
            } `}
          >
            <button type="button" class="btn btn-primary">
              NEW
            </button>
            <input
              id="addStudy"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Type study name…"
            />
          </div>

          {!addSugestedStudy && (
            <div className="row mt-3">
              <h4 style={{ color: "#FFFFFF", fontSize: "30px" }}>
                No studies yet
              </h4>
            </div>
          )}
        </div>
        <div className="col-sm-5">
          <div className="input-group-sm mb-3  float-end">
            {!addSugestedStudy ? (
              <Button
                variant=""
                onClick={() => handleaddSugestedstudy(addSugestedStudy)}
                style={{
                  backgroundColor: "#38d881",
                  paddingTop: "8%",
                  paddingBottom: "8%",
                  border: "2px solid #38d881",
                  borderRadius: "10px",
                }}
              >
                <small
                  style={{
                    fontSize: "16px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  {" "}
                  <img
                    src={plusSign}
                    alt=""
                    style={{ marginTop: "-4px", marginRight: "5px" }}
                  />
                  Suggest study
                </small>
              </Button>
            ) : null}
          </div>
        </div>
        <div style={{ padding: "4px" }}></div>
      </div>

      <div class={` ${!addSugestedStudy ? "" : ""}`}>
        <div className="row col-sm-12 mb-33">
          <div className="col-sm-7">
            <div
              className="card"
              style={{
                color: "#A5ACCB",
                backgroundColor: "#1f2748",
                borderTopRightRadius: "15px",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                padding: "20px",
              }}
            >
              <div className="row">
                <div className="col-sm-12">
                  <b>
                    <h5 style={{ color: "white" }}> {displayMonth}</h5>
                  </b>
                </div>
              </div>
              {/* <img src={fakeImage3} alt=""/> */}
              <LineChart
                data={studyData}
                name={user?.Company}
                subscription={isSubscriber}
              />
            </div>
          </div>
          {/* <div className="container col-sm">
            <div>
              <div>
                <img src={peerlogo} alt="" />
              </div>
              <br />
              <div>
                <p>
                  Congratulations - you are the first of your peers to join this
                  group. Customize your first study using one of the study types
                  below and fill out the required details.
                </p>
              </div>
              <br />

              <div>
                <p>You won’t be alone for long!</p>
              </div>
            </div>
          </div> */}
          <div className="container col-sm">
            <div className="about-section">
              <h6 style={{ color: "#A5ACCB" }}> ABOUT</h6>
              {addSugestedStudy ? (
                <textarea
                  style={{
                    overflowY: "auto",
                    height: "6.5rem",
                    marginTop: "1rem",
                    width: "100%",
                    background: "none",
                    border: "none",
                    color: "rgb(165, 172, 203)",
                    resize: "none",
                  }}
                  onChange={(e) => {
                    console.log(about, "ABOUT");
                    setAbout(e.target.value);
                  }}
                  // rows={4}
                  // cols={50}
                  placeholder="Write a small description of your suggested study here..."
                />
              ) : (
                <p
                  style={{
                    color: "#A5ACCB",
                    overflowY: "auto",
                    height: "6.5rem",
                    marginTop: "1rem",
                  }}
                >
                  Congratulations - you are the first of your peers to join this
                  group. Customize your first study using one of the study types
                  below and fill out the required details.
                </p>
              )}
            </div>
            <div style={{ paddingTop: "2%" }}></div>
            <div className="progress-bar-section">
              <small style={{ color: "#A5ACCB" }}>PeerFormance</small>
              <RenderIf isTrue={contextuser.val == true}>
                {null}
                {/* <div className="data-tooltip-info">
                  <span data-title="This is the User average for the most recent month, in this instance, the User average has seen a 9.97% uptick on their previous month.  The horizontal bar is full because it has outperformed the peer group average">
                    <img
                      src="https://img.icons8.com/metro/26/000000/info.png"
                      class="info-icon"
                      alt=""
                    />
                  </span>
                </div> */}
              </RenderIf>
              <p style={{ marginBottom: "3px" }}>{`${0}         
                                    ${"%"}`}</p>
              <ProgressBarComponent id="user-progres-bar" value={0} />

              <div style={{ paddingTop: "5%" }}></div>
              <small style={{ color: "#A5ACCB" }}>Peer Group Average</small>
              <RenderIf isTrue={contextuser.val == true}>
                {null}
                {/* <div className="data-tooltip-info">
                  <span data-title="This is the peer group average for the most recent month, in this instance, the peer group average has seen a 3.16% uptick on their previous month.  The horizontal bar shows how far behind the average was from the Users result">
                    <img
                      src="https://img.icons8.com/metro/26/000000/info.png"
                      class="info-icon"
                      alt=""
                    />
                  </span>
                </div> */}
              </RenderIf>
              {/* <p color={{ color: "white" }}>{`-20%`}</p> */}
              <p
                style={{
                  marginBottom: "3px",
                  verticalAlign: "bottom",
                }}
              >{`${0}
                                    ${"%"}`}</p>
              <ProgressBarComponent id="industry-progres-bar" value={0} />
            </div>
          </div>

          {/* subscrie to study----------------------------------------------------- */}
        </div>

        {!addSugestedStudy ? null : (
          <SuggestStudyOptions
            suggestStudy={suggestStudy}
            measure1={measure1}
            measure2={measure2}
            measure3={measure3}
            setMeasure1={setMeasure1}
            setMeasure2={setMeasure2}
            setMeasure3={setMeasure3}
            producedBy={producedBy}
            setProducedBy={setProducedBy}
            about={about}
            name={name}
          />
        )}

        <div
          className={`row col-sm-12 ${
            !addSugestedStudy ? "activeaddSugestedStudy" : ""
          } `}
        >
          {/*{isSubscriber ?*/}
          {/*    <Participant_breakdown data={participantData}/> : null}*/}

          <Participant_breakdown data={participantData} unlock={isSubscriber} />

          {/* Circle 1*/}
          <CircularProgressbarComponent
            value={getCircularProgressBarValue()}
            text={circleResult}
            counterClockwise={circleResult < 0 ? true : false}
            months={arrayMonths?.length}
            pathColor={"rgba(0, 226, 125)"}
            trailColor={circleResult < 0 ? "#553b65" : "#353b65"}
            title="Change from start of this period"
            unlock={true}
            donutChartId="donutChart1"
            customText={user?.Company}
          />
          {/* Circle 2*/}
          <CircularProgressbarComponent
            value={getCircularProgressBarAverageValue()}
            text={circleAverageResult}
            counterClockwise={circleAverageResult < 0 ? true : false}
            months={arrayMonths?.length}
            pathColor={"rgba(101,133,255)"}
            trailColor={circleAverageResult < 0 ? "#553b65" : "#353b65"}
            title="Change from start of this period"
            unlock={true}
            donutChartId="donutChart2"
            customText="Peer Group"
          />
        </div>
      </div>
    </div>
  );
}

export default MainCard;

/*

* */
