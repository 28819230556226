import axios from "axios";

export const Categories = async () => {
  try {
    const body = {
      pageNo: "1",
      pageSize: "20000",
    };

    const response = await axios.post(
      "https://frontend.mypeerformance.com:8080/api/categories/getAll",
      body,
      {
        headers: {
          // 'x-access-token': localStorage.getItem('token')
          "x-access-token":
            localStorage.getItem("token1") || localStorage.getItem("token"),
        },
      }
    );

    console.log("This is what we received all category", response);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
export const filtterCategories = async (data) => {
  try {
    const body = {
      category: data,
    };
    console.log("body", body);
    const response = await axios.post("/categories/search", body, {
      headers: {
        // 'x-access-token': localStorage.getItem('token')
        "x-access-token":
          localStorage.getItem("token1") || localStorage.getItem("token"),
      },
    });

    console.log("Filtered category", response);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchRegionChild = async (parent) => {
  try {
    // const response = await axios.get(`/regions/${parent}/datapoint`,
    const response = await axios.get(
      `regions/getRegionChildren`,
      // const response = await axios.get(`/regions/2/datapoint`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    console.log("This is the result 2:", response);

    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

// Get the first three regions - All of UK, Europe, Global,
export const fetchTop3Level = async () => {
  try {
    const response = await axios.get("/regions/getTop3Regions", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });

    // set the top 3 levels

    window.localStorage.setItem(
      "top3Regions",
      JSON.stringify(response.data.data)
    );
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchUserInfo = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    // const response = await axios.get('/users/info',
    const response = await axios.get(`/users/byUserId/${user.id}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
        "x-access-token": token,
      },
    });
    // we are setting a variable that the user has logged in
    localStorage.setItem("userIsLoggedIn", "true");
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

// Get region by id - studyID, regionId
export const fetchStudyByRegion = async (peerGroupId, regionId) => {
  console.log({ peerGroupId, regionId }, "lll");
  try {
    //let response = await axios.get(`/studies/16775/1`,
    let response = await axios.get(
      `/studies/getByRegion/${peerGroupId}/${regionId}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    console.log("here--------------------", response);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

export const fetchStudies = async (peerGroupId) => {
  try {
    const response = await axios.get(`/studies/getByPeergroup/${peerGroupId}`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });

    console.log("This is the peer group id :", peerGroupId);
    console.log("This is the study:", response);
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};

//
export const fetchUserSubscriptions = async (id) => {
  try {
    const response = await axios.get("/subscriptions/getSubscriptions", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    // console.log("response.data" + JSON.stringify(response.data));
    localStorage.setItem(
      "userSubscription",
      JSON.stringify(response.data.data)
    );
    return response.data.data;
  } catch (err) {
    console.log(`error`, err);
    return [];
  }
};
