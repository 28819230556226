import { BrowserRouter, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Signup2 from "./Signup/Signup2";
import CompanyInformations from "./Signup/CompanyInformations";

import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import EmailAuth from "./pages/EmailAuth";
import React, { Suspense } from "react";
import ReactLoading from "react-loading";
import Regions from "./Regions";
import QuickLinks from "./QuickLinks";
import SubmitPage from "./Signup/components/SubmitPage.jsx";
import Confirm from "./Signup/Conform";
import Securityverify from "./securityverify.js";
import { context, PopContext, UserContext } from "./context/context";
import Profile from "./Profile/Profile";
import MobileMessageLogin from "./MobileMessageLogin";


const Example = ({ type, color }) => (
  <ReactLoading type={type} color={color} height={667} width={375} />
);

function App() {
  return (
    <div className="App">
      <PopContext>
        <BrowserRouter>
          <div className="content">
            <Switch>
              <PublicRoute exact path="/" component={Home} />
              <PublicRoute path="/signup" component={Signup2} />
              <PublicRoute path="/confirm/:id" component={Confirm} />
              
              <PublicRoute path="/submitPage" component={SubmitPage} />
              <PublicRoute path="/regions" component={Regions} />
              <PublicRoute path="/quickLinks" component={QuickLinks} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/mobileMessageLogin" component={MobileMessageLogin} />
              <PublicRoute
                path="/validateToken"
                component={CompanyInformations}
              />
              <PublicRoute path="/securityVerify" component={Securityverify} />

              <PublicRoute path="/validateAuthToken" component={EmailAuth} />
              <PrivateRoute path="/profile" component={Profile} />
            </Switch>
          </div>
        </BrowserRouter>
      </PopContext>
      <Toaster />
    </div>
  );
}

export default App;
