import React from "react";

export const Cntx = React.createContext();

const PopContext = ({ children }) => {
  const [val, setVal] = React.useState(false);
  return (
    <React.Fragment>
      <Cntx.Provider value={{ val, setVal }}>{children}</Cntx.Provider>
    </React.Fragment>
  );
};

export { PopContext };
