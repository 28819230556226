
import NavigationHeader from "../NavBar";
import "./Profile.css"
import ProfileStudyData  from "./ProfileStudyData";
import ProfileLeftNav from "./ProfileLeftNav";
import ProfilePayments from "./ProfilePayments";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";



function Profile () {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sourceNum = parseInt(queryParams.get("source"));
    
    const [renderOptions, setRenderOptions] = useState(sourceNum ? sourceNum : 1);
    const [selectedMenu, setSelectedMenu] = useState(sourceNum ? sourceNum : 1);
    
    //Indexes on ProfileLeftNav.js 

function RenderSettings () {
    if (renderOptions === 1){
    return <ProfileStudyData />
}else if (renderOptions === 2){
    return <ProfilePayments/>
}else {
    return 
}}


  useEffect(() => {
    const newSource = parseInt(queryParams.get("source"));
    if (!isNaN(newSource)) {
      setRenderOptions(newSource);
      setSelectedMenu(newSource);
    }
  }, [sourceNum]);



function handleSettingClick (e) {
    console.log(e.target.value, "hsc")
    setSelectedMenu(e.target.value);
    setRenderOptions(e.target.value);
}
    


return(
    <div>
        <div>
            <div style={{backgroundColor:"rgb(35, 45, 83)"}}>

            <NavigationHeader />
            
            </div>
            <br></br>
            <br></br>
            <div  className="row" style={{ marginRight: "0px" , overflow:"hidden", maxHeight:"81vh"}}>
                <div style={{ color: "white", width: "328px", padding: "0 2rem" }}>
                <div style={{ marginLeft: "13px" }}>
                    <ProfileLeftNav handleSettingClick = {handleSettingClick} selectedMenu={selectedMenu}/>
                  
                </div>
                </div>

            <div style={{ width: "20px" }}>
                <div
                    style={{
                    borderLeft: "2px solid #313B65",
                    marginTop: "-48.2px",
                    height: "105%",
                    }}
          />
            </div>
            
                {RenderSettings()}
                
      </div>



                {/* <div className="row" style={{height: "100%", marginRight:"0px"}}>
                    <div style={{width: "30%"}}>

                        <ProfileLeftNav  />
                    </div>
                    <div style={{width: "70%"}}>

                        <ProfileDataTable />
                    </div>
                </div> */}
            </div>
            
        
    </div>
)



}
export default Profile;