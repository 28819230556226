import React, { Component } from 'react';
// import Regions from '../../src/Regions'
// import QuickLinks from '../../src/QuickLinks'
import axios from 'axios';
import * as fetches from './fetches';
import ConfirmationPopup from './components/ConfirmationPopup';
import TermsAndConditionsPopup from './components/TermsAndConditionsPopup/TermsAndConditionsPopup';
import { passwordStrength } from 'check-password-strength';
import PeerformanceLogo from '../assets/peerformance_logo.svg';
import Toggleicon from '../assets/toggle-icon.svg';
import Lefticonarrowwhite from '../assets/left-icon-arrow-white.png';
import Closeiconimg from '../assets/close-icon-img.png';
import Accountanceicon from '../assets/Accountance-icon.png';
import Lawcon from '../assets/Law-con.png';
import Designicon from '../assets/design-icon.png';
import Shoppinglist1 from '../assets/shopping-list-1.svg';
import Recruitment1 from '../assets/recruitment1.png';
import Plumbersicon from '../assets/Plumbers-icon.png';
import Engineering from '../assets/engineering.svg';
import Advertisingicon from '../assets/Advertising-icon.png';
import Electriciansicon from '../assets/Electricians-icon.png';
import Financialplanner from '../assets/Financial-Planner.svg';
import Architectureicon from '../assets/Architecture-icon.svg';

import validator from 'email-syntax-validator';
import TermsAndConditions from './components/TermsAndConditions';
import Button from '../components/Button';
import InputFeedback from '../components/InputFeedback';
import { feedbackMessages } from './constants';
import { PAGES, BUTTONS } from '../constants/en';
import './Signup2.css'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import toast from 'react-hot-toast';


// import { Modal, Row } from "react-bootstrap";
// import arrowLeft from "../Styleguide/arrow_left.svg";
// import {
// 	peerformanceBackground2,
// 	peerformanceBlueButton,
// 	peerformanceDarkBlue,
// 	peerformanceGreen,
// } from "../colours";


const initialFormState = {
  email: {
    value: '',
    isValid: true,
    error: false,
  },
  password: {
    value: '',
    isValid: true,
    error: false,
  },
  confirmPassword: {
    value: '',
    isValid: true,
    error: false,
  },
  name: {
    value: '',
    isValid: true,
  },
  company: {
    value: '',
    isValid: true,
  },
  // question1: {
  //   value: '',
  //   isValid: true,
  // },
  answer1: {
    value: '',
    isValid: true,
  },
};
export default class Signup2 extends Component {






  state = {
    email: '',
    noOfEmployees: '',
    reach: '',
    regions: [],
    quickLinks: [],
    data: [],
    selectOptions: [],
    id: "",
    securityquestion: '',
    activeBoxNo: 1,
    inputsForm: initialFormState,
    isConfirmationPopupOpened: false,
    conditionsAreAgreed: false,
    isTermsAndConditionsPopupOpened: false,
    alertMessage: { type: '', message: '' },
  };

  setInputValue = (e) => {
    console.log(e)
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      inputsForm: {
        ...prevState.inputsForm,
        [name]: {
          ...prevState.inputsForm[name],
          value: value,
          isValid: !!value,
        },
      },
    }));
  };

  setInvalidField = (name, value, error) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        inputsForm: {
          ...prevState.inputsForm,
          [name]: {
            ...prevState.inputsForm[name],
            isValid: value,
            error,
          },
        },
      };
    });
  };

  registerAccount = async () => {
    const {
      inputsForm: { email, password, name, company, answer1, question1 },
    } = this.state;
    console.log(this.state)
    try {
      const response = await fetches.signUp(
        email.value,
        password.value,
        name.value,
        company.value,
        answer1.value,
        this.state.data.id
      ).catch((error) => console.log(error));
      console.log('response', response)
      if (!response.error) {
        this.setAlert('success', PAGES.SIGN_UP.MESSAGES.CREATED_ACCOUNT);
        // this.setState({ ...this.state, email:response.data.email })
        // this.setState({ ...this.state, activeBoxNo: 2 })
        localStorage.setItem('id', response.data.id)
        localStorage.setItem('tokenSignup', response.data.token)
        this.sendEmail(response.data.email, response.data.name)
      }
      else {
        console.log(response.error)
        // toast.error(response.error)
        let msg = {
          type: 'danger',
          message: response.error
        }
        this.setState({ ...this.state, alertMessage: msg });
      }


    } catch (error) {
      console.log(error);
      if (error) {
        console.log(`error`, error);
      }
    } finally {
      this.closeConfirmationPopup();
    }
  };

  // checkAccount = async () => {
  // 	const {
  // 		inputsForm: {
  // 			emailAddress: { value },
  // 		},
  // 	} = this.state;
  // 	const response = await fetches.checkAccount(value);
  // 	return response && response.data ? response.data : null;
  // };

  refreshTokenMessage = () => (
    <p>
      User activation in progress.
      <br />
      Please check your e-mail or click this link to regenerate process:&nbsp;
      <span
        className='text-decoration-underline text-primary c-pointer'
        onClick={this.handleRefreshToken}
      >
        link
      </span>
    </p>
  );








  handleRefreshToken = async () => {
    await fetches.refreshToken(this.state.inputsForm.email.value);
    this.setAlert('success', PAGES.SIGN_UP.MESSAGES.EMAIL_SENT);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    // this.registerAccount();
    this.clearAlert();
    if (this.checkFormIsValid()) {
      // const accountInfo = await this.checkAccount();

      // if (accountInfo && accountInfo.userExists) {
      // 	this.setAlert('danger', PAGES.SIGN_UP.MESSAGES.ACCOUNT_EXIST);
      // } else if (accountInfo.userActivationInProgress) {
      // 	this.setAlert('warning', this.refreshTokenMessage());
      // } else if (accountInfo.domainInOrganization) {
      // 	this.openConfirmationPopup();
      // } else {
      this.registerAccount();
      // }
    } else {
      this.setAlert('danger', PAGES.SIGN_UP.MESSAGES.FILL_REQUIRED_FIELDS);
    }
  };



  async sendEmail(email, name) {
    let UserId = localStorage.getItem('id')
    let body =
    {
      "authorizationemail": email,
      "subject": "Confirm Account",
      "content": `<!DOCTYPE HTML PUBLIC " -//W3C//DTD XHTML 1.0 Transitional //EN" "
      http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="
      http://www.w3.org/1999/xhtml" xmlns:v=" urn:schemas-microsoft-com:vml"
      xmlns:o=" urn:schemas-microsoft-com:office:office"><head><!--[if gte mso 9]><xml>  <o:OfficeDocumentSettings>    <o:AllowPNG/>    <o:PixelsPerInch>96</o:PixelsPerInch>  </o:OfficeDocumentSettings></xml><![endif]-->
          <meta http-equiv=" Content-Type" content=" text/html;
              charset=UTF-8"> <meta name=" viewport" content="
              width=device-width, initial-scale=1.0"> <meta name="
              x-apple-disable-message-reformatting"> <!--[if !mso]><!--><meta
              http-equiv=" X-UA-Compatible" content=" IE=edge"><!--<![endif]-->
          <title></title>  <style type=" text/css">      @media only screen and (min-width: 520px) {  .u-row {    width: 500px !important;  }  .u-row .u-col {    vertical-align: top;  }  .u-row .u-col-33p33 {    width: 166.65px !important;  }  .u-row .u-col-66p67 {    width: 333.35px !important;  }  .u-row .u-col-100 {    width: 500px !important;  }}@media (max-width: 520px) {  .u-row-container {    max-width: 100% !important;    padding-left: 0px !important;    padding-right: 0px !important;  }  .u-row .u-col {    min-width: 320px !important;    max-width: 100% !important;    display: block !important;  }  .u-row {    width: calc(100% - 40px) !important;  }  .u-col {    width: 100% !important;  }  .u-col > div {    margin: 0 auto;  }}body {  margin: 0;  padding: 0;}table,tr,td {  vertical-align: top;  border-collapse: collapse;}p {  margin: 0;}.ie-container table,.mso-container table {  table-layout: fixed;}* {  line-height: inherit;}a[x-apple-data-detectors='true'] {  color: inherit !important;  text-decoration: none !important;}@media (max-width: 480px) {  .hide-mobile {    max-height: 0px;    overflow: hidden;    display: none !important;  }}table, td { color: #000000; } a { color: #0000ee; text-decoration: underline; } @media (max-width: 480px) { #u_content_heading_1 .v-font-size { font-size: 20px !important; } }    </style>
           <!--[if !mso]><!--><link href="
              https://fonts.googleapis.com/css?family=Montserrat:400,700" rel="
              stylesheet" type=" text/css"><!--<![endif]--></head><body
          class=" clean-body u_body" style=" margin: 0;padding:
          0;-webkit-text-size-adjust: 100%;background-color: #181f50;color:
          #000000"> <!--[if IE]><div class="ie-container"><![endif]-->
          <!--[if mso]><div class="mso-container"><![endif]--> <table style="
              border-collapse: collapse;table-layout: fixed;border-spacing:
              0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align:
              top;min-width: 320px;Margin: 0 auto;background-color:
              #181f50;width:100%" cellpadding=" 0" cellspacing=" 0">
              <tbody> <tr style=" vertical-align: top"> <td style="
                          word-break: break-word;border-collapse: collapse
                          !important;vertical-align: top"> <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #181f50;"><![endif]-->
                          <div class=" u-row-container" style=" padding:
                              0px;background-color: transparent"> <div class="
                                  u-row" style=" Margin: 0 auto;min-width:
                                  320px;max-width: 500px;overflow-wrap:
                                  break-word;word-wrap: break-word;word-break:
                                  break-word;background-color: transparent;">
                                  <div style=" border-collapse: collapse;display:
                                      table;width: 100%;background-color:
                                      transparent;"> <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
                                      <!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]--><div
                                          class=" u-col u-col-33p33" style="
                                          max-width: 320px;min-width:
                                          167px;display:
                                          table-cell;vertical-align: top;">
                                          <div style=" width: 100% !important;">
                                              <!--[if (!mso)&(!IE)]><!--><div
                                                  style=" padding:
                                                  0px;border-top: 0px solid
                                                  transparent;border-left: 0px
                                                  solid transparent;border-right:
                                                  0px solid
                                                  transparent;border-bottom: 0px
                                                  solid transparent;"><!--<![endif]-->
                                                  <table style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                  <table
                                                                      width="
                                                                      100%"
                                                                      cellpadding="
                                                                      0"
                                                                      cellspacing="
                                                                      0"
                                                                      border="
                                                                      0"> <tr>
                                                                          <td
                                                                              style="
                                                                              padding-right:
                                                                              0px;padding-left:
                                                                              0px;"
                                                                              align="
                                                                              left">
                                                                              
                                                                              <img
                                                                                  align="left"
                                                                                  border="0"
                                                                                  src="
                                                                                  https://images.unlayer.com/projects/54208/1651306505202-peerformance_logo.svg"
                                                                                 
                                                                                  style="
                                                                                  outline:
                                                                                  none;text-decoration:
                                                                                  none;-ms-interpolation-mode:
                                                                                  bicubic;clear:
                                                                                  both;display:
                                                                                  inline-block
                                                                                  !important;border:
                                                                                  none;height:
                                                                                  auto;float:
                                                                                  none;width:
                                                                                  100%;max-width:
                                                                                  147px;"
                                                                                  width="
                                                                                  147"
                                                                                  />
                                                                              
                                                                          </td>
                                                                      </tr></table>
                                                              </td> </tr>
                                                      </tbody></table>
                                                  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
                                          </div></div><!--[if (mso)|(IE)]></td><![endif]--><!--[if (mso)|(IE)]><td align="center" width="333" style="width: 333px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]--><div
                                          class=" u-col u-col-66p67" style="
                                          max-width: 320px;min-width:
                                          333px;display:
                                          table-cell;vertical-align: top;">
                                          <div style=" width: 100%
                                              !important;border-radius:
                                              0px;-webkit-border-radius: 0px;
                                              -moz-border-radius: 0px;">
                                              <!--[if (!mso)&(!IE)]><!--><div
                                                  style=" padding:
                                                  0px;border-top: 0px solid
                                                  transparent;border-left: 0px
                                                  solid transparent;border-right:
                                                  0px solid
                                                  transparent;border-bottom: 0px
                                                  solid transparent;border-radius:
                                                  0px;-webkit-border-radius: 0px;
                                                  -moz-border-radius: 0px;"><!--<![endif]-->
                                                  <table id="
                                                      u_content_heading_1"
                                                      class=" hide-mobile"
                                                      style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                   <h1 class="
                                                                      v-font-size"
                                                                      style="
                                                                      margin: 0px;
                                                                      color:
                                                                      #ecf0f1;
                                                                      line-height:
                                                                      240%;
                                                                      text-align:
                                                                      left;
                                                                      word-wrap:
                                                                      break-word;
                                                                      font-weight:
                                                                      normal;
                                                                      font-family:
                                                                      'Montserrat',sans-serif;
                                                                      font-size:
                                                                      25px;">
                                                                      <strong>P
                                                                      </strong><strong>E
                                                                          E R F O
                                                                          R M A N
                                                                          C E</strong>
                                                                  </h1> </td>
                                                          </tr> </tbody></table>
                                                  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
                                          </div></div><!--[if (mso)|(IE)]></td><![endif]-->
                                      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                                  </div> </div></div><div class="
                              u-row-container" style=" padding:
                              0px;background-color: transparent"> <div class="
                                  u-row" style=" Margin: 0 auto;min-width:
                                  320px;max-width: 500px;overflow-wrap:
                                  break-word;word-wrap: break-word;word-break:
                                  break-word;background-color: transparent;">
                                  <div style=" border-collapse: collapse;display:
                                      table;width: 100%;background-color:
                                      transparent;"> <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
                                      <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]--><div
                                          class=" u-col u-col-100" style="
                                          max-width: 320px;min-width:
                                          500px;display:
                                          table-cell;vertical-align: top;">
                                          <div style=" width: 100%
                                              !important;border-radius:
                                              0px;-webkit-border-radius: 0px;
                                              -moz-border-radius: 0px;">
                                              <!--[if (!mso)&(!IE)]><!--><div
                                                  style=" padding:
                                                  0px;border-top: 0px solid
                                                  transparent;border-left: 0px
                                                  solid transparent;border-right:
                                                  0px solid
                                                  transparent;border-bottom: 0px
                                                  solid transparent;border-radius:
                                                  0px;-webkit-border-radius: 0px;
                                                  -moz-border-radius: 0px;"><!--<![endif]-->
                                                  <table style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                   <div>
                                                                      <strong
                                                                          style="
                                                                          color:white">Hello,
                                                                          ${name}</strong>
                                                                  </div> </td>
                                                          </tr> </tbody></table>
                                                  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
                                          </div></div><!--[if (mso)|(IE)]></td><![endif]-->
                                      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                                  </div> </div></div><div class="
                              u-row-container" style=" padding:
                              0px;background-color: transparent"> <div class="
                                  u-row" style=" Margin: 0 auto;min-width:
                                  320px;max-width: 500px;overflow-wrap:
                                  break-word;word-wrap: break-word;word-break:
                                  break-word;background-color: transparent;">
                                  <div style=" border-collapse: collapse;display:
                                      table;width: 100%;background-color:
                                      transparent;"> <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
                                      <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]--><div
                                          class=" u-col u-col-100" style="
                                          max-width: 320px;min-width:
                                          500px;display:
                                          table-cell;vertical-align: top;">
                                          <div style=" width: 100%
                                              !important;border-radius:
                                              0px;-webkit-border-radius: 0px;
                                              -moz-border-radius: 0px;">
                                              <!--[if (!mso)&(!IE)]><!--><div
                                                  style=" padding:
                                                  0px;border-top: 0px solid
                                                  transparent;border-left: 0px
                                                  solid transparent;border-right:
                                                  0px solid
                                                  transparent;border-bottom: 0px
                                                  solid transparent;border-radius:
                                                  0px;-webkit-border-radius: 0px;
                                                  -moz-border-radius: 0px;"><!--<![endif]-->
                                                  <table style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                   <table
                                                                      height="
                                                                      0px"
                                                                      align="
                                                                      center"
                                                                      border="
                                                                      0"
                                                                      cellpadding="
                                                                      0"
                                                                      cellspacing="
                                                                      0" width="
                                                                      100%"
                                                                      style="
                                                                      border-collapse:
                                                                      collapse;table-layout:
                                                                      fixed;border-spacing:
                                                                      0;mso-table-lspace:
                                                                      0pt;mso-table-rspace:
                                                                      0pt;vertical-align:
                                                                      top;border-top:
                                                                      1px solid
                                                                      #BBBBBB;-ms-text-size-adjust:
                                                                      100%;-webkit-text-size-adjust:
                                                                      100%">
                                                                      <tbody>
                                                                          <tr
                                                                              style="
                                                                              vertical-align:
                                                                              top">
                                                                              <td
                                                                                  style="
                                                                                  word-break:
                                                                                  break-word;border-collapse:
                                                                                  collapse
                                                                                  !important;vertical-align:
                                                                                  top;font-size:
                                                                                  0px;line-height:
                                                                                  0px;mso-line-height-rule:
                                                                                  exactly;-ms-text-size-adjust:
                                                                                  100%;-webkit-text-size-adjust:
                                                                                  100%">
                                                                                  <span>&#160;</span>
                                                                              </td>
                                                                          </tr>
                                                                      </tbody>
                                                                  </table>
                                                              </td> </tr>
                                                      </tbody></table><table
                                                      style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                   <h1 class="
                                                                      v-font-size"
                                                                      style="
                                                                      margin: 0px;
                                                                      color:
                                                                      #ecf0f1;
                                                                      line-height:
                                                                      140%;
                                                                      text-align:
                                                                      left;
                                                                      word-wrap:
                                                                      break-word;
                                                                      font-weight:
                                                                      normal;
                                                                      font-family:
                                                                      arial,helvetica,sans-serif;
                                                                      font-size:
                                                                      22px;">
                                                                      Thanks for
                                                                      joining
                                                                      Peerformance
                                                                      ! </h1>
                                                              </td> </tr>
                                                      </tbody></table><table
                                                      style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                   <div style="
                                                                      color:
                                                                      #ecf0f1;
                                                                      line-height:
                                                                      140%;
                                                                      text-align:
                                                                      left;
                                                                      word-wrap:
                                                                      break-word;">
                                                                      <p style="
                                                                          font-size:
                                                                          14px;
                                                                          line-height:
                                                                          140%;">Please
                                                                          confirm
                                                                          your
                                                                          email
                                                                          address
                                                                          to
                                                                          validate
                                                                          your
                                                                          account.</p>
                                                                  </div> </td>
                                                          </tr> </tbody></table><table
                                                      style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                  <div align="
                                                                      left">
                                                                      <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:arial,helvetica,sans-serif;"><tr><td style="font-family:arial,helvetica,sans-serif;" align="left"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:37px; v-text-anchor:middle; width:183px;" arcsize="11%" stroke="f" fillcolor="#14af50"><w:anchorlock/><center style="color:#000000;font-family:arial,helvetica,sans-serif;"><![endif]-->
                                                                      <a href="http://frontend.mypeerformance.com/confirm/${UserId}
                                                                          "
                                                                          target="
                                                                          _blank"
                                                                          style="
                                                                          box-sizing:
                                                                          border-box;display:
                                                                          inline-block;font-family:arial,helvetica,sans-serif;text-decoration:
                                                                          none;-webkit-text-size-adjust:
                                                                          none;text-align:
                                                                          center;color:
                                                                          #000000;
                                                                          background-color:
                                                                          #14af50;
                                                                          border-radius:
                                                                          4px;-webkit-border-radius:
                                                                          4px;
                                                                          -moz-border-radius:
                                                                          4px;
                                                                          width:auto;
                                                                          max-width:100%;
                                                                          overflow-wrap:
                                                                          break-word;
                                                                          word-break:
                                                                          break-word;
                                                                          word-wrap:break-word;
                                                                          mso-border-alt:
                                                                          none;">
                                                                          <span
                                                                              style="
                                                                              display:block;padding:10px
                                                                              20px;line-height:120%;">Validate
                                                                              your
                                                                              account</span>
                                                                      </a>
                                                                      <!--[if mso]></center></v:roundrect></td></tr></table><![endif]--></div>
                                                              </td> </tr>
                                                      </tbody></table><table
                                                      style="
                                                      font-family:arial,helvetica,sans-serif;"
                                                      role=" presentation"
                                                      cellpadding=" 0"
                                                      cellspacing=" 0" width="
                                                      100%" border=" 0">
                                                      <tbody> <tr> <td
                                                                  style="
                                                                  overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                                  align=" left">
                                                                   <div style="
                                                                      color:
                                                                      #ecf0f1;
                                                                      line-height:
                                                                      140%;
                                                                      text-align:
                                                                      left;
                                                                      word-wrap:
                                                                      break-word;">
                                                                      <p style="
                                                                          font-size:
                                                                          14px;
                                                                          line-height:
                                                                          140%;">If
                                                                          you
                                                                          didn't
                                                                          sign up
                                                                          to
                                                                          Peerformance,
                                                                          please
                                                                          ignore
                                                                          this
                                                                          email,
                                                                          its
                                                                          likely
                                                                          someone
                                                                          else
                                                                          accidentally
                                                                          entered
                                                                          your
                                                                          address
                                                                          or made
                                                                          at typo.</p>
                                                                  </div> </td>
                                                          </tr> </tbody></table>
                                                  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
                                          </div></div><!--[if (mso)|(IE)]></td><![endif]-->
                                      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                                  </div> </div></div> <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                      </td> </tr> </tbody> </table> <!--[if mso]></div><![endif]-->
          <!--[if IE]></div><![endif]--></body></html>`
    }
    const res = await axios.post('https://frontend.mypeerformance.com:8080/api/users/sendmail', body, { headers: { 'Content-Type': 'application/json' } })
    const data = res.data
    console.log("data", data)
    localStorage.clear()
    toast.success('You have registered successfully in Peerformance.Please check your email to validate your account.')
    const { history } = this.props
    this.clearForm();
    if (window.innerWidth <= 480) {
      history.push("/mobileMessageLogin")
    }
    else{
      history.push("/");
    }
  }
  async getOptions() {
    const res = await axios.get('https://frontend.mypeerformance.com:8080/api/securityquestions/getlist')
    const data = res.data
    console.log("data", data)
    this.state.data = data.data
    console.log("data", this.state.data)
  }
  async getQuickLink() {
    const res = await axios.get('https://frontend.mypeerformance.com:8080/api/quicklinks/getlist', { headers: { "x-access-token": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsImlhdCI6MTY1MTIxMjc1MX0.4-m46wrSnEfOGuqGThMOr1EyYeIHmxQ5EPn042zNlTg` } })
    const data = res.data
    console.log("quickLinks", data)
    this.state.quickLinks = data.data
    console.log("quickLinks", this.state.quickLinks)
  }
  async getRegions() {
    const res = await axios.get('https://frontend.mypeerformance.com:8080/api/regions/getRegions', { headers: { "x-access-token": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsImlhdCI6MTY1MTIxMjc1MX0.4-m46wrSnEfOGuqGThMOr1EyYeIHmxQ5EPn042zNlTg` } })
    const data = res.data
    console.log("regions", data)
    this.state.regions = data.data
    console.log("regions", this.state.regions)
  }
  handleChange(e) {
    this.setState({ id: e.value, name: e.securityquestion })
  }
  componentDidMount() {
    this.getOptions()
    this.getQuickLink()
    this.getRegions()
    localStorage.clear()
  }

  checkFormIsValid = () => {
    const { inputsForm } = this.state;
    const hasError = [];

    Object.keys(inputsForm).forEach((field) => {
      console.log(field)
      switch (field) {
        case 'emailAddress':
          if (inputsForm[field].value && !validator.validate(inputsForm[field].value)) {
            this.setInvalidField(field, false, true);
            hasError.push(1);
          } else {
            this.setInvalidField(field, !!inputsForm[field].value, false);
            hasError.push(!!inputsForm[field].value ? 0 : 1);
          }
          break;
        case 'password':
          if (
            inputsForm[field].value &&
            !this.checkPasswordStrength(inputsForm[field].value)
          ) {
            this.setInvalidField(field, !!inputsForm[field].value, true);
            hasError.push(1);
          } else {
            this.setInvalidField(field, !!inputsForm[field].value, false);
            hasError.push(!!inputsForm[field].value ? 0 : 1);
          }
          break;
        case 'confirmPassword':
          if (inputsForm[field].value !== inputsForm.password.value) {
            this.setInvalidField(field, !!inputsForm[field].value, true);
            hasError.push(1);
          } else {
            this.setInvalidField(field, !!inputsForm[field].value, false);
            hasError.push(!!inputsForm[field].value ? 0 : 1);
          }
          break;
        default:
          this.setInvalidField(field, !!inputsForm[field].value);
          hasError.push(!!inputsForm[field].value ? 0 : 1);
          break;
      }
    });

    return !hasError.some((i) => i === 1);
  };

  checkPasswordStrength = (password) => passwordStrength(password)?.id === 3;

  closeConfirmationPopup = () => {
    this.setState({
      isConfirmationPopupOpened: false,
    });
  };

  openConfirmationPopup = () => {
    this.setState({
      isConfirmationPopupOpened: true,
    });
  };

  closeTermsAndConditionsPopup = () => {
    this.setState({
      isTermsAndConditionsPopupOpened: false,
    });
  };

  openTermsAndConditionsPopup = () => {
    this.setState({
      isTermsAndConditionsPopupOpened: true,
    });
  };

  agreedOrDisagreedConditions = (value) => {
    this.closeTermsAndConditionsPopup();
    this.setState({
      conditionsAreAgreed: value,
    });
  };

  switchAgreed = () => {
    this.setState({
      conditionsAreAgreed: !this.state.conditionsAreAgreed,
    });
  };

  setAlert = (type, message) => this.setState({ alertMessage: { type, message } });
  clearAlert = () => this.setState({ alertMessage: { type: '', message: '' } });

  clearForm = () => this.setState({ inputsForm: initialFormState, conditionsAreAgreed: false });



  render() {

    console.log(this.state.selectOptions)
    const {
      inputsForm: { email, password, confirmPassword, name, company, answer1 },
      isConfirmationPopupOpened,
      conditionsAreAgreed,
      isTermsAndConditionsPopupOpened,
      alertMessage,
    } = this.state;
    const {
      registerAccount,
      onSubmit,
      closeConfirmationPopup,
      closeTermsAndConditionsPopup,
      openTermsAndConditionsPopup,
      agreedOrDisagreedConditions,
      switchAgreed,
    } = this;
    const { SIGN_UP, COMPANY_INFORMATIONS } = PAGES;
    // let global = "Global";
    // let europe = "Europe";
    // let allOfUk = "UK";


    return (
      <>


        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 mx-auto'>
              <div className='row'>
                <div className='col-md-5 col-sm-12 mx-auto'>
                  <img
                    className='w-50 mx-auto d-block mt-5'
                    src={PeerformanceLogo}
                    alt='Peerformance Logo'
                  />
                </div>
              </div>
              <br />
              {
                this.state.activeBoxNo === 1 && (
                  <div className='row'>
                    <div className='col-md-5 col-sm-12 mx-auto'>
                      <form noValidate className='w-100' >
                        <div className='row mt-3 form-mb'>
                          <div className='group-inputs p-3'>

                            <div className='group-input mt-2'>
                              <label htmlFor='Name'>{SIGN_UP.FORM.NAME.LABEL}</label>
                              <input
                                className={`form-control mt-2 ${!name.isValid ? 'is-invalid' : ''
                                  }`}
                                type='text'
                                id='name'
                                name='name'
                                value={name.value}
                                onChange={this.setInputValue}
                                placeholder={SIGN_UP.FORM.NAME.PLACEHOLDER}
                                autoFocus
                                required
                              />
                              {!this.state.inputsForm.name.isValid && (
                                <InputFeedback message={feedbackMessages.name} />
                              )}
                            </div>
                            <div className='group-input mt-3'>
                              <label htmlFor='company'>{SIGN_UP.FORM.COMPANY.LABEL}</label>
                              <input
                                className={`form-control mt-2 ${!company.isValid ? 'is-invalid' : ''
                                  }`}
                                type='text'
                                id='company'
                                name='company'
                                value={company.value}
                                placeholder={SIGN_UP.FORM.COMPANY.PLACEHOLDER}
                                onChange={this.setInputValue}
                                required
                              />
                              {!this.state.inputsForm.company.isValid && (
                                <InputFeedback message={feedbackMessages.company} />
                              )}
                            </div>
                            <div className='group-input mt-3'>
                              <label htmlFor='email'>{SIGN_UP.FORM.EMAIL.LABEL}</label>
                              <input
                                className={`form-control mt-2 ${!email.isValid || email.error
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                type='email'
                                id='email'
                                name='email'
                                value={email.value}
                                onChange={this.setInputValue}
                                placeholder={SIGN_UP.FORM.EMAIL.PLACEHOLDER}
                                required
                              />
                              {!this.state.inputsForm.email.isValid &&
                                !this.state.inputsForm.email.error && (
                                  <InputFeedback
                                    message={feedbackMessages.emailRequired}
                                  />
                                )}
                              {!this.state.inputsForm.email.isValid &&
                                this.state.inputsForm.email.error && (
                                  <InputFeedback
                                    message={feedbackMessages.invalidEmail}
                                  />
                                )}
                            </div>
                            <div className='group-input mt-3'>
                              <label htmlFor='password'>{SIGN_UP.FORM.PASSWORD.LABEL}</label>
                              <input
                                className={`form-control mt-2 ${!password.isValid || password.error
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                type='password'
                                id='password'
                                name='password'
                                value={password.value}
                                onChange={this.setInputValue}
                                placeholder={SIGN_UP.FORM.PASSWORD.PLACEHOLDER}
                                required
                              />
                              {!this.state.inputsForm.password.isValid && (
                                <InputFeedback
                                  message={feedbackMessages.emptyPassword}
                                />
                              )}
                              {this.state.inputsForm.password.error && (
                                <InputFeedback
                                  message={feedbackMessages.passwordRequirements}
                                />
                              )}
                            </div>
                            <div className='group-input mt-3'>
                              <label htmlFor='confirmPassword'>{SIGN_UP.FORM.CONFIRM_PASSWORD.LABEL}</label>
                              <input
                                className={`form-control mt-2 ${!confirmPassword.isValid || confirmPassword.error
                                  ? 'is-invalid'
                                  : ''
                                  }`}
                                type='password'
                                id='confirmPassword'
                                name='confirmPassword'
                                value={confirmPassword.value}
                                onChange={this.setInputValue}
                                placeholder={SIGN_UP.FORM.CONFIRM_PASSWORD.PLACEHOLDER}
                                required
                              />
                              {!this.state.inputsForm.confirmPassword.isValid && (
                                <InputFeedback
                                  message={feedbackMessages.emptyConnfirmPassword}
                                />
                              )}
                              {this.state.inputsForm.confirmPassword.error && (
                                <InputFeedback
                                  message={feedbackMessages.samePasswords}
                                />
                              )}
                            </div>
                            <div className='group-input mt-3'>
                              <label htmlFor='question1'>{COMPANY_INFORMATIONS.FIELDS.QUESTION1.LABEL}</label>
                              {/* <select className='form-control mt-2  security select-tag-info' id="question1" name="question1">
                                <option value="0">Select a question from the following options.</option>
                                <option value="1">Who's your daddy?</option>
                                <option value="2">What is your favorite color?</option>
                                <option value="3">What is your mother's favorite aunt's favorite color?</option>
                                <option value="4">Where does the rain in Spain mainly fall?</option>
                                <option value="5">If Mary had three apples, would you steal them?</option>
                                <option value="6">What brand of food did your first pet eat?</option>
                              </select> */}



                              <select
                                className='form-control mt-2  security select-tag-info'
                                onChange={(e) => { this.state.data.id = e.currentTarget.value }}
                                name="question1"
                                value={this.state.data.id}
                                options={this.state.selectOptions}
                              >
                                <option value="" disabled="disabled" selected="selected">Select a question from the following options.</option>
                                {this.state.data.map((d) => (
                                  <option value={d.id}>{d.securityquestion}</option>))}

                              </select>
                            </div>
                            <div className='group-input mt-3'>
                              <label htmlFor='answer1'>{COMPANY_INFORMATIONS.FIELDS.ANSWER1.LABEL}</label>
                              <input
                                className={`form-control mt-2 ${!answer1.isValid ? 'is-invalid' : ''
                                  }`}
                                type='text'
                                id='answer1'
                                name='answer1'
                                value={answer1.value}
                                placeholder={COMPANY_INFORMATIONS.FIELDS.ANSWER1.PLACEHOLDER}
                                onChange={this.setInputValue}
                                required
                              />
                              {!this.state.inputsForm.answer1.isValid && (
                                <InputFeedback message={feedbackMessages.answer1} />
                              )}
                            </div>
                            <TermsAndConditions
                              switchAgreed={switchAgreed}
                              conditionsAreAgreed={conditionsAreAgreed}
                              openTermsAndConditionsPopup={openTermsAndConditionsPopup}
                            />
                            <br />
                            {alertMessage.message && (
                              <div
                                className={`alert alert-${alertMessage.type} notification-message`}
                                role='alert'
                              >
                                {alertMessage.message}
                              </div>
                            )}
                            <Button
                              onClick={onSubmit}
                              type='Submit'
                              text='Sign up'
                              disabled={!conditionsAreAgreed}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                )
              }


            </div>
          </div>
          <ConfirmationPopup
            isConfirmationPopupOpened={isConfirmationPopupOpened}
            closeConfirmationPopup={closeConfirmationPopup}
            registerAccount={registerAccount}
          />
          <TermsAndConditionsPopup
            isOpen={isTermsAndConditionsPopupOpened}
            closePopup={closeTermsAndConditionsPopup}
            setAgreeConditionsValue={agreedOrDisagreedConditions}
          />
        </div>

      </>
    );
  }
}
