import React, { useState, useEffect } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";

import NavigationHeader from "./NavBar";
import MainCard from "./MainCard";
import LeftSideNav from "./LeftSideNav";
import "./App.css";
import exampleStudy from "./Styleguide/example_study.svg";
import { dashboardActions } from "./store/dashboard";
import { fetchStudyById } from "./store/dashboardFetches";
import { useDispatch, useSelector } from "react-redux";
import { fetchParentPeerGroupBysic } from "./store/dashboardFetches";
import {
  getRegionChildren,
  getRegions,
  getTop3Regions,
  getUserInfo,
  getUserSubscriptions,
  setActiveStudy,
  toggleQEffect,
  setLastStudySubscribed
} from "./store/dashboardActions";
import AppLoading from "./Loading";

import {
  getExamplestudieslist,
  getStudyData,
  getStudies,
  getParticipantBreakdown,
} from "./store/dashboardActions";

import { JoinSocket } from "./Socket/socket";

function Dashboard(props) {
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(0);

  useEffect(() => {
    dispatch(getUserInfo());
    // dispatch(getUserSubscriptions());
    dispatch(getRegions());
    // dispatch(getTop3Regions());
    dispatch(getRegionChildren());
    // setActiveStudy();
    // subActive();

    // Faizan
    JoinSocket(localStorage.getItem("token"), (cbData) => {
      console.log("cbData", cbData);
    });
    //
  }, []);
  const userSubscriptions = useSelector(
    (state) => state.dashboard.userSubscriptions
  );
  const availableStudies = useSelector(
    (state) => state.dashboard.availableStudies
  );
  const queuedStudies = useSelector((state) => state.dashboard.queuedStudies);
  const activeStudies = useSelector((state) => state.dashboard.activeStudies);
  const lastStudySubscribed = useSelector((state) => state.dashboard.lastStudySubscribed);

 
  const allStudies = useSelector((state) => state.dashboard.allStudies);
  const toggleQEffectCheck = useSelector(
    (state) => state.dashboard.toggleQEffect
  );

  const setActiveStudy = () => {
    if (userSubscriptions.length > 0) {
      let study = {
        ID: userSubscriptions[0].StudyID,
        Name: userSubscriptions[0].study.Name,
        RegionID: userSubscriptions[0].RegionID,
        PeerGroupID: userSubscriptions[0].PeerGroupID,
        TypeID: userSubscriptions[0].TypeID,
        NumOfSubscribers: userSubscriptions[0].NumOfSubscribers,
        About: userSubscriptions[0].About,
        Status: userSubscriptions[0].study.Status,
        Activestatus: parseInt(userSubscriptions[0].Active),
        Category: userSubscriptions[0].Category,
        ParentCategory: userSubscriptions[0].ParentCategory,
        createdAt: userSubscriptions[0].study.createdAt,
        updatedAt: userSubscriptions[0].study.updatedAt,
      };
      dispatch(dashboardActions.setActiveStudy(study));

      const isSubscriber = userSubscriptions.some(
        (subscription) => subscription.StudyID === study.ID
      );
      if (isSubscriber) {
        dispatch(getStudyData(study.ID));
        dispatch(getParticipantBreakdown(study?.ID));
      } else {
        dispatch(dashboardActions.setStudyData(null));
      }
    } else if (availableStudies.length > 0) {
      dispatch(dashboardActions.setActiveStudy(availableStudies[0]));
      const isSubscriber = userSubscriptions.some(
        (subscription) => subscription.StudyID === availableStudies[0].ID
      );
      if (isSubscriber) {
        dispatch(getStudyData(availableStudies[0].ID));
        dispatch(getParticipantBreakdown(availableStudies[0].ID));
      } else {
        dispatch(dashboardActions.setStudyData(null));
      }
    } else if (queuedStudies.length > 0) {
      dispatch(dashboardActions.setActiveStudy(queuedStudies[0]));
      const isSubscriber = userSubscriptions.some(
        (subscription) => subscription.StudyID === queuedStudies[0].ID
      );
      if (isSubscriber) {
        dispatch(getStudyData(queuedStudies[0].ID));
        dispatch(getParticipantBreakdown(queuedStudies[0].ID));
      } else {
        dispatch(dashboardActions.setStudyData(null));
      }
    } else {
      dispatch(dashboardActions.setActiveStudy());

      //was setActiveStudy({}) changed to fix bug where no studys selected would stop main ternery statment in maincard

      dispatch(dashboardActions.setStudyData(null));
    }
  };

  React.useEffect(() => {
    if (userSubscriptions.length > 0) {
      if (firstLoad < 1) {
        console.log("SPERK", firstLoad);

        setFirstLoad(firstLoad + 1);
      } else {
        if (toggleQEffectCheck) {
          dispatch(toggleQEffect(false));
          console.log("SPERK3");
        } else {
          console.log("SPERK1");
          queueActive();
        }
      }
    } else {
      if (toggleQEffectCheck) {
        dispatch(toggleQEffect(false));
        console.log("SPERK3");
      } else {
        console.log("SPERK1");
        queueActive();
      }
    }
  }, [queuedStudies]);

  const queueActive = () => {
      if (allStudies.find((study) => study.StudyID === lastStudySubscribed))
      {
        console.log('lastStudySubscribed', lastStudySubscribed)
        dispatch(
          dashboardActions.setActiveStudy(
            allStudies.find((study) => study.StudyID === lastStudySubscribed)
          )
        );
        dispatch(
          getStudyData(
            allStudies.find((study) => study.StudyID === lastStudySubscribed)
          )
        );
        dispatch(setLastStudySubscribed(""));
      }else{

        if (allStudies.length > 0) {
          const queueHighestSubs = allStudies.reduce((max, current) =>
          current.NumOfSubscribers > max.NumOfSubscribers ? current : max
          );
          console.log("QACTIVE", queueHighestSubs);
          console.log("QACTIVE", queuedStudies);
          dispatch(dashboardActions.setActiveStudy(queueHighestSubs));
          dispatch(getStudyData(queueHighestSubs.ID));
        } else {
          console.log("sperk2");
        }
      }
    }
      ;
      
      const [getPeerGroupId, setPeerGroupId] = React.useState(
    localStorage.getItem("peerGroupId")
  );
  const [getRegionId, setRegionId] = React.useState(
    localStorage.getItem("regionId")
  );
  const [getFetchedLevel4, setFetchedLevel4] = React.useState(
    localStorage.getItem("fetchedLevel4")
  );

  let getNewUser;
  if (!localStorage.getItem("peerGroupId")) {
    return <AppLoading />;
  }

  //if (peerGroupId === "null" || peerGroupId === null || peerGroupId === undefined) {
  if (getRegionId === "null") {
    getNewUser = (
      <div style={{ marginTop: "-174.5px", marginLeft: "10px" }}>
        <img
          src={exampleStudy}
          height="1080"
          width="1600"
          style={{ marginLeft: "10px" }}
          alt=""
        />
      </div>
    );
  } else {
    getNewUser = (
      <div className="row" style={{ marginRight: "0px", height: "100%" }}>
        <div style={{ color: "white", width: "328px" }}>
          <LeftSideNav />
        </div>

        <div style={{ width: "20px" }}>
          <div
            style={{
              borderLeft: "2px solid #313B65",
              marginTop: "-48.2px",
              height: "105%",
            }}
          />
        </div>
        <MainCard />
      </div>
    );
  }
  return (
    <div>
      <div style={{ backgroundColor: "#232D53", width: "100%" }}>
        <div
          style={{
            backgroundColor: "#232D53",
            // , width: "1600px"
          }}
        >
          <NavigationHeader />
        </div>
      </div>
      <br />
      <br />
      <div
        className="container-fluid"
        style={{
          margin: "0px",
          padding: "0px",
          // , width: "1600px"
        }}
      >
        <div id="mainCardDisplay" style={{ display: "block" }}>
          {getNewUser}
        </div>

        <div>
          <div id="exampleStudyDisplay" style={{ display: "none" }}>
            <div style={{ marginTop: "-49px" }} id="exampleStudy2">
              <img src={exampleStudy} width="1600" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
      }  

export default Dashboard;
