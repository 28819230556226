
import Modal from 'react-bootstrap/Modal';
import TermsAndConditionsIcon from './Styleguide/icons/terms_conditions_icon.svg';

import { PAGES, BUTTONS } from './constants/en';
import React, { useState } from 'react';
import { JoinStudyModalTerms } from './JoinStudyModalTerms';
import { useSelector } from 'react-redux';

export function JoinStudyModal({onHide, onSubscribeStudy, show}) {
    const [terms, setTerms] = useState(false);
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const [isCheckedToken, setIsCheckedToken] = useState(false);
    const [userCheckWarning, setUserCheckWarning] = useState(false);
    
    const [clicked, setClicked] = useState(false);

    const activeStudy = useSelector((state) => state.dashboard.activeStudy);
    const userInfo = useSelector((state) => state.dashboard.userInfo);
   
    const userValue = userInfo?.ID;
    const studyValue = activeStudy?.ID;



    const handleCheckboxChangeTerms = () => {
      setIsCheckedTerms(!isCheckedTerms);
    };
    const handleCheckboxChangeToken = () => {
      setIsCheckedToken(!isCheckedToken);
    };

    const generateStripeUrl = () => {
        const url = `https://buy.stripe.com/8wM2cf19mfqEdCUbII?client_reference_id=${encodeURIComponent(JSON.stringify({ user: userValue, study: studyValue }))}`;
        return url;
  };



    const checkBoxChecker = () => {
        if (isCheckedTerms && isCheckedToken){
            
            handleClick()
            onSubscribeStudy()
            onHide()
        } else {
            // setUserCheckWarning(true)
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
            //FIX THIS CAUSES MASSIVE RERENDERS^^^^
            return false
        }
    }

    const handleClick = () => {
        if (!clicked) {
          setClicked(true);
        }
      };


  return (
    <Modal className="terms-conditions-popup" show={show} onHide={onHide}>
      <Modal.Header></Modal.Header>
      <Modal.Body className="m-2 overflow-auto text-center">
        <h4 className="mb-4">This study requires 1 token.</h4>
        <div className="form-check mb-4">
          <input
            className="form-check-input mb-4"
            type="checkbox"
            value=""
            id="checkboxToken"
            style={{
              backgroundColor: "#36d881",
              borderColor: "#1c6c40",
              scale: "1.3",
            }}
            checked={isCheckedToken}
            onChange={handleCheckboxChangeToken}
          />
          <label className="form-check-label mb-4" for="checkboxToken">
            Please tick to accept.
          </label>
        </div>
        <h4 className="mb-4">
          Please confirm you have read and agree to study terms and conditions
        </h4>
        <div className="form-check mb-4">
          <input
            className="form-check-input mb-4"
            type="checkbox"
            value=""
            id="checkboxTerms"
            checked={isCheckedTerms}
            onChange={handleCheckboxChangeTerms}
            style={{
              backgroundColor: "#36d881",
              borderColor: "#1c6c40",
              scale: "1.3",
            }}
          />
          <label className="form-check-label mb-4" for="checkboxTerms">
            Please tick to confirm.
          </label>
        </div>

        <JoinStudyModalTerms
          show={terms}
          onHide={() => setTerms(false)}
          handleCheckboxChange={handleCheckboxChangeTerms}
        />
      </Modal.Body>
      <Modal.Footer>
        {userCheckWarning && (
          <p style={{ color: "red", margin: "auto" }}>
            {" "}
            Please tick checkboxes to continue
          </p>
        )}
        <button
          style={{
            width: "100% ",
            color: "#38D982",
            paddingBottom: "1rem",
            margin: "auto",
            textAlign: "center",
            background: "none",
            border: "none",
          }}
          onClick={() => setTerms(true)}
        >
          Click to read terms and conditions.
        </button>

        <button
          type="button"
          className="btn decline-button d-inline-block px-5"
          onClick={onHide}
        >
          {BUTTONS.DECLINE}
        </button>

        <button
          type="button"
          className="btn accept-button d-inline-block px-5"
          onClick={checkBoxChecker}
        >
          Subscribe
          {/* <a
          href={generateStripeUrl()}
          role="button"
          aria-label="Click me to go to subscribe to a study"
          tabindex="0"
          className="no-underline btn accept-button d-inline-block px-5"
          style={{ pointerEvents: checkBoxChecker() ? "auto" : "none" }}
        >
          Subscribe
        </a> */}
        </button>
      </Modal.Footer>
    </Modal>
  );
}