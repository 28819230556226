export let peerformanceDarkBlue = "#141936";
export let peerformanceDarkBlue2 = "#192141";
export let peerformanceBlueButton = "#313B65";
export let peerformanceBackground2 = "#1F2748";
export let peerformanceListItem = "#262D51";
export let peerformanceGreen = "#38d982";
export let peerformanceDarkGreen = "#104750";
export let peerformanceBlueWhite = "#989cb4";
export let peerformanceBlueText = "#131833";
export let peerformancePurple = "#B114D6";
export let peerformanceBabyBlue = "#466ef2";
export let peerformanceTextBabyBlue = "#A5ACCB";
export let peerformanceMenuText = "#7B84A8";


