import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { addStudyData, getStudyData } from "./store/dashboardActions";
import { encryptKey } from "./Utils/Encryption";
import { getDecryptedKey, getIV, getUser } from "./Utils/Common";
import { decryptDataCTR } from "./Utils/DecryptDataCTR";
import plusSign from "./Styleguide/plus-symbol.svg";
import AddDataAbsoluteNumberType from "./Dashboard/AddDataAbsoluteNumberType";
import AddDataEfficiencyType from "./Dashboard/AddDataEfficiencyType";
import AddDataPercentageType from "./Dashboard/AddDataPercentageType";
import  toast  from "react-hot-toast";

const AddDataPopUp = ({ type }) => {
  const dispatch = useDispatch();
  const activeStudy = useSelector((state) => state.dashboard.activeStudy);
  const studiesNeedForSubs = useSelector((state) => state.dashboard.allStudies);
  const showExampleStudystate = useSelector(
    (state) => state.dashboard.showExampleStudy
  );
  const [show, setShow] = useState(false);
  const [buttonValue, setbuttonValue] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!showExampleStudystate) {
      setShow(true);
    }
  };

const studyDataGetter = useSelector((state) => state.dashboard.studyData)
console.log(studyDataGetter, "studget" , activeStudy ,studiesNeedForSubs)


function calculateDays(studyData, activeStudy) {
  const currentDate = new Date();
  const prevMonth = new Date(currentDate);
  const currentMonth = new Date(currentDate);
  currentMonth.getMonth();
  prevMonth.setMonth(currentDate.getMonth() - 1);
  const nextMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    7
  );

  const daysDifference = Math.ceil(
    (nextMonth - currentDate) / (1000 * 3600 * 24)
  );

  let days2Difference = false;
  if (studyData && studyData[0]?.Date) {
    console.log('studyData', studyData)
    const comparisonDate = new Date(studyData[0].Date);
    days2Difference = Math.ceil(
      (nextMonth - comparisonDate) / (1000 * 3600 * 24)
    );
  }

  const PrevMonthFormatted = new Intl.DateTimeFormat("en-GB", {
    month: "long",
  }).format(prevMonth);
  const CDateMonthFormatted = new Intl.DateTimeFormat("en-GB", {
    month: "long",
  }).format(currentMonth);



  const dateObby = {
    CurrentDate: currentDate.toDateString(),
    SeventhDayofNextMonth: nextMonth.toDateString(),
    Numberofdaysbetweenthem: daysDifference,
    Daydifferenceonsub: days2Difference,
    Padding: "4%",
    currentDay: currentDate.getDate(),
    previousMonth: PrevMonthFormatted,
    currentMonth: CDateMonthFormatted
  };

  const activeStudyMatchForSubCount = studiesNeedForSubs.find(
    (obj) => obj.ID === activeStudy.ID
  );

  if (
    activeStudyMatchForSubCount &&
    activeStudyMatchForSubCount.NumOfSubscribers < "5"
  ) {
    dateObby.Output =
      "Needs " +
      (5 - activeStudyMatchForSubCount.NumOfSubscribers) +
      " more participants";
    dateObby.Color = "#6585ff";
    // dateObby.Clickable = "none";
    dateObby.Img = "none";
  } else {
    if (days2Difference && 7 < days2Difference < 14 ) {
      dateObby.Output = "Data Added ";
      dateObby.Color = "#38d982";
      console.log(dateObby, "dateOBBY");
    } else if (dateObby.currentDay <= 7) {
      // dateObby.Output = "Data due in " + dateObby.Numberofdaysbetweenthem + " Days"
      dateObby.Output = "Add " + dateObby.previousMonth + " Data";
      dateObby.Color = "#38d881";

      console.log(dateObby, "dateOBBY");
    } else if (dateObby.Numberofdaysbetweenthem <= 4) {
      dateObby.Output =
        dateObby.previousMonth +
        "Data close in " +
        dateObby.Numberofdaysbetweenthem +
        " Days";
      dateObby.Color = "#dc3545";
    } else {
       let daysRemaining = dateObby.Numberofdaysbetweenthem - 6;
      dateObby.Output =
        dateObby.currentMonth +
        " Data due in " +
        (daysRemaining < 2 ? "1 Day" : daysRemaining + " Days");
      dateObby.Color = "#6585ff";
      // dateObby.Clickable = "none";
      dateObby.Img = "none";
      console.log(dateObby, "dateOBBY");
    }
  }

  return dateObby;
} 


useEffect(() => {
  
  setbuttonValue(calculateDays(studyDataGetter, activeStudy))
  
 
}, [studiesNeedForSubs])





  const addData = (value) => {
    dispatch(addStudyData(value, activeStudy.ID));
    dispatch(getStudyData(activeStudy.ID));
    handleClose();
    toast.success(
      "Your encrypted data has been received - your results will be available on the 8th of this month"
    );
  };

  const renderPopupContentDependsOnStudyType = () => {
    switch (type) {
      case 1:
        return (
          <AddDataPercentageType handleClose={handleClose} addData={addData} />
        );
      case 2:
        return (
          <AddDataEfficiencyType handleClose={handleClose} addData={addData} />
        );
      case 3:
        return (
          <AddDataAbsoluteNumberType
            handleClose={handleClose}
            addData={addData}
          />
        );
      default:
        return null;
    }
  };

  




  return (
    <div className="input-group-sm mb-3  float-end">
      <Button
        variant=""
        onClick={handleShow}
        style={{
          backgroundColor: buttonValue.Color ? buttonValue.Color : "#38d881",
          paddingTop: buttonValue.Padding ? buttonValue.Padding : "8%",
          paddingBottom: buttonValue.Padding ? buttonValue.Padding : "8%",
          border: buttonValue ? "none" : "2px solid #38d881",
          borderRadius: "10px",
          pointerEvents: buttonValue.Clickable ? buttonValue.Clickable : "auto"
        }}
      >
        <small
          style={{
            fontSize: "16px",
            paddingRight: "10px",
            paddingLeft: "10px",
          }}
        >
          {" "}
          <img
            src= {buttonValue.Img ? buttonValue.Img : plusSign}
            alt=""
            style={{ marginTop: "-4px", marginRight: "5px" }}
          />
          {buttonValue?.Output} 
        </small>
      </Button>

      <Modal
        className="add-data-popup"
        size="lg"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Body>
          {/* <form noValidate onSubmit={onSubmitForm}> */}
          {renderPopupContentDependsOnStudyType()}
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddDataPopUp;
