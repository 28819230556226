import React, { useEffect, useState } from 'react';

import { getUser } from "./Utils/Common";
import logo from "./Styleguide/logo_horizontal.svg";


function MobileMessageLogin(props) {
  const user = getUser();

    return (
      <div>
        <div className="flexContainer">
          <div className="flex-item1">
            <a href="https://www.mypeerformance.com">
              <img
                  src={logo}
                  alt=""
                  width="90%"
              />
            </a>
            </div>
          <div className="flex-item2" style={{padding:"24px"}}>
            <h4 style={{fontSize:"16px", color:"rgba(255,255,255,1)", lineHeight:"27px"}}>Hi <b>{user.name}</b>,<br></br><br></br> Peerformance is currently a web based application only.   Please login from a desktop or an iPad.  We will notify you once the mobile application is available.</h4> 
            <div className="back-home"style={{marginTop:"50px"}}>
              <a href='https://www.mypeerformance.com'>
                Back to Homepage
              </a>
            </div>  
          </div>
        </div>
      </div>
    )
}

export default MobileMessageLogin;

