import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import lockOpen from "../Styleguide/icons/basic-lock-open.svg";
import { Cntx } from "../context/context";
import RenderIf from "../renderif/RenderIf";
import { useContext } from "react";
export const CircularProgressbarComponent = ({
  title,
  value,
  text,
  counterClockwise,
  months,
  pathColor,
  trailColor,
  unlock,
  donutChartId,
  customText,
}) => {
  const getValue = () => {
    if (unlock) {
      return text ? `${text}%` : "0%";
    }
    return "";
  };
  const contextuser = useContext(Cntx);

  if(!months){
    months = "0"
  }

  return (
    <div className="col" style={{ height: "300px", alignContent: "top" }}>
      <div
        className="card"
        style={{
          color: "#A5ACCB",
          textAlign: "center",
          backgroundColor: "#1f2748",
          borderLeftRightRadius: "4px",
          borderTopRightRadius: "15px",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
          height: "300px",
        }}
      >
        <br />

        <h5
          className="card-title"
          style={{ color: "white", marginBottom: 0 }}
          id={donutChartId}
        >
          Last {unlock ? months : 0} months
        </h5>

        {/* <p className="card-text">{title}</p> */}
        <p className="card-text" style={{ marginBottom: "8px" }}>
          {customText}
        </p>
        <RenderIf isTrue={contextuser.val == true}>
          {null}
          {/* <div className="data-tooltip-info">
            <span data-title="This is the User average for the entire period show in the line chart above, in this instance, the User average has seen a 203% uptick on this period.  The doughnut is full because the user has outperformed the peer group average for the same period">
              <img
                src="https://img.icons8.com/metro/26/000000/info.png"
                class="info-icon"
                alt=""
              />
            </span>
          </div>{" "} */}
        </RenderIf>

        <div className="card-body pt-0">
          <div
            className="d-flex justify-content-center container"
            style={{ width: "205px", height: "100%" }}
          >
            <CircularProgressbar
              value={unlock ? value : 0}
              text={getValue()}
              counterClockwise={counterClockwise}
              strokeWidth={13}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                // rotation: 0.25,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: "round",
                // Text size
                textSize: "14px",
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
                // Colors
                pathColor: pathColor,
                textColor: "white",
                trailColor: trailColor,
                /*backgroundColor: '#c300dc',*/
              })}
            />
          </div>
          <br />
        </div>
        {!unlock && (
          <div className="chart-background">
            <div className="chart-background-content">
              <img src={lockOpen} alt="lock-open" />
              <small style={{ margin: "10px 15px 0px 15px" }}>
                Subscribe to Study to see your statistic every day
              </small>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
