import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getStudyData,
  getStudies,
  getExampleStudyData,
  updateLastPeerGroup,
  getParticipantBreakdown,
  getExampleParticipantBreakdown,
  updateLastRegion,
  toggleQEffect
} from "./store/dashboardActions";
// import { getStudyData } from './store/dashboardActions';
import { dashboardActions } from "./store/dashboard";
import ActiveStudies from "./Dashboard/ActiveStudies";
import AvailableStudies from "./Dashboard/AvailableStudies";
import QueuedStudies from "./Dashboard/QueuedStudies";
import {
  fetchParentPeerGroupBysic,
  fetchPeerGroup,
} from "./store/dashboardFetches";
// handle click event of logout button

function LeftSideNav(props) {
  const availableStudies = useSelector(
    (state) => state.dashboard.availableStudies
  );
  const queuedStudies = useSelector((state) => state.dashboard.queuedStudies);
  const allStudies = useSelector((state) => state.dashboard.allStudies);
  const userSubscriptions = useSelector(
    (state) => state.dashboard.userSubscriptions
  );

  let userSubsFilteredBy5subs = userSubscriptions.filter(
      (subscription) => subscription.NumOfSubscribers >= 5
      );

  const examplestudies = useSelector((state) => state.dashboard.examplestudies);
  const activeexamplestudies = useSelector(
    (state) => state.dashboard.activeexamplestudies
  );
  const activeStudy = useSelector((state) => state.dashboard.activeStudy);
  const dispatch = useDispatch();
  const showExampleStudystate = useSelector(
    (state) => state.dashboard.showExampleStudy
  );

  console.log("ksksksksk", activeStudy);

  const handlingStudyClick = (study) => {
    console.log("study  setActiveStudy", study);
    dispatch(dashboardActions.setActiveStudy(study));
    const isSubscriber = userSubscriptions.some(
      (subscription) => subscription.StudyID === study.ID
    );
    console.log("isSubscriber", isSubscriber);
    console.log("dfsssgw312123312", study);

    if (isSubscriber) {
      console.log("dfsssgw312123312", study);
      dispatch(getStudyData(study.ID));
      dispatch(getParticipantBreakdown(study?.ID));
    } else {
      dispatch(dashboardActions.setStudyData(null));
    }
  };
  const handlingExampleStudyClick = (study) => {
    console.log("study  setActiveStudy", study);
    dispatch(dashboardActions.setActiveStudy(study));
    const isSubscriber = userSubscriptions.some(
      (subscription) => subscription.StudyID === study.ID
    );
    console.log("isSubscriber", isSubscriber);
    console.log("dfsssgw312123312", study);

    // if (isSubscriber) {
    //     console.log("dfsssgw312123312",study)
    dispatch(getExampleStudyData());
    dispatch(getExampleParticipantBreakdown());
    // } else {
    // dispatch(dashboardActions.setStudyData(null));
    // }
  };

  const onActiveStudyClick = async (subscription) => {
    let studyPC = await fetchParentPeerGroupBysic(subscription?.ParentCategory);
    localStorage.setItem("peerGroupId", subscription.PeerGroupID);
    async function fetchPeerbyId(item) {
      try {
        let resid = await fetchPeerGroup(item);
        console.log(resid.Category, "CHECKARRRR");
        
        return resid.Category;
      } catch (error) {
        // Handle any errors that may occur during the fetch
        console.error("Error fetching data:", error);
      }
    }
    
    let peerGroupNameByID = await fetchPeerbyId(subscription.PeerGroupID);
    localStorage.setItem("peerGroupName", peerGroupNameByID);
    localStorage.setItem("fetchedLevel4SIC",subscription.ParentCategory);
    console.log(subscription,"HERE!")
    





    let study = {
      ID: subscription.StudyID,
      Name: subscription.study.Name,
      RegionID: subscription.RegionID,
      PeerGroupID: subscription.PeerGroupID,
      TypeID: subscription.TypeID,
      NumOfSubscribers: subscription.NumOfSubscribers,
      About: subscription.About,
      Status: subscription.study.Status,
      Activestatus: parseInt(subscription.Active),
      Category: subscription.Category,
      ParentCategory: studyPC,
      createdAt: subscription.study.createdAt,
      updatedAt: subscription.study.updatedAt,
      DividedBy: subscription.DividedBy,
      AverageOf: subscription.AverageOf,
    };
    if (activeStudy?.PeerGroupID === subscription.PeerGroupID) {
      console.log(
        activeStudy,
        subscription,
        activeStudy?.PeerGroupID,
        "CHECKCHECK"
      );
      
    }
    await fetchParentPeerGroupBysic(study.ParentCategory);
    console.log("study  setActiveStudy", study, allStudies);

    

    let getStuds = (study) => {
      dispatch(
        getStudies(
          study.PeerGroupID,
          study.RegionID,
          study.ID
          )
          )
          
          dispatch(getParticipantBreakdown(study.ID));
          
        }

        
        dispatch(toggleQEffect(true));
        if(study){
          console.log("HMMMMMMM")
          getStuds(study);
          dispatch(dashboardActions.setActiveStudy(study));
          dispatch(getStudyData(study.ID));
        }
        else(
          console.log( localStorage.getItem("peerGroupId")))
          


    // dispatch(updateLastPeerGroup(subscription?.PeerGroupID));
    // localStorage.setItem("regionId", subscription?.RegionID);
    // dispatch(updateLastRegion(subscription?.RegionID));
  };
  const onActiveExampleStudyClick = async (subscription) => {
    console.log(subscription, "111111333###");
    let study = {
      ID: subscription.StudyID,
      Name: subscription.study.Name,
      RegionID: subscription.RegionID,
      PeerGroupID: subscription.PeerGroupID,
      TypeID: subscription.TypeID,
      NumOfSubscribers: subscription.NumOfSubscribers,
      About: subscription.About,
      Status: subscription.study.Status,
      Activestatus: subscription.Active,
      Category: subscription.Category,
      ParentCategory: subscription.ParentCategory,
      createdAt: subscription.study.createdAt,
      updatedAt: subscription.study.updatedAt,
    };
    await fetchParentPeerGroupBysic(study.ParentCategory);
    console.log("study  setActiveStudy", study);

    dispatch(dashboardActions.setActiveStudy(study));
    dispatch(getExampleStudyData());
    dispatch(getExampleParticipantBreakdown());

    // alert("stop")
    // dispatch(getStudies(subscription?.PeerGroupID, subscription?.RegionID, subscription?.StudyID));
    // dispatch(updateLastPeerGroup(subscription?.PeerGroupID));
    // dispatch(getParticipantBreakdown(subscription?.StudyID))
    // localStorage.setItem("regionId", subscription?.RegionID);
    // dispatch(updateLastRegion(subscription?.RegionID));
  };

  React.useEffect(() => {
    console.log("=====", examplestudies, "examplestudies");

    if (examplestudies[0]) {
      onActiveExampleStudyClick(examplestudies[0]);
    }
  }, [examplestudies]);

  return (
    <div style={{ marginLeft: "13px" }}>
      <ActiveStudies
        activeStudies={
          showExampleStudystate ? examplestudies : userSubscriptions
        }
        activeStudy={activeStudy}
        onActiveStudyClick={
          showExampleStudystate ? onActiveExampleStudyClick : onActiveStudyClick
        }
      />
      <AvailableStudies
        activeStudy={activeStudy}
        availableStudies={showExampleStudystate ? [] : availableStudies}
        userSubscriptions={showExampleStudystate ? [] : userSubsFilteredBy5subs}
        onStudyClick={
          showExampleStudystate ? handlingExampleStudyClick : handlingStudyClick
        }
      />
      <br />

      <QueuedStudies
        activeStudy={activeStudy}
        queuedStudies={showExampleStudystate ? [] : queuedStudies}
        userSubscriptions={userSubscriptions}
        onStudyClick={handlingStudyClick}
      />
    </div>
  );
}

export default LeftSideNav;
