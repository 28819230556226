import axios from "axios";
import React, { Component } from "react";
import Button from "../src/components/Button";
import { BUTTONS } from "./constants/en";
import { feedbackMessages } from "./Signup/constants";
import InputFeedback from "./components/InputFeedback";
import "./securityverify.css";
import { fetchPeerGroup, fetchRegionById } from "./store/dashboardFetches";
import { setUserSession } from "./Utils/Common";
import loadData from "./Utils/category";
import toast from "react-hot-toast";
import "./Signup/Signup2.css";
import Closeiconimg from "./assets/close-icon-img.png";
import Lefticonarrowwhite from "./assets/left-icon-arrow-white.png";
import { PAGES } from "./constants/en";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PeerformanceLogo from "../src/assets/peerformance_logo.svg";
import { Categories } from "./store/apiservice";
import { filtterCategories } from "./store/apiservice";
import searchSvg from "./Styleguide/search_icon.svg";
const initialFormState = {
  email: {
    value: "",
    isValid: true,
    error: false,
  },
  password: {
    value: "",
    isValid: true,
    error: false,
  },
  confirmPassword: {
    value: "",
    isValid: true,
    error: false,
  },
  name: {
    value: "",
    isValid: true,
  },
  company: {
    value: "",
    isValid: true,
  },
  noOfEmployees: {
    value: "",
    isValid: true,
  },
  companyReach: {
    value: "",
    isValid: true,
  },
  question1: {
    value: "",
    isValid: true,
  },
  answer1: {
    value: "",
    isValid: true,
  },
  searchFilter: {
    value: "",
    isValid: true,
  },
};
export default class Securityverify extends Component {
  state = {
    question1: {
      value: "",
      isValid: true,
    },
    answer1: {
      value: "",
      isValid: true,
    },
    email: "",
    category: false,
    activeClasses: [false],
    noOfEmployees: "",
    reach: "",
    revenue: "",
    quickLink: {},
    quicklinkId: 0,
    categoryId: 0,
    cityId: [],
    globalId: 0,
    globalName: "",
    regionName: "Uk",
    nationalId: 0,
    regionId: 111,
    regionalId: 0,
    regions: [],
    quickLinks: [],
    data: [],
    selectOptions: [],
    id: "",
    securityquestion: "",
    activeBoxNo: 1,
    inputsForm: initialFormState,
    isConfirmationPopupOpened: false,
    conditionsAreAgreed: false,
    isTermsAndConditionsPopupOpened: false,
    alertMessage: { type: "", message: "" },
    categories: [],
    filterCategory: [],
    quickLinkCategories: [],
    searchFilter: "",
    quickLinkView: true,
    
    
  };

  setInputValueAnswer = (e) => {
    console.log(e);
    console.log(e.target.value);

    this.setState({ answer1: { value: e.target.value } });
  };
  onSubmit = async () => {
    let loginresponse = await JSON.parse(localStorage.getItem("response"));

    const body = {
      userId: loginresponse.data.data.id,
      questionId: loginresponse.data.data.firstsecurityquestionId,
      answer: this.state.answer1.value,
    };
    console.log(body);
    const token = loginresponse.data.data.token;

    const res = await axios.post(
      "https://frontend.mypeerformance.com:8080/api/users/compareSecurityquestions",
      body,
      {
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = res.data;
    console.log("data", data);
    if (data.data == "1") {
      if (loginresponse.data.data.isfirsttime == 1) {
        this.setState({ ...this.state, activeBoxNo: 2 });
      } else {
        this.next();
      }
    } else {
      localStorage.clear();
      localStorage.clear();
      toast.error("security answer not match");
      this.props.history.push("/");
    }
  };
  async next() {
    let loginresponse = await JSON.parse(localStorage.getItem("response"));

    console.log({ loginresponse });
    // Create variable for all of the values that we have received
    let token = loginresponse.data.data.token;
    let user = loginresponse.data.data.user;
    localStorage.setItem("id", loginresponse.data.data.user.id);

    // Security values
    let salt = loginresponse.data.data.salt;
    let iv = loginresponse.data.data.iv;
    let peerGroupId = loginresponse.data.data.peerGroupId;
    let regionID = loginresponse.data.data.regionID;
    let decryptedKey = "";
    setUserSession(
      token,
      user,
      "",
      decryptedKey,
      iv,
      salt,
      peerGroupId,
      regionID
    );
    setUserSession(
      token,
      user,
      "",
      decryptedKey,
      iv,
      salt,
      peerGroupId,
      regionID
    );
    loadData(token);

    let storedPeerGroupId = localStorage.getItem("peerGroupId");
    let storedRegionId = localStorage.getItem("storedRegionId");
    console.log(storedPeerGroupId, storedRegionId);
    if (!localStorage.getItem("peerGroupId")) {
      fetchPeerGroup(storedPeerGroupId).then((r) => console.log(r));
      fetchRegionById(storedRegionId).then((r) => console.log(r));
    } else {
      fetchPeerGroup(storedPeerGroupId).then((r) => console.log(r));
      fetchRegionById(storedRegionId).then((r) => console.log(r));
    }

    console.log("dttt", loginresponse);

    this.props.history.push("/dashboard");
  }
  async getallCategories() {
    let categories = await Categories();
    this.state.categories = categories.rows;
    console.log("categories", this.state.categories);
  }
  setInputValue = (e) => {
    console.log(e);
    const { name, value } = e.target;

    this.setState((prevState) => ({
      ...prevState,
      inputsForm: {
        ...prevState.inputsForm,
        [name]: {
          ...prevState.inputsForm[name],
          value: value,
          isValid: !!value,
        },
      },
    }));
    this.getFilterCategories(e);
  };
  async getFilterCategories(filterdata) {
    let resultfilterCategories = await filtterCategories(
      filterdata.target.value
    );
    this.state.searchFilter = filterdata.target.value;
    this.setState({ searchFilter: filterdata.target.value });

    this.state.filterCategory = resultfilterCategories;
    this.setState({ filtterCategory: resultfilterCategories });
    console.log("filterdcategories", this.state.filterCategory);
  }
  async getQuickLink() {
    const res = await axios.get(
      "https://frontend.mypeerformance.com:8080/api/quicklinks/getlist",
      {
        headers: {
          "x-access-token": localStorage.getItem("token1"),
        },
      }
    );
    const data = res.data;
    console.log("quickLinks", data);
    this.state.quickLinks = data.data;
    console.log("quickLinks", this.state.quickLinks);
  }
  async getRegions() {
    const res = await axios.get(
      "https://frontend.mypeerformance.com:8080/api/regions/getRegions",
      {
        headers: {
          "x-access-token": localStorage.getItem("token1"),
        },
      }
    );
    const data = res.data;
    console.log("regions", data);
    this.state.regions = data.data;
    console.log("regions1", this.state.regions);
  }
  // RegionId = () => {
  //   setTimeout(() => {
  //     if (this.state.cityId) {
  //       this.setState({ ...this.state, regionId: this.state.cityId });
  //       console.log("city", this.state.regionId);
  //     } else if (this.state.regionalId) {
  //       this.setState({ ...this.state, regionId: this.state.regionalId });
  //       console.log("regional", this.state.regionId);
  //     } else if (this.state.nationalId) {
  //       this.setState({ ...this.state, regionId: this.state.nationalId });
  //       console.log("national", this.state.regionId);
  //     } else if (this.state.globalId) {
  //       this.setState({ ...this.state, regionId: this.state.globalId });
  //       console.log("global", this.state.regionId);
  //     }
  //   }, 200);
  // };
  UpdateAccount = async () => {
    console.log(this.state.quickLink.categoryId);
    if (!this.state.category) {
      // this.setState({ ...this.state, categoryId: this.state.quickLink.categoryId });
      this.state.categoryId = this.state.quickLink.categoryId;
    }

    const body = {
      numberofemployee: this.state.noOfEmployees,
      reach: this.state.reach,
      categoryId: this.state.categoryId,
      regionId: this.state.regionId,
      quicklinkId: this.state.quicklinkId,
    };
    console.log("CHECKSIGN", body);
    let id = await localStorage.getItem("id");

    const res = await axios.put(
      `https://frontend.mypeerformance.com:8080/api/users/update/${id}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token1"),
        },
      }
    );
    console.log("regions", res);
    this.next();
  };
  async getOptions() {
    const res = await axios.get(
      "https://frontend.mypeerformance.com:8080/api/securityquestions/getlist"
    );
    const data = res.data;
    console.log("data", data);
    this.state.data = data.data;
    let loginresponse = JSON.parse(localStorage.getItem("response"));
    console.log("data", this.state.data);
    var result = data.data.filter((obj) => {
      return obj.id === loginresponse.data.data.firstsecurityquestionId;
    });
    this.setState({ question1: { value: result[0].securityquestion } });
    // this.state.question1.value = result[0].securityquestion
    console.log(this.state.question1.value);
  }
  async getChildlength(parentsic) {
    const res = await axios.get(
      `https://frontend.mypeerformance.com:8080/api/categories/byparentsic/${parentsic}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    const data = res.data.data;
    if (data.length <= 1) {
      return true;
    } else if (data.length > 1) {
      console.log("cond", false);
      return false;
    }
  }
  getChild(d) {
    this.setState({
      ...this.state,
      quickLink: d,
    });
    this.SaveClicks(d.category);
  }
  SaveClicks = async (d) => {
    // window.localStorage.setItem("clicks", JSON.stringify(data));
    let parentsic = d;
    console.log("dataTrackparentsic", d);

    const res = await axios.get(
      `https://frontend.mypeerformance.com:8080/api/categories/byparentsic/${parentsic.sic}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );

    const data = res.data.data;
    console.log("dataTrack", data);

    const mapped = data.map((item) => {
      return {
        ID: item.id,
        Sic: item.sic,
        parentsic: item.parentsic,
        Category: item.category,
        level: item.level,
        status: item.status,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      };
    });
    this.setState({ quickLinkCategories: data });
    if (data.length > 0) {
      this.setState({ quickLinkView: false });
    }
  };
  componentDidMount() {
    this.getOptions();
    this.getQuickLink();
    this.getRegions();
    this.getallCategories();
  }

  render() {
    console.log(this.state.selectOptions);
    console.log({ regionalId: this.state.regionalId });
    const {
      inputsForm: {
        email,
        password,
        confirmPassword,
        name,
        company,
        noOfEmployees,
        companyReach,
        question1,
        searchFilter,
      },
    } = this.state;
    const {} = this;
    const { SIGN_UP, COMPANY_INFORMATIONS } = PAGES;
    return (
      <>
        {this.state.activeBoxNo === 1 && (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 mx-auto">
                  <img
                    className="w-50 mx-auto d-block mt-4 mb-24"
                    style={{
                      height: "100px",
                      width: "139.65px",
                    }}
                    src={PeerformanceLogo}
                    alt="Peerformance Logo"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 col-sm-12 mx-auto">
                  <div className="row mt-3">
                    <div className="group-inputs p-3">
                      <div className="group-input mt-2 ">
                        <label htmlFor="question1">{"Security Question"}</label>
                        <input
                          className={`form-control`}
                          type="text"
                          id="question1"
                          name="question1"
                          readonly
                          value={this.state.question1.value}
                        />
                      </div>
                      <div className="group-input mt-3 ">
                        <label htmlFor="answer1">{"Answer"}</label>
                        <input
                          className={`form-control ${
                            !this.state.answer1.isValid ? "" : "is-invalid"
                          }`}
                          type="text"
                          id="answer1"
                          name="answer1"
                          placeholder={"Answer"}
                          onChange={this.setInputValueAnswer}
                          required
                        />
                        {!this.state.answer1.isValid && (
                          <InputFeedback message={feedbackMessages.answer1} />
                        )}
                      </div>
                      <Button
                        onClick={this.onSubmit}
                        type="button"
                        text={BUTTONS.SUBMIT}
                        cname="greenbtn"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.activeBoxNo === 2 && (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 mx-auto">
                  <img
                    className="w-50 mx-auto d-block mt-4 mb-24"
                    style={{
                      height: "100px",
                      width: "139.65px",
                    }}
                    src={PeerformanceLogo}
                    alt="Peerformance Logo"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 col-sm-12 mx-auto">
                  <form noValidate className="w-100">
                    <div className="row mt-3">
                      <div className="group-inputs p-3">
                        <div className="group-input mt-2">
                          <div className="tooltip-bt-info">
                            <label htmlFor="No of employees">
                              {
                                COMPANY_INFORMATIONS.FIELDS.NUMBER_OF_EMPLOYEES
                                  .LABEL
                              }
                            </label>

                            <div className="tooltip-content">
                              <div className="tooltip-icon-img">
                                {<HelpOutlineIcon />}
                              </div>
                              <div className="tooltip-item">
                                <p>
                                  We need this to be able to compare you to
                                  businesses of a similar size
                                </p>
                              </div>
                            </div>
                          </div>
                          <select
                            name="no-employees"
                            id="no-employees"
                            className="select-tag-info"
                            required
                            onChange={(e) => {
                              this.state.noOfEmployees = e.currentTarget.value;
                            }}
                          >
                            <option
                              value=""
                              disabled="disabled"
                              selected="selected"
                            >
                              Select a no of employees.
                            </option>
                            <option value="0-20">0-20</option>
                            <option value="20-100">20-100</option>
                            <option value="100-1000">100-1000</option>
                            <option value="1000-plus">1000-plus</option>
                          </select>
                          {!this.state.inputsForm.noOfEmployees.isValid && (
                            <InputFeedback message={feedbackMessages.name} />
                          )}
                        </div>
                        <div className="group-input mt-3">
                          <div className="tooltip-bt-info">
                            <label htmlFor="Company reach(Global, UK, Local)">
                              {COMPANY_INFORMATIONS.FIELDS.COMPANY_REACH.LABEL}
                            </label>
                            <div className="tooltip-content">
                              <div className="tooltip-icon-img">
                                {<HelpOutlineIcon />}
                              </div>
                              <div className="tooltip-item">
                                <p>
                                  We need this to be able to compare you to
                                  businesses of a similar size
                                </p>
                              </div>
                            </div>
                          </div>

                          <select
                            name="company-locations"
                            onChange={(e) => {
                              this.state.reach = e.currentTarget.value;
                            }}
                            id="company-locations"
                            className="select-tag-info"
                          >
                            <option
                              value=""
                              disabled="disabled"
                              selected="selected"
                            >
                              Select a region.
                            </option>
                            <option value="Local">Local</option>
                            <option value="Regional">Regional</option>
                            <option value="National">National</option>
                            <option value="International">International</option>
                          </select>
                          {!this.state.inputsForm.companyReach.isValid && (
                            <InputFeedback message={feedbackMessages.company} />
                          )}
                        </div>
                        <div className="group-input mt-3">
                          <div className="tooltip-bt-info">
                            <label htmlFor="Company reach(Global, UK, Local)">
                              Total Revenue
                            </label>
                            <div className="tooltip-content">
                              <div className="tooltip-icon-img">
                                {<HelpOutlineIcon />}
                              </div>
                              <div className="tooltip-item">
                                <p>
                                  We need this to be able to compare you to
                                  businesses of a similar size
                                </p>
                              </div>
                            </div>
                          </div>

                          <select
                            name="company-locations"
                            onChange={(e) => {
                              this.state.revenue = e.currentTarget.value;
                            }}
                            id="revenue-types"
                            className="select-tag-info"
                          >
                            <option
                              value=""
                              disabled="disabled"
                              selected="selected"
                            >
                              Select a revenue group.
                            </option>
                            <option value="0-1m">0-1m</option>
                            <option value="1-10m">1-10m</option>
                            <option value="10-50m">10-50m</option>
                            <option value="50m + ">50m + </option>
                          </select>
                          {!this.state.inputsForm.companyReach.isValid && (
                            <InputFeedback message={feedbackMessages.company} />
                          )}
                        </div>
                        <Button
                          onClick={() => {
                            if (
                              this.state.revenue.length > 0 &&
                              this.state.reach.length > 0 &&
                              this.state.noOfEmployees.length > 0
                            ) {
                              this.setState({ ...this.state, activeBoxNo: 3 });
                            } else {
                              toast.error("Please select one of each option");
                            }
                          }}
                          type="button"
                          text={BUTTONS.SUBMIT}
                          cname="greenbtn"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.activeBoxNo === 3 && (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12 mx-auto">
                  <img
                    className="w-50 mx-auto d-block mt-4 mb-24"
                    style={{
                      height: "100px",
                      width: "139.65px",
                    }}
                    src={PeerformanceLogo}
                    alt="Peerformance Logo"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 mx-auto center-text">
                  <div className="change-group-info mt-3">
                    <div className="change-group-body">
                      <div className="change-group-content">
                        <div className="top-bar-row">
                          {!this.state.quickLinkView && (
                            <div className="top-bar-col">
                              <h3
                                className="align-left"
                                //was align-center if Text involved below
                                //this onclick to go back one page
                                // onClick={() =>
                                //   this.setState({ ...this.state, activeBoxNo: 2 })
                                // }
                                //this onclick to go back to quicklinks main
                                onClick={() =>
                                  this.setState({ quickLinkView: true })
                                }
                                style={{ color: "#38d982" }}
                              >
                                <img src={Lefticonarrowwhite} alt="" />
                                {/* Pick your
                            peer group using any option below, Quick Links are
                            popular peer groups ready to go. You can change your
                            peer group at any time. */}
                              </h3>
                            </div>
                          )}
                          <div className="top-bar-col align-right">
                            {/* <img className="btn-link" alt="" src={Closeiconimg} /> */}
                          </div>
                        </div>
                        <div className="tabs-bar-row">
                          <nav>
                            <div
                              className="nav nav-tabs nav-fill"
                              id="nav-tab"
                              role="tablist"
                            >
                              <a
                                className="nav-item nav-link"
                                id="nav-industry-filter-tab"
                                data-toggle="tab"
                                href="#nav-industry-filter"
                                role="tab"
                                aria-controls="nav-industry-filter"
                                aria-selected="true"
                              >
                                Industry Filter
                              </a>
                              <a
                                className="nav-item nav-link"
                                id="nav-key-word-search-tab"
                                data-toggle="tab"
                                href="#nav-key-word-search"
                                role="tab"
                                aria-controls="nav-key-word-search"
                                aria-selected="false"
                              >
                                Key Word Search
                              </a>
                              <a
                                className="nav-item nav-link active"
                                id="nav-quick-links-tab"
                                data-toggle="tab"
                                href="#nav-quick-links"
                                role="tab"
                                aria-controls="nav-quick-links"
                                aria-selected="false"
                                onClick={() =>
                                  this.setState({ quickLinkView: true })
                                }
                              >
                                Quick Links
                              </a>
                            </div>
                          </nav>
                          <div className="tab-content" id="nav-tabContent">
                            <div
                              className="tab-pane fade"
                              id="nav-industry-filter"
                              role="tabpanel"
                              aria-labelledby="nav-industry-filter-tab"
                            >
                              <div className="industry-filter-tab-content">
                                <div className="overflow-changes-txt">
                                  <div className="row mb-20">
                                    {this.state.categories.map(
                                      (category, i) => (
                                        <div className="col-md-12 mb-20">
                                          <div
                                            className={`btn-group-info`}
                                            onClick={(e) => {
                                              this.setState({
                                                ...this.state,
                                                categoryId: category.id,
                                                category: true,
                                              });
                                            }}
                                          >
                                            <button
                                              className={`btn-solid-css align-left  ${
                                                category.id ==
                                                this.state.categoryId
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              {category.category}
                                            </button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-key-word-search"
                              role="tabpanel"
                              aria-labelledby="nav-key-word-search-tab"
                            >
                              <div className="key-word-search-tab-content">
                                <h2 className="font-size-20 align-center mb-30">
                                  Search for a category
                                </h2>
                                <div className="row">
                                  <div className="col-md-2"></div>
                                  <div className="col-md-12 mb-30">
                                    <div className="col-md-8 mb-30 centerSearch">
                                      <div className="card">
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="e.g. recruitment"
                                            id="searchFilter"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={this.setInputValue}
                                            name="company"
                                          />
                                          <span
                                            className="input-group-text"
                                            id="basic-addon1"
                                          >
                                            <img
                                              id="searchIconBtn"
                                              src={searchSvg}
                                              alt=""
                                            />
                                          </span>
                                        </div>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() => {
                                            let userInput =
                                              document.getElementById(
                                                "searchBox"
                                              ).value;
                                            userInput = "";
                                          }}
                                        >
                                          {" "}
                                          Clear Search{" "}
                                        </button>
                                      </div>
                                    </div>
                                    <div className="industry-filter-tab-content">
                                      <div className="overflow-changes-txt">
                                        <div className="row mb-20">
                                          {this.state.filterCategory.map(
                                            (category, i) => (
                                              <div className="col-md-12 mb-20">
                                                <div
                                                  className={`btn-group-info`}
                                                  onClick={(e) => {
                                                    this.setState({
                                                      ...this.state,
                                                      categoryId: category.id,
                                                      category: true,
                                                    });
                                                  }}
                                                >
                                                  <button
                                                    className={`btn-solid-css align-left  ${
                                                      category.id ==
                                                      this.state.categoryId
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                  >
                                                    {category.category}
                                                  </button>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade show active"
                              id="nav-quick-links"
                              role="tabpanel"
                              aria-labelledby="nav-quick-links-tab"
                            >
                              <div className="quick-links-tab-content">
                                {/* <h2 className="font-size-20 align-center mb-20">
                                  Quick Links
                                </h2> */}
                                {this.state.quickLinkView ? (
                                  <div className="row">
                                    {this.state.quickLinks.map((d, i) => (
                                      <div
                                        className="col-sm-3"
                                        onClick={(e) => {
                                          this.setState({
                                            ...this.state,
                                            quicklinkId: d.id,
                                            quickLink: d,
                                          });
                                          this.getChild(d);
                                        }}
                                      >
                                        <div
                                          className="card"
                                          style={{
                                            backgroundColor: "rgb(38, 45, 81)",
                                            paddingTop: "8px",
                                            marginBottom: "2rem",
                                          }}
                                        >
                                          <div
                                            className="card-body text-center"
                                            style={{ height: "13rem" }}
                                          >
                                            <div
                                              className={`quick-links-boxs ${
                                                d.id == this.state.quicklinkId
                                                  ? "active"
                                                  : ""
                                              } align-center `}
                                            >
                                              <img
                                                src="/static/media/circle_background.9632aeeb84b56b551a44fb1ca4b40cd3.svg"
                                                className="quickLinkCircleBackGround"
                                              />
                                              <img
                                                className="quickLinkIcon"
                                                alt=""
                                                src={
                                                  "https://frontend.mypeerformance.com/peerformance_webbackend/assets/images/" +
                                                  d?.icon
                                                }
                                              />
                                            </div>
                                            <br></br>
                                            <p
                                              style={{
                                                fontSize: "15px",
                                                color: "rgb(165, 172, 203)",
                                              }}
                                            >
                                              {d?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className="industry-filter-tab-content">
                                    <div className="overflow-changes-txt">
                                      <div className="row mb-20">
                                        {this.state.quickLinkCategories
                                          .slice(1)
                                          .map((category, i) => (
                                            <div className="col-md-12 mb-20">
                                              <div
                                                className={`btn-group-info`}
                                                onClick={async (e) => {
                                                  this.setState({
                                                    ...this.state,
                                                    categoryId: category.id,
                                                    category: true,
                                                  });
                                                  (await this.getChildlength(
                                                    category.Sic
                                                  ))
                                                    ? this.setState({
                                                        ...this.state,
                                                        categoryId: category.id,
                                                        category: true,
                                                      })
                                                    : this.SaveClicks(category);
                                                }}
                                              >
                                                <button
                                                  className={`btn-solid-css align-left  ${
                                                    category.id ==
                                                    this.state.categoryId
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  {category.category}
                                                </button>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-9"></div>
                            <div
                              className="col-md-3 mb-3 "
                              style={{ marginTop: "-0.5rem" }}
                            >
                              <Button
                                onClick={() => {
                                  if (this.state.category) {
                                    this.setState({
                                      ...this.state,
                                      activeBoxNo: 4,
                                    });
                                  } else {
                                    toast.error("Please select a peer group");
                                  }
                                }}
                                type="button"
                                text={BUTTONS.NEXT}
                                cname="greenbtn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.activeBoxNo === 4 && (
          <>
            <div className="container ">
              <div className="row">
                <img
                  className="w-50 mx-auto d-block mt-4 mb-24"
                  style={{
                    height: "100px",
                    width: "139.65px",
                  }}
                  src={PeerformanceLogo}
                  alt="Peerformance Logo"
                />
              </div>
              <div className="col-md-12 col-sm-12 mx-auto center-text ">
                <div className="change-group-info mt-3">
                  <div className="change-group-body">
                    <div className="change-group-content">
                      <div className="top-bar-row">
                        <div className="top-bar-col">
                          <h3
                            className="align-left"
                            //was align-center if Text involved below
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                activeBoxNo: 3,
                              })
                            }
                            style={{ color: "#38d982" }}
                          >
                            <img src={Lefticonarrowwhite} />
                            {/* Pick the region you
                            compete in. Your peer group will only sumbit data
                            for this region - you can change this region at any
                            time. */}
                          </h3>
                        </div>
                        <div className="top-bar-col align-right">
                          {/* <img className="btn-link" src={Closeiconimg} /> */}
                        </div>
                      </div>
                      <div className="tabs-bar-row">
                        <nav>
                          <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              className="nav-item nav-link active"
                              // className={`nav-item nav-link ${
                              //   this.state.regionId==3
                              //        ? "active"
                              //        : ""
                              //    }`}
                              id="nav-uk-tab"
                              data-toggle="tab"
                              href="#nav-uk"
                              role="tab"
                              aria-controls="nav-uk"
                              aria-selected="true"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  regionId: 111,
                                  regionName: "Uk",
                                });
                              }}
                            >
                              UK
                            </button>

                            <button
                              className="nav-item nav-link"
                              // className={`nav-item nav-link  ${
                              //   this.state.regionId==2
                              //        ? "active"
                              //        : ""
                              //    }`}
                              id="nav-europe-country-tab"
                              data-toggle="tab"
                              href="#nav-europe-country"
                              role="tab"
                              aria-controls="nav-europe-country"
                              aria-selected="false"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  regionId: 11,
                                  regionName: "Europe",
                                });
                              }}
                            >
                              Europe
                            </button>
                            <a
                              className="nav-item nav-link"
                              //   className={`nav-item nav-link active ${
                              //  this.state.regionId==1
                              //       ? "active"
                              //       : ""
                              //   }`}
                              id="nav-global-tab"
                              data-toggle="tab"
                              href="#nav-global"
                              role="tab"
                              aria-controls="nav-global"
                              aria-selected="false"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  regionId: 1,
                                  regionName: "Global",
                                });
                              }}
                            >
                              Global
                            </a>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                          <div
                            className="tab-pane tab-pane fade show active"
                            id="nav-uk"
                            role="tabpanel"
                            aria-labelledby="nav-uk-tab"
                          >
                            <div className="uk-tab-content">
                              <div className="overflow-changes-txt">
                                <div className="row">
                                  <div className="col-md-12">
                                    <h2 className="font-size-20 align-left mb-20">
                                      Regions
                                    </h2>
                                  </div>
                                </div>
                                <div className="row mb-20 ">
                                  {this.state.regions.map((d) =>
                                    d.Level == 4 ? (
                                      <div className="col-md-6 mb-20">
                                        <div
                                          className="btn-group-info"
                                          onClick={() => {
                                            const ParentRegionPasser =
                                              this.state.regions.filter(
                                                (item) => {
                                                  return item.ParentID === d.ID;
                                                }
                                              );
                                            console.log(d, "hmmm");
                                            this.setState({
                                              ...this.state,
                                              regionId: d.ID,
                                              regionName: d.Name,
                                              regionalId: d.ID,
                                              cityId: ParentRegionPasser,
                                            });
                                          }}
                                        >
                                          <button
                                            className={`btn-solid-css align-left ${
                                              d.ID == this.state.regionId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {d.Name}
                                          </button>
                                        </div>
                                      </div>
                                    ) : null
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <h2 className="font-size-20 align-left mb-20">
                                      Cities
                                    </h2>
                                  </div>
                                </div>
                                <div className="row mb-20">
                                  {this.state.cityId.map((d) =>
                                    d.Level == 5 ? (
                                      <div className="col-md-6 mb-20">
                                        <div
                                          className="btn-group-info"
                                          onClick={() => {
                                            console.log(d, "hmmm1");
                                            this.setState({
                                              ...this.state,
                                              regionId: d.ID,
                                              regionName: d.Name,
                                            });
                                          }}
                                        >
                                          <button
                                            className={`btn-solid-css align-left ${
                                              d.ID == this.state.regionId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {d.Name}
                                          </button>
                                        </div>
                                      </div>
                                    ) : null
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-europe-country"
                            role="tabpanel"
                            aria-labelledby="nav-europe-country-tab"
                          >
                            <div className="europe-country-tab-content">
                              <div className="overflow-changes-txt">
                                {/* <div className="row">
                                  <div className="col-md-12">
                                    <h2 className="font-size-20 align-left mb-20">
                                      National
                                    </h2>
                                  </div>
                                </div> */}
                                <div className="row mb-20">
                                  {/* {this.state.regions.map(
                                    (d) => (
                                      console.log(
                                        d?.ParentID,
                                        this.state.globalId
                                      ),
                                      d.Level == 3 &&
                                      d.ParentID == this.state.globalId ? (
                                        <div className="col-md-6 mb-20">
                                          <div
                                            className="btn-group-info"
                                            onClick={() => {
                                              this.setState({
                                                ...this.state,
                                                nationalId: d.ID,
                                                nationalName: d.Name,
                                              });
                                              this.RegionId();
                                            }}
                                          >
                                            <button
                                              className={`btn-solid-css align-left ${
                                                d.ID == this.state.nationalId
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              {d.Name}
                                            </button>
                                          </div>
                                        </div>
                                      ) : null
                                    )
                                  )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade show active"
                            id="nav-global"
                            role="tabpanel"
                            aria-labelledby="nav-global-tab"
                          >
                            <div className="global-tab-content">
                              <div className="overflow-changes-txt">
                                {/* <div className="row">
                                  <div className="col-md-12">
                                    <h2 className="font-size-20 align-left mb-20">
                                      Continental
                                    </h2>
                                  </div>
                                </div> */}

                                <div className="row mb-20">
                                  {/* {this.state.regions.map((d) =>
                                    d.Level == 2 ? (
                                      <div className="col-md-6 mb-20">
                                        <div
                                          className="btn-group-info"
                                          onClick={() => {
                                            this.setState({
                                              ...this.state,
                                              globalId: d.ID,
                                              globalName: d.Name,
                                            });
                                            this.RegionId();
                                          }}
                                        >
                                          <button
                                            className={`btn-solid-css align-left ${
                                              d.ID == this.state.globalId
                                                ? "active"
                                                : ""
                                            }`}
                                          >
                                            {d.Name}
                                          </button>
                                        </div>
                                      </div>
                                    ) : null */}
                                  {/* )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ justifyContent: "space-between" }}
                        >
                          <p className="selectionRegion mt-2 w-50">
                            Your selected location is :
                            <b className="Regionname">
                              {" " + this.state.regionName}
                            </b>
                          </p>
                          <div
                            className="w-25 mb-3"
                            style={{ marginTop: "-0.5rem" }}
                          >
                            <Button
                              cname="greenbtn"
                              type="button"
                              text={BUTTONS.SUBMIT}
                              onClick={() => this.UpdateAccount()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
